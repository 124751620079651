import React from 'react';
import { useState } from 'react';
import { Outlet } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import DashboardSidebar from './DashboardSidebar';
import DashboardNavbar from "./DashboardNavbar";

const APP_BAR_MOBILE = 64;
const APP_BAR_DESKTOP = 92;

const RootStyle = styled('div')({
  display: 'flex',
  minHeight: '100%',
  overflow: 'hidden'
});

const MainStyle = styled('div')(({ theme }) => ({
  flexGrow: 1,
  backgroundColor: '#F4F6F8',
  overflow: 'auto',
  minHeight: '100%',
  paddingTop: APP_BAR_MOBILE+ 20,
  paddingBottom: theme.spacing(10),
  [theme.breakpoints.up('lg')]: {
    paddingTop: APP_BAR_DESKTOP + 20,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  }
}));

// ----------------------------------------------------------------------

export default function DashboardLayout() {
  const [open, setOpen] = useState(true);

  return (
        <RootStyle>
          <DashboardNavbar isOpen={open} onOpenSidebar={() => setOpen(!open)} onClick={() => setOpen(!open)} />
          <DashboardSidebar isOpenSidebar={open} onCloseSidebar={() => setOpen(!open)} />
          <MainStyle>
                <Outlet />
          </MainStyle>
        </RootStyle>
  );
}
