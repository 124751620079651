import { Card, CardContent, CardHeader } from "@mui/material";
import React, { useState } from "react";
import {
  PieChart,
  Pie,
  Sector,
  ResponsiveContainer,
  Cell,
  BarChart,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  CartesianGrid,
  Bar,
  LabelList,
} from "recharts";

interface propsChart {
  dados: any[]
}
const DemandaPorPrioridade: React.FC<propsChart> = (props) => {
 

  const COLORS = [
    "#DD3366",
    "#66CC88",
    "#EEFF55"
  ];

  return (
    <Card>
      <CardHeader title={"Demandas Por Prioridade"} />
      <CardContent style={{ padding: "8px" }}>
        <ResponsiveContainer height={300}>
          <BarChart
            data={props.dados}
            margin={{
              top: 5,
              right: 20,
              left: 20,
              bottom: 5,
            }}
            barSize={60}
  
          >
            <XAxis dataKey="_id" scale="point" padding={{ left: 60, right: 60 }} />
            <YAxis domain={[0, 'dataMax + 1']} allowDecimals={false}/>
            <Tooltip />
            
            <CartesianGrid strokeDasharray="3 6" />
            <Bar dataKey="total" fill="#8884d8">
              {props?.dados.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={entry._id === "Alta" ? COLORS[0] : entry._id === "Baixa" ? COLORS[1]  : COLORS[2] } />))}
              <LabelList dataKey="total" position="top" fill="#000"/>
            </Bar>
          </BarChart>
        </ResponsiveContainer>
      </CardContent>
    </Card>
  );
};
export default DemandaPorPrioridade;
