import React from "react";
import {
    Categoria,
    Regiao,
} from "../component/typings";
import GABCrud from "../component/gab-crud";
import * as Yup from "yup";

const DemandaLocation: React.FC = () => {
    const url ='/parlamentar/regiao'
    const validation =  Yup.object().shape({
        nome: Yup.string().trim().required("informe o nome"),
    });

    return (
        <GABCrud
            idField={'_id'}
            validationSchema={validation}
            service={url}
            pageTitle={'Regiao'}
            serviceName={'regiao'}
            icon={"tabler:map-2"}
            title={"Regiao"}
            subtile={`Gerenciar Regiao`}
            fields={
                [
                    {
                        id: "nome",
                        label: "Categoria",
                        size : 12
                    }
                ]
            }
            columns={
                [
                    {
                        name: "nome",
                        label: "Categoria",
                    },
                    {
                        name: "acao",
                        label: "Ação",
                    }
                ]
            }
        />
    )
}

export default DemandaLocation;


