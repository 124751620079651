import React from "react";
export const  useScroll = ()=> {
    const scrollToTop = ()=>{
        setTimeout(
            ()=>{
                window.scrollTo({
                    top: 0,
                    left: 0,
                    behavior: "smooth",
                });
            }, 500
        );
    }

    return {scrollToTop};
}
