import React, { CSSProperties, ReactElement } from "react";
import { Avatar, Card, CardContent, CardHeader, Grid } from "@mui/material";
import { grey } from "@mui/material/colors";
import useResponsive from "../../hooks/useResponsive";
import {useTheme} from "@mui/material/styles";
import GABIcon from "../gab-icon";

interface propsCardForm {
  title: string;
  subtile: string;
  children?: React.ReactNode;
  icon: string;
  style?: CSSProperties | undefined;
  action?: ReactElement | undefined;

    size?:number;
}
const GABForm: React.FC<propsCardForm> = (props) => {
    const isDesktop = useResponsive('up', 'lg');
    const theme = useTheme();

    return (
        <Grid item xs={isDesktop? (props?.size || 12) :  12} style={{
            paddingTop : '0.5rem',
            width : '100%'
        }}>
            <Card>
                <CardHeader
                    avatar={
                        <Avatar sx={{ bgcolor: grey[100] }} aria-label="recipe">
                            <GABIcon
                                icon={props.icon}
                            />
                        </Avatar>
                    }
                    action={props.action}
                    title={props.title}
                    subheader={props.subtile}
                />
                <CardContent>
                    <Grid container rowSpacing={1} spacing={1}>
                        {props.children}
                    </Grid>
                </CardContent>
            </Card>
        </Grid>
    );
};

export default GABForm;
