import React, { useState,} from "react";
import {Grid, IconButton, TableCell, TableRow, Typography} from "@mui/material";
import GABLabel from "../../../../components/gab-label";
import GabInput from "../../../../components/gab-input";
import GABInput from "../../../../components/gab-input";
import GABForm from "../../../../components/gab-form";
import {FormikProps, useFormik} from "formik";
import {Generic, UsuariosResponsavel} from "../../component/typings";
import GABDataTable from "../../../../components/gab-data-table";
import GABButtonIcon from "../../../../components/gab-button-icon";
import {alpha, useTheme} from "@mui/material/styles";
import GABIcon from "../../../../components/gab-icon";
import GABModal from "../../../../components/gab-modal";
import GABMenu from "../../../../components/gab-menu";
import GABMenuItem from "../../../../components/gab-menu/item";
import {Atividade, Tarefa} from "../../dto/demanda";
import {format, parse, parseISO} from "date-fns";
import GABSwitch from "../../../../components/gab-switch";
import * as Yup from "yup";


interface propsGABFormDemandaTask {
    formik:FormikProps<any>;
    responsaveis:UsuariosResponsavel[];
}
const GABFormDemandaTask: React.FC<propsGABFormDemandaTask> = (props) => {
    const [modalTarefaOpen, setModalTarefaOpen] = useState(false);
    const theme = useTheme();

    const validationSchema = Yup.object().shape({
        responsavel: Yup.string().trim().required("informe o responsável"),
        descricao: Yup.string().trim().required("informe a tarefa"),
        prazo: Yup.string().trim().required("informe o prazo"),
      });

    const formikTarefa = useFormik({
        initialValues: {} as any,
        validationSchema: validationSchema,
        onSubmit: async (values) => {
            setModalTarefaOpen(false);

            let list = props.formik.values.tarefas || [];
            if (indexSelected > -1) {
                list = remove();
            }

            const reponsavel = props.responsaveis.filter((f:UsuariosResponsavel) => f.value === formikTarefa.values.responsavel)

            list.push({
                responsavel: reponsavel[0] as any,
                descricao: formikTarefa.values.descricao,
                prazo: formikTarefa.values.prazo,
            })
            await props.formik.setFieldValue('tarefas', list);

            formikTarefa.resetForm();
        }
    });
    const [anchorEl, setAnchorEl] = React.useState(null);

    const [indexSelected, setIndexSelected] = useState(-1);
    const handleClick = (event: any, rowIndex:number) => {
        setAnchorEl(event.currentTarget);
        setIndexSelected(rowIndex);
    };
    const customRenderTarefas = (data: any, dataIndex: any, rowIndex: any) => {
        let styleLocal: React.CSSProperties = {};
        if (rowIndex % 2 === 0) {
            styleLocal.backgroundColor = alpha(theme.palette.primary.light, 0.20);
        }
        return (
            <TableRow style={styleLocal}>
                <TableCell align={"left"} valign={"top"}>
                    <GABLabel title={data[0]?.label} subTitle={data[0]?.email} flip={true}/>
                </TableCell>

                <TableCell align={"left"} valign={"top"}>
                    <Typography>{data[1]}</Typography>
                </TableCell>

                <TableCell align={"left"} valign={"top"} style={{
                    textAlign :'center'
                }}>
                    {
                        data[2]?
                            <GABIcon width={'1.5rem'} color={theme.palette.success.main} height={'1.5rem'} icon={'akar-icons:person-check'}/> :
                            <GABIcon width={'1.5rem'} color={theme.palette.grey[600]} height={'1.5rem'} icon={'akar-icons:person'}/>
                    }
                </TableCell>

                <TableCell align={"justify"} valign={"top"}>
                    <Typography>{data[3]}</Typography>
                </TableCell>

                <TableCell width={30}>
                    <IconButton onClick={
                        (event: any) => {
                             handleClick(event,dataIndex)
                        }
                    }>
                        <GABIcon
                            icon={"iconamoon:menu-kebab-vertical-bold"}
                        />
                    </IconButton>
                </TableCell>
            </TableRow>
        )
    };
    const closeMenu = () => {
        setIndexSelected(-1);
        setAnchorEl(null);
    };
    const remove = () => {
        const newList = props.formik.values.tarefas.filter((atividade:Atividade, index:number) => index !== indexSelected);
        props.formik.setFieldValue('tarefas', newList);
        setIndexSelected(-1);
        setAnchorEl(null)
        return newList;
    }
    const confirm = async ()=>{
        let terefas = props.formik.values.tarefas;
        if(terefas[indexSelected].status === 1){
            terefas[indexSelected].status =0;
        }else{
            terefas[indexSelected].status =1;
        }
        await props.formik.setFieldValue('tarefas', terefas);
    }
    const edit = () => {
        const newList = props.formik.values.tarefas.filter((tarefa:Tarefa, index:number) => index === indexSelected);
        formikTarefa.setValues({
            responsavel : newList[0]?.responsavel.value,
            prazo: newList[0]?.prazo,
            descricao : newList[0]?.descricao
        });
        setAnchorEl(null);
        setModalTarefaOpen(true);
    };
  return (
     <>
         <GABForm
             icon={"jam:task-list"}
             title={"Tarefas"}
             subtile={`Tarefas da demanda`}>

             {
                 props.formik.values.responsaveis?.length > 0 ?
                     <GABDataTable
                         buttons={
                             <>
                                 <GABButtonIcon
                                     onClick={
                                         () => {
                                             setModalTarefaOpen(true)
                                         }
                                     }
                                     tip={'Adicionar Tarefa'}
                                     icon={"fluent:task-list-square-add-24-regular"}/>
                             </>
                         }
                         data={props.formik.values.tarefas}
                         title={""}
                         customRowRender={customRenderTarefas}
                         columns={[
                             {
                                 name: "responsavel",
                                 label: "Responsável",
                             },
                             {
                                 name: "prazo",
                                 label: "Prazo",
                             },
                             {
                                 name: "status",
                                 label: "Concluído?",
                             },
                             {
                                 name: "descricao",
                                 label: "Descrição",
                             },
                             {
                                 name: "acao",
                                 label: "Ação",
                             },
                         ]}/>
                     :
                     <div style={{
                         display: 'flex',
                         flexDirection: 'column',
                         justifyContent: 'center',
                         alignItems: 'center',
                         width: '100%'

                     }}>
                         <GABLabel
                             textAlign={"center"}
                             flip={true} size={6} title={"É necessário ao menos um representante"}
                             subTitle={"Antes de adicionar tarefas, é necessário atribuir um responsável à demanda."}/>
                         <img src='/static/illustrations/checklist-cuate.svg' width={'200rem'}/>

                     </div>

             }

         </GABForm>
         <GABMenu  id="basic-menu" anchorEl={anchorEl} handleClose={closeMenu}>
             <GABMenuItem
                 onClick={remove} label={"Excluir"} icon={"akar-icons:trash-can"} tooltip={"Excluir Tarefa"}/>
             <GABMenuItem onClick={edit} label={"Editar"} icon={"akar-icons:pencil"} tooltip={"Editar Tarefa"}/>

             <GABMenuItem onClick={confirm} label={"Mudar Status"} icon={"akar-icons:person-check"} tooltip={"Confirmar Tarefa"}/>

         </GABMenu>
         <GABModal
             title={'Cadastrar Tarefa'}
             open={modalTarefaOpen}
             onClickLeft={() => {
                 formikTarefa.resetForm();
                 setModalTarefaOpen(false)
             }}
             labelLeft={"Cancelar"}
             onClickRight={
                 () => {
                     if (formikTarefa.isValid) {
                         formikTarefa.submitForm();
                     }
                 }

             }
             labelRight={"Salvar"}
         >
             <form onSubmit={formikTarefa.handleSubmit}>
                 <Grid container spacing={3}>
                     <GabInput
                        required
                         label={"Responsável"}
                         id={"responsavel"}
                         formik={formikTarefa}
                     >
                         <>
                             <option key={"_1"} value={""}/>

                             {props.formik.values.responsaveis?.map((option:Generic) => (
                                 <option key={option.value} value={option.value}>
                                     {option?.label}
                                 </option>
                             ))}
                         </>
                     </GabInput>

                     <GABInput
                         label={"Prazo"}
                         id={"prazo"}
                         formik={formikTarefa}
                         datePick/>
                     <GabInput
                         label={"Tarefa"}
                         id={"descricao"}
                         formik={formikTarefa}
                         rows={4}
                     />


                 </Grid>
             </form>
         </GABModal>
     </>
  );
};

export default GABFormDemandaTask;
