import React, { useContext, useState } from "react";
import Page from "../../components/Page";
import { Fade, Grid, Typography } from "@mui/material";
import GABForm from "../../components/gab-form";
import { useFormik } from "formik";
import Chamado from "./chamado";
import * as Yup from "yup";
import AppProviderContext, {
  propsAppProviderContext
} from "../../app-provider";
import { chamadoCat } from "../../utils/select-utils.domain";
import GabInput from "../../components/gab-input";
import GABTextEditor from "../../components/gab-text-editor";
import GABFormButtons from "../../components/gab-form-buttons";
import GABDialog from "../../components/gab-dialog";
import { useNavigate } from "react-router-dom";

const Suporte: React.FC = () => {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [protocolo, setProtocolo] = useState("");
const navigator = useNavigate();

  const {
    setLoading,
    postAxios,
    addMessage,
    profile,
  }: propsAppProviderContext = useContext(AppProviderContext);

  const validationSchema = Yup.object().shape({
    assunto: Yup.string()
      .trim()
      .required("informe o assunto do chamado"),
    categoria: Yup.string()
      .trim()
      .required("informe a categoria do chamado"),
    descricao: Yup.string()
      .trim()
      .required("Descreva o seu problema ou sugestão")
  });

  const formik = useFormik({
    initialValues: new Chamado(),
    validationSchema: validationSchema,
    onSubmit: async values => {
      setLoading(true);
      const r = await postAxios("/parlamentar/chamado", values);
      if(r.data && r.data.protocolo){
        formik.resetForm();
        setDialogOpen(true);
        setProtocolo(r.data.protocolo);
      }else{
        addMessage("Erro ao criar chamado, tente novamente", "error");
      }
      setLoading(false);
    }
  });

  return (
    <Fade in timeout={1000}>
      <div>
        <Page title="Suporte">
          <form onSubmit={formik.handleSubmit}>
            <GABForm
              icon={"icon-park:help"}
              title={`Suporte`}
              subtile={"Abrir um chamado junto ao suporte"}
            >

              <GabInput
                label={"Categoria"}
                id={"categoria"}
                formik={formik}
                size={6}
              >
                {
                  <option key={''} value={''}></option>
                }
                {chamadoCat.map((option) => (
                  <option key={option?.value} value={option?.label}>
                    {option?.label}
                  </option>
                ))}
              </GabInput>
              <GabInput
                label={"Assunto"}
                id={"assunto"}
                formik={formik}
                size={6}
              />
              <Grid item md={6}>
                  <Typography variant="body1" color="secondary">
                    Para receber as atualizações referentes a este chamado via <b>WHATSAPP</b>, informe o telefone no campo ao lado.
                  </Typography>
              </Grid>
              <GabInput
                label={"Telefone"}
                id={"fone"}
                mask="phone"
                formik={formik}
                size={6}
              />
              <GABTextEditor
                id={'descricao'}
                formik={formik}
                label={"Descrição"}
              />
              <GABFormButtons
                size={6} formik={formik} />
            </GABForm>
          </form>
          <GABDialog
            open={dialogOpen}
            title="Sucesso"
            description={""}
            labelRight="OK"
            onClickRight={() => { setDialogOpen(false);
              navigator("/");
             }}
          >
            <center>
              <h2><b>{protocolo}</b></h2>
              <p>
                Este é o protocolo para acompanhamento do seu chamado.
              </p>
            </center>
          </GABDialog>
        </Page>
      </div>
    </Fade>
  );
};

export default Suporte;
