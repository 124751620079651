import React from "react";
import GABCrud from "../component/gab-crud";
import * as Yup from "yup";

const DemandaCategory: React.FC = () => {
    const url ='/parlamentar/demanda/categoria'
    const validation =  Yup.object().shape({
        nome: Yup.string().trim().required("informe o nome"),
        descricao: Yup.string().trim().required("informe a descrição"),
    });
    return (
        <GABCrud
        idField={'_id'}
        validationSchema={validation}
        service={url}
        pageTitle={'Categorias'}
        icon={"material-symbols:order-approve-rounded"}
        title={"Categoria"}
        serviceName={'categoria'}
        subtile={`Gerenciar categoria`}
        columns={
            [
                {
                    name: "nome",
                    label: "Categoria",
                },
                {
                    name: "descricao",
                    label: "Descrição",
                },
                {
                    name: "acao",
                    label: "Ação",
                }
            ]
        }
        fields={
            [
                {
                    id: "nome",
                    label: "Categoria",
                    size : 12
                },
                {
                    id: "descricao",
                    label: "Descrição",
                    size : 12
                }

            ]
        }
        />
    )
}

export default DemandaCategory;


