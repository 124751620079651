import {
    Button, Card, CardActions, CardContent,
    Fade,
    Grid
} from "@mui/material";
import React, {useContext, useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import AppProviderContext, {propsAppProviderContext} from "../../../app-provider";
import {useFormik} from "formik";
import GabInput from "../../../components/gab-input";
import {Caixa, Categoria, DemandaResponse, Generic, Regiao, Usuarios, UsuariosResponsavel} from "../component/typings";
import GABDataTable from "../../../components/gab-data-table";
import GABModal from "../../../components/gab-modal";
import {Solicitante} from "../dto/solicitante";
import {Demanda, DemandaUtils} from "../dto/demanda";
import GABFormButtons from "../../../components/gab-form-buttons";
import GABFormDemandaInfo from "./form-info";
import GABFormDemandaTask from "./form-task";
import GABFormDemandaUpload from "./form-upload";
import GABFormDemandaActivity from "./form-activity";
import GABLabel from "../../../components/gab-label";
import {useScroll} from "../../../hooks/useScroll";
import GABGridMenu from "../../../components/gab-grid-menu";
import GABListItem from "../../../components/gab-list-item";

const DemandaNew: React.FC = () => {
    let {modelType, id} = useParams();
    const [cloneDemanda,setCloneDemanda] = useState<Demanda>();
    const [solicitantes, setSolicitantes] = useState<Solicitante[]>([]);
    const [solicitante, setSolicitante] = useState<Solicitante>();
    const [kanban, setKanban]= useState<Generic[]>([]);
    const [modalSelectOpen, setModalSelectOpen] = useState(id? false: (modelType==='internal'? false: true));

    const [equipes, setEquipes] = useState<Generic[]>([])
    const [categoria, setCategoria] = useState<Categoria[]>([])
    const [regiao, setRegiao] = useState<Generic[]>([])
    const [usuarios, setUsuarios] = useState<UsuariosResponsavel[]>([])

    const [demandaResponse, setDemandaResponse] = useState<DemandaResponse>()

    const {
        getAxios,
        postAxios,
        putAxios,
        setLoading,
        profile
    }: propsAppProviderContext = useContext(AppProviderContext);

    const updateCategoria = async () => {
        const request = await getAxios("parlamentar/demanda/categoria");
        setCategoria(request.data.categoria);
    }
    const updateKanban = async () => {
        const request = await getAxios("parlamentar/demanda/kanban");
        const kb = request.data.kanban;
        setKanban([
            {
                value:kb.kb0.index,
                label:kb.kb0.titulo
            },
            {
                value:kb.kb1.index,
                label:kb.kb1.titulo
            },
            {
                value:kb.kb2.index,
                label:kb.kb2.titulo
            },
            {
                value:kb.kb3.index,
                label:kb.kb3.titulo
            },
            {
                value:kb.kb4.index,
                label:kb.kb4.titulo
            },
            {
                value:kb.kb5.index,
                label:kb.kb5.titulo
            },
            {
                value:kb.kb6.index,
                label:kb.kb6.titulo
            },
            {
                value:kb.kb7.index,
                label:kb.kb7.titulo
            },
            {
                value:kb.kb8.index,
                label:kb.kb8.titulo
            },
        ]);
    }
    const updateEquipe = async () => {
        const request = await getAxios("parlamentar/demanda/caixa");
        const equipesReq = request.data.caixa.map((c: Caixa) => new Generic(c?._id || '', c?.nome || ''))
        setEquipes(request.data.caixa);
    }
    const updateRegiao = async () => {
        const request = await getAxios("parlamentar/regiao");
        const regiaoReq = request.data.regiao.map((c: Regiao) => new Generic(c?._id || '', c?.nome || ''))
        setRegiao(regiaoReq);
    }
    const updateUsuario =async ()=>{
        const request = await getAxios("auth/usuario");
        const usuaiosRequested:Usuarios[] =  request.data;
        let tmp:UsuariosResponsavel[] = []
        usuaiosRequested.forEach( (u:Usuarios )=>{
            tmp.push({
                value : u._id,
                label: u.nome,
                email: u.email
            })
        });
        setUsuarios(tmp);
    }

    const updateDemanda = async ()=>{
        if (id){
            const response = await getAxios(`parlamentar/demanda/id/${id}`)
            let cloneDemandaTmp: any = Object.assign({}, response.data.demanda[0]);
            await setSolicitante(cloneDemandaTmp.pessoa[0])
            await formik.setValues(cloneDemandaTmp)
            await setCloneDemanda(Object.assign({}, cloneDemandaTmp));
        }else{
            await formik.setFieldValue('interna', modelType==='internal'? false: true);
        }
    }
    const {scrollToTop} = useScroll();

    const init = async () => {
        setLoading(true);
        await updateEquipe()
        await updateCategoria();
        await updateRegiao();
        await updateUsuario();
        await updateKanban();
        if (id) {
            await updateDemanda();
        }
        scrollToTop();
        setLoading(false);
    }

    useEffect(
        ()=>{
                init();
        },[]
    )

    const {
        addMessage,
    }: propsAppProviderContext = useContext(AppProviderContext);
    const formik = useFormik({
        initialValues: DemandaUtils.createNewDemanda( solicitante?._id),
        validationSchema: DemandaUtils.validationSchema,
        onReset: async (value:any) =>{
            let demanda = DemandaUtils.createNewDemanda( solicitante?._id);
            demanda.regiao = [];
            demanda.responsaveis = [];
            demanda.caixa = [];
            demanda.descricao ='';
            await formik.setValues(demanda)
            await prepareSubmit();
            scrollToTop();
        },
        onSubmit: async (values) => {
            setLoading(true);
            let clone: any = Object.assign({}, values);
            if (clone._id) {
                delete clone._id;
            }
            if(id){
                clone.id = id;
            }
            if(clone?.protocolo){
                clone.protocolo =clone.protocolo;
            }
            const resp = await postAxios('/parlamentar', clone)
            setDemandaResponse(resp.data);
            if(values?._id){
                try {
                    await putAxios("parlamentar/demanda/status",{
                        "id":values._id,
                        "status":values.status,
                        "notify": false
                    });
                }catch (e) {

                }
            }
            formik.resetForm();
            setSolicitante(undefined)
            addMessage(`Demanda foi salva com sucesso ${resp.data?.protocolo}`,"success")
            setLoading(false);
        },
    });

    const prepareSubmit =async (pessoa = solicitante)=>{
         
        let tmp = DemandaUtils.createNewDemanda();
        tmp.idPessoa =  pessoa?._id || pessoa?.id || '';
        tmp.cpfCnpj = pessoa?.cpfCnpj || '';
        tmp.eleitor = pessoa?.nome || '';
        tmp.interna = modelType === 'internal';
        tmp.dataFinalizada = '';
        tmp.caixa = [];
        tmp.regiao  =[];
        tmp.responsaveis = [];
        tmp.anexos = [];
        tmp.atividades =[];
        tmp.categorias = [];
        tmp.id = id;
        await formik.setValues(tmp)
    }

    const formikSolicitante = useFormik({
        initialValues: {nome: ''},
        onSubmit: async (values) => {
        },
    });

    const pesquisar = async (value: string) => {
        if (value.length > 2) {
            const response = await getAxios(`/parlamentar/pessoa/autocomplete/${value}`)
            setSolicitantes(response.data);
        }

    }

const getForm = ()=>{
        return(
            <form onSubmit={formik.handleSubmit}>

                <GABFormDemandaInfo
                    modelType={modelType}
                    formik={formik}
                    regioes={regiao}
                    kanban={kanban}
                    solicitante={solicitante}
                    categorias={categoria}
                    equipes={equipes}
                    responsaveis={usuarios}
                />

                <GABFormDemandaTask
                    formik={formik}
                    responsaveis={usuarios}
                />

                <GABFormDemandaUpload
                    formik={formik}
                />
                <GABFormDemandaActivity formik={formik}/>

                <Grid container >
                    <Grid item xs={12} md={6}/>
                    <GABFormButtons disableReset={id !== undefined}  size={12} formik={formik}/>
                </Grid>


            </form>
        )
}

    const getDefault = ()=>{

        return (
            <div style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                width: '100%'

            }}>

                {
                    demandaResponse? <div style={{
                        paddingTop :'4rem'
                    }}>
                        <Card>
                            <CardContent>
                                <GABLabel
                                    titleCopy
                                    titleVariant={"h6"}
                                    textAlign={"center"}
                                    flip
                                    title={demandaResponse?.protocolo.split('-')[1] || ''}/>

                                <GABLabel
                                    textAlign={"center"}
                                    titleVariant={"body1"}
                                    flip
                                    title={`Previsão de atendimento ${demandaResponse?.dataPrazo}`}/>

                            </CardContent>
                        </Card>

                        <GABGridMenu>
                            <GABListItem size={6} label={'Lista demanda'} icon={'fluent:text-bullet-list-square-warning-24-regular'} href={`/admin/demanda/list`}/>
                            <GABListItem size={6} label={'Kanban demanda'} icon={'bi:kanban'} href={`/admin/demanda/kanban`}/>
                        </GABGridMenu>
                    </div> : <></>
                }


            </div>
        )
    }

    const navigator = useNavigate()
    return (
        <Fade in timeout={1000}>
            <Grid container spacing={2} padding={2}>
                {demandaResponse? getDefault() :  (solicitante || modelType==='internal' || id ? getForm():getDefault())}

                <GABModal
                    title={'Selecione o solicitante'}
                    open={modalSelectOpen}
                    onClickRight={
                        async () => {
                            setModalSelectOpen(false);
                            scrollToTop();
                            navigator('/admin/demanda')
                        }
                    }
                    labelRight={"Sair"}
                >
                    <form onSubmit={formikSolicitante.handleSubmit}>
                        <Grid container spacing={3}>
                            <GabInput
                                onChange={(e: any) => {
                                    formikSolicitante.setValues(
                                        {
                                            nome: e.target.value
                                        }
                                    )
                                    pesquisar(e.target.value)
                                }}
                                label={"Solicitante"}
                                id={"nome"}
                                formik={formikSolicitante}
                            />
                            <GABDataTable
                                iconMenu={'akar-icons:arrow-up-right'}
                                onClickMenu={async (event, dataIndex) => {
                                    await init();
                                    setSolicitante(solicitantes[dataIndex]);
                                    setModalSelectOpen(false);
                                    scrollToTop()
                                    await prepareSubmit(solicitantes[dataIndex]);
                                }}
                                rowsPerPage={5}
                                search={false}
                                data={solicitantes} title={'Pessoas'} columns={
                                [
                                    {
                                        name: "nome",
                                        label: "Nome",
                                    },
                                    {
                                        name: "",
                                        label: "",
                                    }
                                ]
                            }/>

                        </Grid>
                    </form>
                </GABModal>
            </Grid>
        </Fade>
    );
};
export default DemandaNew;
