import {
    Fade,
    Grid,
    IconButton,
    TableCell,
    TableRow,
    Typography
} from "@mui/material";
import React, {useContext, useEffect, useState} from "react";
import AppProviderContext, {
    propsAppProviderContext,
} from "../../app-provider";
import MUIDataTable from "mui-datatables";
import {useTheme} from "@mui/material/styles";
import {alpha} from "@mui/material/styles";
import GABMenuItem from "../../components/gab-menu/item";
import GABMenu from "../../components/gab-menu";
import GABForm from "../../components/gab-form";
import GABDialog from "../../components/gab-dialog";
import User from "./user";
import {useNavigate, useParams} from "react-router-dom";
import GABIcon from "../../components/gab-icon";

const UserList: React.FC = () => {
    const {getAxios, setLoading, delAxios}: propsAppProviderContext =
        useContext(AppProviderContext);
    const [data, setData] = useState<User[]>([]);
    const [dialogOpen, setDialogOpen ] = useState(false);

    const init = async () => {
        setLoading(true);
        const value = await getAxios(`auth/usuario`);
        setData(value.data);
        setLoading(false);
    };

    useEffect(() => {
        init();
    }, []);

    const [anchorEl, setAnchorEl] = React.useState(null);

    const [indexSelected, setIndexSelected] = useState(-1);
    const handleClick = (event: any, rowIndex:number) => {
        setAnchorEl(event.currentTarget);
        setIndexSelected(rowIndex);
    };
    const closeMenu = () => {
        setIndexSelected(-1);
        setAnchorEl(null);
    };

    const navigator = useNavigate()
    const editarContato = () => {
        const id = data[indexSelected]._id;
        closeMenu();
        navigator(`/admin/user/edit/${id}`)
    };
    const excluirContato = async () => {
        const id = data[indexSelected]._id;
        setDialogOpen(false)
        setLoading(true)
        await delAxios(`auth/usuario/${id}`);
        setLoading(false)
        closeMenu();
        init();
    };
    const columns = [
        {
            name: "nome",
            label: "Nome",
        },
        {
            name: "cargo",
            label: "Cargo",
        },
        {
            name: 'listaTelefone',
            label: "Telefone",
        },
        {
            name: "Ação",
        },
    ];
    const theme = useTheme();
    const options = {
        selectableRows: "none",
        viewColumns: false,
        responsive: 'vertical',
        customRowRender: (data: any, dataIndex: any, rowIndex: any) => {
            let styleLocal: React.CSSProperties = {};
            if (rowIndex % 2 === 0) {
                styleLocal.backgroundColor = alpha(theme.palette.primary.light, 0.20);
            }
            return (
                <TableRow style={styleLocal}>
                    <TableCell>
                        <Typography>{data[0]}</Typography>
                    </TableCell>
                    <TableCell>
                        <Typography>{data[1]}</Typography>
                    </TableCell>
                    <TableCell>
                        {
                            data[2]?.map(
                                (val: any) => {
                                    return <Typography variant={"body2"}>{val.fone}</Typography>;
                                }
                            )
                        }
                    </TableCell>
                    <TableCell width={30}>
                        <IconButton onClick={
                            (event: any) => {
                                handleClick(event,dataIndex)
                            }
                        }>
                            <GABIcon
                                icon={"iconamoon:menu-kebab-vertical-bold"}
                            />
                        </IconButton>
                    </TableCell>
                </TableRow>
            )
        }
    } as any;

    return (
        <Fade in timeout={1000}>
            <div>
                <GABDialog
                    title ={"Excluir?"}
                    description={`Deseja excluir o usuário? ${data[indexSelected]?.nome || ''}`}
                    open={dialogOpen}
                    onClickLeft={()=>{
                        setDialogOpen(false)
                        closeMenu();
                    }}
                    labelLeft={"Não"}
                    onClickRight={excluirContato}
                    labelRight={"Sim"}
                />
                <GABMenu  id="basic-menu" anchorEl={anchorEl} handleClose={closeMenu}>
                    <GABMenuItem onClick={()=>{setDialogOpen(true)}} label={"Excluir"} icon={"akar-icons:trash-can"} tooltip={"Excluir contato"}/>
                    <GABMenuItem onClick={editarContato} label={"Editar"} icon={"akar-icons:pencil"} tooltip={"Editar contato"}/>
                </GABMenu>
                <GABForm
                    icon={"fluent:task-list-square-person-20-regular"}
                    title={"Usuários"}
                    subtile={`Lista de usuários do sistema`}
                >
                    <Grid item xs={12}>
                        <MUIDataTable
                            title={"Usuários"}
                            data={data}
                            columns={columns}
                            options={options}
                        />
                    </Grid>
                </GABForm>
            </div>
        </Fade>
    );
};
export default UserList;
