import {
  Button,
  ButtonGroup,
  Fade,
  Grid,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import React, {
  useContext,
  useEffect,
  useState,
  useRef,
  EffectCallback,
  DependencyList,
} from "react";
import AppProviderContext, {
  propsAppProviderContext,
} from "../../app-provider";
import {
  listaCamposContatos,
  listaEscolaridade,
  listaEstadoCivil,
  listaEstados,
  listaGeneros,
} from "../../utils/select-utils.domain";
import { useFormik } from "formik";
import MUIDataTable from "mui-datatables";
import { useTheme } from "@mui/material/styles";
import { alpha } from "@mui/material/styles";
import GABForm from "../../components/gab-form";

import GabInput from "../../components/gab-input/index";
import Contato from "../contato/contato";

import { green } from "@mui/material/colors";

import GABAutocomplete from "../../components/gab-autocomplete";
import GABFormButtons from "../../components/gab-form-buttons";

const useEffectAfterMount = (
  cb: EffectCallback,
  dependencies?: DependencyList
) => {
  const mounted = useRef(true);

  useEffect(() => {
    if (!mounted.current) {
      return cb();
    }
    mounted.current = false;
  }, dependencies);
};

const ContatoExport: React.FC = () => {
  const { postAxios, setLoading, getAxios }: propsAppProviderContext =
    useContext(AppProviderContext);
  const [data, setData] = useState<Contato[]>([]);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [count, setCount] = useState(0);
  const [campos, setCampos] = useState([]);
  const [filtros, setFiltros] = useState({});

  useEffectAfterMount(() => {
    buscar(page, rowsPerPage);
  }, [rowsPerPage, page]);

  const [listaGrupos, setListaGrupos] = useState <any[]>([]);

  const carregaGrupos = async () => {
    const value = await getAxios(`parlamentar/grupo/`);
    setListaGrupos(value.data.grupos);
  };

  useEffect(()=>{
    carregaGrupos();
  }, []);

  const buscar = async (page: number, rowsPerPage: number, dados?: any) => {
    if (!dados) {
      dados = filtros;
    }

    if(dados.grupos === "")
      delete dados.grupos;

    setLoading(true);
    const value = await postAxios(
      `parlamentar/pessoas/todas/${page}/${rowsPerPage}`,
      dados
    );
    setData(value.data.lista);
    setCount(value.data.total);
    setLoading(false);
  };

  const exportar = async () => {
    setLoading(true);

    let dados = {
      cpfCnpj: formik.values.cpfCnpj.replace(/\D/g, ""),
      nome: formik.values.nome,
      nascimento: formik.values.nascimento,
      rg: formik.values.rg,
      im: formik.values.im,
      sexo: formik.values.sexo,
      estadoCivil: formik.values.estadoCivil,
      nomeSocial: formik.values.nomeSocial,
      grupos: formik.values.grupos.toString() !== "" ? formik.values.grupos : { value: "", label: "" },
      "endereco.cep": formik.values.endereco?.cep,
      "endereco.cidade": formik.values.endereco?.cidade,
      "endereco.bairro": formik.values.endereco?.bairro,
      "endereco.uf": formik.values.endereco?.uf,
    };

    await postAxios(`parlamentar/exportar`, {
      dados: dados,
      campos: campos,
      notIn: [],
    });

    var token = await localStorage.getItem("PARLAMENTAR.APP_TOKEN");

    window.open(
      "https://www.gabinetevirtual.com/api/v1/parlamentar/exportacaoDownload/" +
        encodeURIComponent(token ? token : ""),
      "_blank"
    );

    setLoading(false);
  };

  const columns = [
    {
      name: "nome",
      label: "Nome",
    },
    {
      name: "nascimento",
      label: "Nascimento",
    },
    {
      name: "listaTelefone",
      label: "Telefone",
    },
  ];

  const theme = useTheme();

  const options = {
    search: false,
    selectableRows: "none",
    viewColumns: false,
    responsive: "vertical",
    serverSide: true,
    count: count,
    onChangePage: (page: number) => {
      setPage(page);
    },
    onChangeRowsPerPage: (rows: number) => {
      setRowsPerPage(rows);
    },
    customRowRender: (data: any, dataIndex: any, rowIndex: any) => {
      let styleLocal: React.CSSProperties = {};
      if (rowIndex % 2 === 0) {
        styleLocal.backgroundColor = alpha(theme.palette.primary.light, 0.2);
      }
      return (
        <TableRow style={styleLocal}>
          <TableCell>
            <Typography>{data[0]}</Typography>
          </TableCell>
          <TableCell>
            <Typography>{data[1]}</Typography>
          </TableCell>
          <TableCell>
            {data[2]?.map((val: any) => {
              return <Typography variant={"body2"}>{val.fone}</Typography>;
            })}
          </TableCell>
        </TableRow>
      );
    },
  } as any;

  const formik = useFormik({
    initialValues: new Contato(),
    onSubmit: async (values) => {
      let dados = {
        cpfCnpj: values.cpfCnpj.replace(/\D/g, ""),
        nome: values.nome,
        nascimento: values.nascimento,
        rg: values.rg,
        im: values.im,
        sexo: values.sexo,
        estadoCivil: values.estadoCivil,
        nomeSocial: values.nomeSocial,
        grupos: values.grupos,
        "endereco.cep": values.endereco?.cep,
        "endereco.cidade": values.endereco?.cidade,
        "endereco.bairro": values.endereco?.bairro,
        "endereco.uf": values.endereco?.uf,
      };

      setFiltros(dados);
      buscar(0, 10, dados);
      
      window.scrollTo({
        top: 500,
        left: 0,
        behavior: "smooth",
      });

      return false;
    },
  });

  return (
    <Fade in timeout={1000}>
      <div>
        <form onSubmit={formik.handleSubmit}>
          <GABForm
            icon={"fluent:task-list-square-person-20-regular"}
            title={"Exportar Contatos"}
            subtile={
              "Busca de contatos, pessoa física ou jurídica para exportação"
            }
          >
            <GabInput
              label={"Nome / Razão"}
              id={"nome"}
              size={6}
              value={formik.values.nome}
              onChange={formik.handleChange}
              error={formik.touched.nome && Boolean(formik.errors.nome)}
              helperText={formik.touched.nome && formik.errors.nome}
            />
            <GabInput
              label={"Nome Social/Fantasia"}
              id={"nomeSocial"}
              size={6}
              value={formik.values.nomeSocial}
              onChange={formik.handleChange}
              error={
                formik.touched.nomeSocial && Boolean(formik.errors.nomeSocial)
              }
              helperText={formik.touched.nomeSocial && formik.errors.nomeSocial}
            />

            <GabInput
              label={"Nasc. / Abert."}
              id={"nascimento"}
              size={3}
              value={formik.values.nascimento}
              mask={"date"}
              onChange={formik.handleChange}
              error={
                formik.touched.nascimento && Boolean(formik.errors.nascimento)
              }
              helperText={formik.touched.nascimento && formik.errors.nascimento}
            />
            <GabInput
              label={"sexo"}
              id={"sexo"}
              size={3}
              value={formik.values.sexo}
              onChange={formik.handleChange}
              error={formik.touched.sexo && Boolean(formik.errors.sexo)}
              helperText={formik.touched.sexo && formik.errors.sexo}
            >
              {listaGeneros.map((option) => (
                <option key={option?.value} value={option?.value}>
                  {option?.label}
                </option>
              ))}
            </GabInput>

            <GabInput
              label={"CPF / CNPJ"}
              id={"cpfCnpj"}
              size={3}
              mask={"cpfCnpj"}
              value={formik.values.cpfCnpj}
              onChange={formik.handleChange}
              error={formik.touched.cpfCnpj && Boolean(formik.errors.cpfCnpj)}
              helperText={formik.touched.cpfCnpj && formik.errors.cpfCnpj}
            />
            <GabInput
              label={"RG / IE"}
              id={"rg"}
              size={3}
              value={formik.values.rg}
              onChange={formik.handleChange}
              error={formik.touched.rg && Boolean(formik.errors.rg)}
              helperText={formik.touched.rg && formik.errors.rg}
            />

            <GabInput
              label={"IM"}
              id={"im"}
              size={3}
              value={formik.values.im}
              onChange={formik.handleChange}
              error={formik.touched.im && Boolean(formik.errors.im)}
              helperText={formik.touched.im && formik.errors.im}
            />

            <GabInput
              label={"Estado Civil"}
              id={"estadoCivil"}
              size={3}
              formik={formik}
            >
              {listaEstadoCivil.map((option) => (
                <option key={option?.value} value={option?.value}>
                  {option?.label}
                </option>
              ))}
            </GabInput>

            <GabInput
              label={"Escolaridade"}
              id={"escolaridade"}
              size={3}
              value={formik.values.escolaridade}
              formik={formik}
            >
              {listaEscolaridade.map((option) => (
                <option key={option?.value} value={option?.value}>
                  {option?.label}
                </option>
              ))}
            </GabInput>

            <GabInput
              label={"Grupo"}
              id={"grupos"}
              size={3}
              value={formik.values.grupos}
              formik={formik}
            >
              <option key="null" value=""></option>
              {listaGrupos.map((option) => (
                <option key={option?._id} value={option?._id}>
                  {option?.nome}
                </option>
              ))}
            </GabInput>

            <GabInput
              label={"Cep"}
              id={"endereco.cep"}
              size={3}
              mask={"cep"}
              value={formik.values.endereco?.cep}
              onChange={formik.handleChange}
              error={
                formik.touched.endereco?.cep &&
                Boolean(formik.errors.endereco?.cep)
              }
              helperText={
                formik.touched.endereco?.cep && formik.errors.endereco?.cep
              }
            />

            <GabInput
              label={"UF"}
              id={"endereco.uf"}
              size={3}
              value={formik.values.endereco?.uf}
              onChange={formik.handleChange}
              error={
                formik.touched.endereco?.uf &&
                Boolean(formik.errors.endereco?.uf)
              }
              helperText={
                formik.touched.endereco?.uf && formik.errors.endereco?.uf
              }
            >
              {listaEstados.map((option) => (
                <option key={option?.id} value={option?.id}>
                  {option?.estado}
                </option>
              ))}
            </GabInput>

            <GabInput
              label={"Cidade"}
              id={"endereco.cidade"}
              size={3}
              value={formik.values.endereco?.cidade}
              onChange={formik.handleChange}
              error={
                formik.touched.endereco?.cidade &&
                Boolean(formik.errors.endereco?.cidade)
              }
              helperText={
                formik.touched.endereco?.cidade &&
                formik.errors.endereco?.cidade
              }
            />

            <GabInput
              label={"Bairro"}
              id={"endereco.bairro"}
              size={3}
              value={formik.values.endereco?.bairro}
              onChange={formik.handleChange}
              error={
                formik.touched.endereco?.bairro &&
                Boolean(formik.errors.endereco?.bairro)
              }
              helperText={
                formik.touched.endereco?.bairro &&
                formik.errors.endereco?.bairro
              }
            />
            <GABFormButtons
              size={12}
              formik={formik}
              labelSubmit={"Previsualizar"}
            />

            <Grid item xs={12}>
              <GABForm
                icon={"noto:busts-in-silhouette"}
                title={"Dados a exportar"}
                subtile={
                  "selecione os campos que deverão ser adicionados a lista exportada"
                }
              >
                <GABAutocomplete
                  id={"l-contatos"}
                  size={12}
                  label={"campos"}
                  limitTags={-1}
                  optionLabel={"label"}
                  value={campos}
                  options={listaCamposContatos}
                  onChange={(event: any, valor: any) => {
                    setCampos(valor);
                  }}
                  groupBy="group"
                />
              </GABForm>
            </Grid>
            <Grid item xs={12}>
              <ButtonGroup
                variant="contained"
                style={{
                  width: "100%",
                  padding: "10px",
                  margin: "0px",
                  minHeight: "5rem",
                }}
              >
                <Button
                  id="btSave"
                  color="primary"
                  type="button"
                  style={{ width: "100%" }}
                  disabled={campos.length === 0}
                  onClick={exportar}
                >
                  Exportar
                </Button>
              </ButtonGroup>
            </Grid>
            <Grid item xs={12}>
              <div style={{ height: "20px" }} />
              <MUIDataTable
                title={`Prévia dos contatos a exportar (${count} contatos)`}
                data={data}
                columns={columns}
                options={options}
              />
            </Grid>
          </GABForm>
        </form>
      </div>
    </Fade>
  );
};
export default ContatoExport;
