import React, { useContext, useEffect, useState } from "react";
import Page from "../../../components/Page";
import {
  Button,
  Divider,
  Fade,
  Grid,
  Typography,
  useTheme,
} from "@mui/material";
import AppProviderContext, {
  propsAppProviderContext,
} from "../../../app-provider";
import GABForm from "../../../components/gab-form";
import GabInput from "../../../components/gab-input";
import GABDialog from "../../../components/gab-dialog";
import { useNavigate } from "react-router-dom";

const DemandaClean: React.FC = () => {
  const [data, setData] = useState<Date>();
  const [quantidade, setQtd] = useState(0);
  const [modalOpen, setModalOpen] = useState(false);
  const [codConf, setCodConf] = useState("");

  const {
    setLoading,
    putAxios,
    getAxios,
    addMessage,
  }: propsAppProviderContext = useContext(AppProviderContext);

  const buscarDemandas = async () => {
    setLoading(true);

    let r = await getAxios(`parlamentar/demanda/data/${data?.getTime()}`);

    if (r.data && r.data.qtd && r.data.qtd > 0) {
      setQtd(parseInt(r.data.qtd));
    } else {
      addMessage("Nenhuma demanda no período selecionado", "warning");
    }
    setLoading(false);
  };

  const navigator = useNavigate();

  const limparDemandas = async () => {
    if (parseInt(codConf) === quantidade) {
      await putAxios(`parlamentar/demanda/clean/${data?.getTime()}`, {});
      addMessage("Demandas fechadas com sucesso", "success");
      navigator("/admin/demanda");
    } else {
      addMessage("Quantidade informada na confirmação errada", "error");
    }
  };

  const theme = useTheme();

  return (
    <Fade in timeout={1000}>
      <div>
        <Page title={`Manutenção de demandas`}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <GABForm
                icon={"carbon:clean"}
                title={`Manutenção de demandas`}
                subtile={
                  "fazer limpeza de demandas antigas, para melhor funcionamento do sistema"
                }
              >
                <Grid item xs={12}>
                  <Typography variant="body1">
                    Selecione a data limite para a busca das demandas antigas:
                  </Typography>
                  <br />
                </Grid>
                <GabInput
                  label={"Demandas até:"}
                  id={"data"}
                  size={6}
                  value={data}
                  onChange={setData}
                  datePick
                />
                <Grid item md={6}>
                  <Button
                    fullWidth
                    variant="contained"
                    color="primary"
                    onClick={buscarDemandas}
                    style={{ height: "100%" }}
                  >
                    {" "}
                    Pesquisar demandas em aberto até esta data
                  </Button>
                </Grid>
                <br />
                <br />
                <Grid item xs={12}>
                  <Divider />
                </Grid>
                <br />
                <br />
                {quantidade > 0 ? (
                  <>
                    <Grid item xs={12}>
                      <center>
                        <Typography variant="h3">
                          Existem{" "}
                          <big style={{ color: theme.palette.secondary.main }}>
                            {quantidade}{" "}
                          </big>{" "}
                          demandas em aberto até a data informada.
                        </Typography>
                      </center>
                    </Grid>
                    <Grid item xs={4}></Grid>
                    <Grid item xs={4}>
                      <Button
                        fullWidth
                        variant="contained"
                        color="secondary"
                        onClick={() => {
                          setModalOpen(true);
                        }}
                        style={{ height: "100%" }}
                      >
                        {" "}
                        Finalizar demandas selecionadas
                      </Button>
                    </Grid>
                    <Grid item xs={4}></Grid>
                  </>
                ) : null}
              </GABForm>
            </Grid>
          </Grid>
          <GABDialog
            open={modalOpen}
            title="Confirmar Finalização"
            description=""
            labelLeft="cancelar"
            labelRight="Confirmar"
            onClickLeft={() => {
              setModalOpen(false);
            }}
            onClickRight={limparDemandas}
          >
            <Typography variant="subtitle1">
              Para confirmar a finalização das demandas informe a quantidade de
              demandas selecionadas (
              <big style={{ color: theme.palette.secondary.main }}>
                {quantidade}
              </big>
              ) no campo a baixo e confirme.
            </Typography>
            <br/>
            <GabInput
              label={"Confirmar quantidade:"}
              id={"qtd"}
              size={12}
              value={codConf}
              onChange={(e:any) =>{
                setCodConf(e.target.value);
              }}
              type="number"
            />
          </GABDialog>
        </Page>
      </div>
    </Fade>
  );
};

export default DemandaClean;
