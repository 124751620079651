import React from "react";
import Page from "../../components/Page";
import { Fade } from "@mui/material";
import useResponsive from "../../hooks/useResponsive";
import GABGridMenu from "../../components/gab-grid-menu";
import GABListItem from "../../components/gab-list-item";
import GABCanAccess from "../../components/gab-canAccess";

const DemandaMenu: React.FC = () => {
  const isDesktop = useResponsive("up", "lg");
  return (
    <Fade in timeout={1000}>
      <div>
        <Page title="Demandas">
          <GABGridMenu>
            <GABListItem
              label={"Nova demanda Interna"}
              icon={"mdi:home-group"}
              href={`/admin/demanda/new/internal`}
            />
            <GABListItem
              label={"Nova demanda Externa"}
              icon={"heroicons-outline:office-building"}
              href={`/admin/demanda/new/external`}
            />
            <GABListItem
              label={"Lista demanda"}
              icon={"fluent:text-bullet-list-square-warning-24-regular"}
              href={`/admin/demanda/list`}
            />
            <GABListItem
              label={"Kanban demanda"}
              icon={"bi:kanban"}
              href={`/admin/demanda/kanban`}
            />
            <GABListItem
              label={"Categoria"}
              icon={"material-symbols:order-approve-rounded"}
              href={`/admin/demanda/category`}
            />
            <GABListItem
              label={"Equipe"}
              icon={"fluent:people-team-28-filled"}
              href={`/admin/demanda/team`}
            />
            <GABListItem
              label={"Região"}
              icon={"tabler:map-2"}
              href={`/admin/demanda/location`}
            />
            <GABCanAccess roles={[1, 9]}><GABListItem
              label={"Atividade"}
              icon={"icon-park-twotone:log"}
              href={`/admin/demanda/activity`}
            /></GABCanAccess>
            <GABListItem
              label={"Manutenção"}
              icon={"carbon:clean"}
              href={`/admin/demanda/clean`}
            />
          </GABGridMenu>
        </Page>
      </div>
    </Fade>
  );
};

export default DemandaMenu;
