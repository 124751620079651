import React, { CSSProperties } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  DialogProps
} from "@mui/material";
import {useTheme} from "@mui/material/styles";

interface propsCardForm {
  open: boolean;
  onClickLeft?: () => void;
  onClickRight?: () => void;
  onClickMiddle?: () => void;
  onClose?: () => void;
  title: string;
  labelLeft?: string;
  labelMiddle?: string;
  labelRight?: string;
  children?: React.ReactNode;
  maxWidth?: any;
}

const GABModal: React.FC<propsCardForm> = props => {
  const theme = useTheme();
  return (
    <Dialog
      open={props.open}
      onClose={props.onClose}
      maxWidth={props?.maxWidth}
      fullWidth

    >
      <DialogTitle
          style={
            {
              padding : '1rem',
              backgroundColor: `${theme.palette.grey["A100"]}`
            }
          }
          id="alert-dialog-title">{props.title}</DialogTitle>
      <DialogContent
          style={
            {
              padding : '1rem',
              backgroundColor: `${theme.palette.grey["A100"]}`
            }
          }
      >{props.children}</DialogContent>
      <DialogActions
          style={
            {
              padding : '1rem',
              backgroundColor: `${theme.palette.grey["A100"]}`
            }
          }
      >
        {
          props.onClickLeft? <Button
              onClick={props.onClickLeft}
              variant="contained"
              color={"secondary"}
          >
            {props.labelLeft || "Não"}
          </Button> : <></>
        }

          {
              props.onClickMiddle? <Button
                  onClick={props.onClickMiddle}
                  variant="contained"
                  color={"inherit"}
              >
                  {props.labelMiddle || "?"}
              </Button> : <></>
          }

        {
          props.onClickRight? <Button
              onClick={props.onClickRight}
              variant="contained"
              color={"primary"}
              autoFocus
          >
            {props.labelRight || "Sim"}
          </Button> : <></>
        }


      </DialogActions>
    </Dialog>
  );
};

export default GABModal;
