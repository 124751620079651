import React from "react";
import {Helmet} from 'react-helmet-async';
import {Box,  Grid} from '@mui/material';

interface propTypes {
    title?: string,
    meta?: any,
    ref?: any,
    children?: any
};

const Page: React.FC<propTypes> = (props) => {
    return (
        <div>
            <Helmet>
                <title>{`${props.title}`}</title>
                {props.meta}
            </Helmet>

            <Box ref={props.ref}>
                <Grid container spacing={1} padding={0}>
                    <Grid item style={{width: '100%'}}>
                        {props.children}
                    </Grid>
                </Grid>
            </Box>
        </div>

    );
}

export default Page;
