import { Grid, Fade, Typography, Divider } from "@mui/material";
import AppProviderContext, {
  propsAppProviderContext,
} from "../../app-provider";
import React, { useContext, useEffect, useState } from "react";

import GABForm from "../../components/gab-form";
import Page from "../../components/Page";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import customAgenda from "../agenda/component/gab-customAgenda";
import CustomToolbarDay from "../agenda/component/gab-customToolBarHoje";
import Evento from "../agenda/evento";
import { Calendar, dateFnsLocalizer } from "react-big-calendar";
import { format, parse, startOfWeek, getDay } from "date-fns";
import ptBR from "date-fns/locale/pt-BR";
import { useTheme } from "@mui/material/styles";
import GABDashboardCard from "../../components/gab-dashboard-card";
import { Board } from "../demanda/component/typings";
import KanbanUtils from "../demanda/kanban/kanban.utils";
import GABCanAccess from "../../components/gab-canAccess";
import DemandaPorRegiao from "../../components/charts/demandaRegiao";
import DemandaPorPrioridade from "../../components/charts/demandaPrioridade";
import GABButtonIcon from "../../components/gab-button-icon";

const DashboardApp: React.FC = () => {
  const [propostas, setPropostas] = useState(0);
  const [contatos, setContatos] = useState(0);
  const [demandas, setDemandas] = useState(0);
  const [demandasAntigas, setDemandasAntigas] = useState(0);
  const [demandasRegiao, setDemandasRegiao] = useState([]);
  const [demandasPrioridade, setDemandasPrioridade] = useState([]);
  const [aniversariantes, setAniversariantes] = useState(0);
  const [kanban, setKanban] = useState<Board>(KanbanUtils.newBoard());
  const [demandasStatus, setDemandasStatus] = useState({
    abertas: 0,
    emAndamento: 0,
    fechadas: 0,
    candeladas: 0,
    respondidas: 0,
  });
  const [agendaType, setAgendaType] = useState("parlamentar");
  const [idAgenda, setIdAgenda] = useState<string>();
  const [idAgendaSec, setIdAgendaSec] = useState<string>();
  const [eventsP, setEventsP] = useState<Evento[]>([]);
  const [eventsG, setEventsG] = useState<Evento[]>([]);
  const { getAxios, setLoading, canAccess }: propsAppProviderContext =
    useContext(AppProviderContext);
  const locales = {
    "pt-BR": ptBR,
  };
  const localizer = dateFnsLocalizer({
    format,
    parse,
    startOfWeek,
    getDay,
    locales,
  });

  const navigator = useNavigate();
  const qtdContatos = async () => {
    let tPF = await getAxios("parlamentar/widget/pessoa/pf");
    let tPJ = await getAxios("parlamentar/widget/pessoa/pj");
    setContatos(parseInt(tPF.data.total) + parseInt(tPJ.data.total));
  };

  const qtdAniversariantes = async () => {
    let tA = await getAxios("parlamentar/widget/qtdAniversariantes/contato");
    setAniversariantes(tA.data || 0);
  };

  const qtdDemandas = async () => {
    let tDM = await getAxios("parlamentar/widget/demanda/");
    setDemandas(tDM.data.total);
  };

  const demandasPorRegiao = async () => {
    let tDM = await getAxios("parlamentar/demandaRegiao");
    setDemandasRegiao(tDM.data);
  };
  
  const buscaDemandasAntigas = async () => {
    let data = new Date();
    data.setMonth(data.getMonth() - 6);

    let tDM = await getAxios(`parlamentar/demanda/data/${data.getTime()}`);
    setDemandasAntigas(tDM.data.qtd);
  };

  const demandasPorPrioridade = async () => {
    let tDM = await getAxios("parlamentar/demandaPrioridade");

    let valor = tDM.data.filter((e: any) => e._id && e._id !== "");

    setDemandasPrioridade(valor);
  };

  const qtdDemandaStatus = async () => {
    let ab = await getAxios(`parlamentar/qtdDemandaStatus/1`);
    let ea = await getAxios(`parlamentar/qtdDemandaStatus/2`);
    let fc = await getAxios(`parlamentar/qtdDemandaStatus/4`);
    let cc = await getAxios(`parlamentar/qtdDemandaStatus/3`);
    let rd = await getAxios(`parlamentar/qtdDemandaStatus/5`);

    setDemandasStatus({
      abertas: ab.data.total || 0,
      emAndamento: ea.data.total || 0,
      fechadas: fc.data.total || 0,
      candeladas: cc.data.total || 0,
      respondidas: rd.data.total || 0,
    });
  };

  const qtdDemandaKanban = async () => {
    const resultKanban = await getAxios("parlamentar/demanda/kanban");
    const board: Board = resultKanban.data.kanban;
    setKanban(board);
  };

  const qtdPropostas = async () => {
    let tProp = await getAxios("parlamentar/widget/propositura/");

    if (tProp.data.total) {
      setPropostas(tProp.data.total);
    } else {
      try{
        var demandas = await axios.post(
          "https://ple.cl.df.gov.br/pleservico/api/public/proposicao/filter?page=0&size=1&sort=",
          {
            intervaloNumeracao: false,
            ementa: false,
            documentoInicial: false,
            todosDocumentos: false,
            autoria: tProp.data,
            unidadePesquisadora: "0",
          }
        );

        if (demandas.data) {
          setPropostas(demandas.data.totalElements);
        }

      }catch(e){
        setPropostas(0);
      }

    }
  };

  const configAgenda = async () => {
    let agendas = await getAxios("agenda/idsAgenda");
    var id = "";
    var idSec = "";

    if (agendaType === "gabinete") {
      id = agendas.data.id_agenda_gabinete;
      idSec = agendas.data.id_agenda;
    } else {
      id = agendas.data.id_agenda;
      idSec = agendas.data.id_agenda_gabinete;
    }

    setIdAgenda(id);
    setIdAgendaSec(idSec);
  };

  useEffect(() => {
    qtdContatos();
    qtdAniversariantes();
    qtdDemandas();
    qtdPropostas();
    qtdDemandaStatus();
    qtdDemandaKanban();
    demandasPorRegiao();
    demandasPorPrioridade();
    buscaDemandasAntigas();
  }, []);

  useEffect(() => {
    getEvents(1);
    getEvents(2);
  }, [idAgenda]);

  useEffect(() => {
    configAgenda();
  }, [agendaType]);

  const getEvents = async (type: number) => {
    setLoading(true);

    var resposta = [];

    let r = await getAxios(
      `agenda/hoje/${type === 1 ? idAgenda : idAgendaSec}`
    );
    resposta = r.data;

    var eventos: Evento[] = [];

    if (resposta.events) {
      resposta.events.forEach((item: any, index: number) => {
        eventos.push({
          id: item.id,
          title: item.summary,
          local: item.location,
          participantes: item.attendees,
          descricao: item.description,
          start: new Date(item.start.dateTime),
          end: new Date(item.end.dateTime),
          allDay: false,
          colorId: item.colorId,
          resource: item,
          salvarDuplo: false,
        });
      });
    }

    if (type === 1) setEventsP(eventos);
    else setEventsG(eventos);

    setLoading(false);
  };

  const getToolbar = (toolbar: any) => {
    return CustomToolbarDay(
      toolbar,
      agendaType === "parlamentar" ? 1 : 2,
      idAgenda
    );
  };

  const theme = useTheme();

  return (
    <Fade in timeout={1000}>
      <div>
        <Page title="Dashboard">
          <Grid container spacing={2} padding={2}>
            <GABCanAccess roles={[4, 9]}>
              <Grid item xs={12} md={6}>
                <DemandaPorRegiao dados={demandasRegiao} />
              </Grid>
              <Grid item xs={12} md={6}>
                <DemandaPorPrioridade dados={demandasPrioridade} />
              </Grid>

              <GABForm
                title={"Kanban"}
                subtile={"demandas no kanban"}
                icon={"bi:kanban-fill"}
                action={
                  <GABButtonIcon
                    onClick={() => {
                      navigator("/admin/demanda/kanban");
                    }}
                    icon={"akar-icons:arrow-forward-thick-fill"}
                    color={theme.palette.secondary.main}
                    size={15}
                  />
                }
              >
                <Grid item xs={12} container>
                  <GABDashboardCard
                    size={3}
                    title={kanban.kb1.demandas.toString()}
                    tip={kanban.kb1.titulo}
                    subtile={kanban.kb1.titulo}
                    bgColor={kanban.kb1.cor}
                  />
                  <GABDashboardCard
                    size={3}
                    title={kanban.kb2.demandas.toString()}
                    tip={kanban.kb2.titulo}
                    subtile={kanban.kb2.titulo}
                    bgColor={kanban.kb2.cor}
                  />
                  <GABDashboardCard
                    size={3}
                    title={kanban.kb3.demandas.toString()}
                    tip={kanban.kb3.titulo}
                    subtile={kanban.kb3.titulo}
                    bgColor={kanban.kb3.cor}
                  />
                  <GABDashboardCard
                    size={3}
                    title={kanban.kb4.demandas.toString()}
                    tip={kanban.kb4.titulo}
                    subtile={kanban.kb4.titulo}
                    bgColor={kanban.kb4.cor}
                  />
                  <GABDashboardCard
                    size={3}
                    title={kanban.kb5.demandas.toString()}
                    tip={kanban.kb5.titulo}
                    subtile={kanban.kb5.titulo}
                    bgColor={kanban.kb5.cor}
                  />
                  <GABDashboardCard
                    size={3}
                    title={kanban.kb6.demandas.toString()}
                    tip={kanban.kb6.titulo}
                    subtile={kanban.kb6.titulo}
                    bgColor={kanban.kb6.cor}
                  />
                  <GABDashboardCard
                    size={3}
                    title={kanban.kb7.demandas.toString()}
                    tip={kanban.kb7.titulo}
                    subtile={kanban.kb7.titulo}
                    bgColor={kanban.kb7.cor}
                  />
                  <GABDashboardCard
                    size={3}
                    title={kanban.kb8.demandas.toString()}
                    tip={kanban.kb8.titulo}
                    subtile={kanban.kb8.titulo}
                    bgColor={kanban.kb8.cor}
                  />
                </Grid>
              </GABForm>
              <GABForm
                title={"Status"}
                subtile={"demandas por Status"}
                icon={"fluent-mdl2:away-status"}
              >
                <Grid item xs={12} container>
                  <GABDashboardCard
                    size={2}
                    bgColor={theme.palette.grey[300]}
                    icon={"octicon:alert-16"}
                    title={demandasStatus.abertas.toString()}
                    subtile={"Abertas"}
                  />
                  <GABDashboardCard
                    bgColor={theme.palette.grey[300]}
                    size={2}
                    icon={"carbon:in-progress-warning"}
                    title={demandasStatus.emAndamento.toString()}
                    subtile={"Em Andamento"}
                  />
                  <GABDashboardCard
                    size={2}
                    bgColor={theme.palette.grey[300]}
                    icon={"solar:chat-dots-broken"}
                    title={demandasStatus.respondidas.toString()}
                    subtile={"Respondidas"}
                  />
                  <GABDashboardCard
                    size={2}
                    bgColor={theme.palette.grey[300]}
                    icon={"file-icons:donejs"}
                    title={demandasStatus.fechadas.toString()}
                    subtile={"Finalizadas"}
                  />
                  <GABDashboardCard
                    size={2}
                    bgColor={theme.palette.grey[300]}
                    icon={"tabler:clock-stop"}
                    title={demandasStatus.candeladas.toString()}
                    subtile={"Canceladas"}
                  />
                  <GABDashboardCard
                    size={2}
                    bgColor={theme.palette.grey[300]}
                    icon={"wpf:past"}
                    title={demandasAntigas.toString()}
                    subtile={"há +6 meses"}
                    tip="Demandas abertas a mais de 6 meses"
                  />
                </Grid>
              </GABForm>
            </GABCanAccess>
            <GABCanAccess roles={[3]}>
              <Grid item xs={12} md={4}>
                <GABForm
                  icon={"akar-icons:people-group"}
                  title={"Contatos"}
                  subtile={"Pessoas e entidades"}
                >
                  <Grid item xs={12}>
                    <Typography variant={"h3"}>{contatos}</Typography>
                  </Grid>
                </GABForm>
              </Grid>

              <Grid item xs={12} md={4}>
                <GABForm
                  icon={"ph:cake-fill"}
                  title={"Aniversariantes"}
                  subtile={"aniversariantes de hoje"}
                >
                  <Grid item xs={12}>
                    <Typography variant={"h3"}>{aniversariantes}</Typography>
                  </Grid>
                </GABForm>
              </Grid>
            </GABCanAccess>
            <Grid item xs={12} md={4}>
              <GABForm
                icon={"codicon:law"}
                title={"Propostas"}
                subtile={"Propostas do parlamentar"}
                action={
                  <GABButtonIcon
                    onClick={() => {
                      navigator("/admin/propostas");
                    }}
                    icon={"akar-icons:arrow-forward-thick-fill"}
                    color={theme.palette.secondary.main}
                    size={15}
                  />
                }
              >
                <Grid item xs={12}>
                  <Typography variant={"h3"}>{propostas}</Typography>
                </Grid>
              </GABForm>
            </Grid>
            <Grid item xs={12}>
              <Divider
                style={{
                  borderColor: theme.palette.grey[300],
                  marginTop: "8px",
                }}
              />
            </Grid>
            <GABCanAccess roles={[2, 8]}>
              <Grid item xs={12} md={6}>
                <GABForm
                  size={12}
                  icon={"akar-icons:calendar"}
                  title={"Agenda do Parlamentar"}
                  subtile={`Agenda dos compromissos de hoje`}
                >
                  <Grid item xs={12}>
                    <Calendar
                      localizer={localizer}
                      messages={{
                        date: "Data",
                        time: "Hora",
                        event: "Evento",
                        noEventsInRange: "Nenhum evento no período",
                        allDay: "Dia todo",
                        showMore: (total: number) => `+${total} Eventos`,
                      }}
                      events={eventsP}
                      defaultDate={new Date()}
                      length={0.5}
                      culture="pt-BR"
                      defaultView={"agenda"}
                      components={{
                        toolbar: getToolbar,
                        agenda: {
                          event: customAgenda,
                        },
                      }}
                    />
                  </Grid>
                </GABForm>
              </Grid>
              <Grid item xs={12} md={6}>
                <GABForm
                  size={12}
                  icon={"line-md:calendar"}
                  title={"Agenda do Gabinete"}
                  subtile={`Agenda dos compromissos de hoje`}
                >
                  <Grid item xs={12}>
                    <Calendar
                      localizer={localizer}
                      messages={{
                        date: "Data",
                        time: "Hora",
                        event: "Evento",
                        noEventsInRange: "Nenhum evento no período",
                        allDay: "Dia todo",
                        showMore: (total: number) => `+${total} Eventos`,
                      }}
                      events={eventsG}
                      defaultDate={new Date()}
                      length={0.5}
                      culture="pt-BR"
                      defaultView={"agenda"}
                      components={{
                        toolbar: getToolbar,
                        agenda: {
                          event: customAgenda,
                        },
                      }}
                    />
                  </Grid>
                </GABForm>
              </Grid>
            </GABCanAccess>
          </Grid>
        </Page>
      </div>
    </Fade>
  );
};

export default DashboardApp;
