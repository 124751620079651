import React from "react";
import {Chip} from "@mui/material";

interface propsGABChip {
  label:string;
  backgroundColor?: any;
}

const GABChip: React.FC<propsGABChip> = props => {
  return (
    <Chip label={props.label} style={{
      backgroundColor: `${props?.backgroundColor}`
    }}/>
  );
};

export default GABChip;
