import { Divider, Fade, Grid, Button } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import GABModal from "../../components/gab-modal";
import AppProviderContext, {
  propsAppProviderContext,
} from "../../app-provider";
import GABLabel from "../../components/gab-label";

import GABForm from "../../components/gab-form";
import { useTheme } from "@mui/material/styles";

import { format } from "date-fns";

import GabInput from "../../components/gab-input";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import Chamado, { Resposta } from "./chamado";

import ptBR from "date-fns/locale/pt-BR";
import GABCanAccess from "../../components/gab-canAccess";
import GABInput from "../../components/gab-input";
import { STATUS_CHAMADO } from "../../utils/select-utils.domain";

interface propsChamadoDetail {
  idChamado?: string;
  isOpen: boolean;
  onClose: () => void;
}

const ChamadoDetail: React.FC<propsChamadoDetail> = (props) => {
  const {
    getAxios,
    postAxios,
    putAxios,
    setLoading,
    addMessage,
    canAccess,
  }: propsAppProviderContext = useContext(AppProviderContext);
  const [chamado, setChamado] = useState<Chamado>();
  const prof = JSON.parse(
    localStorage.getItem("PARLAMENTAR.APP_USUARIO") || ""
  );
  const formik = useFormik({
    initialValues: new Resposta(),
    onSubmit: async (values) => {
      setLoading(true);

      formik.values.userName = prof?.nome;

      if(formik.values.status === ""){
        formik.values.status = chamado?.status || "";
      }

      const resp = await postAxios(
        `/parlamentar/chamado/resposta/${props.idChamado}`,
        {...formik.values, view: canAccess([1000]) ? 2 : 1}
      );

      init();
      formik.resetForm();
      addMessage(`Chamado atualizado com sucesso`, "success");
      setLoading(false);
    },
  });

  const init = async () => {
    if (props?.idChamado && props?.idChamado !== "") {
      setLoading(true);
      const response = await getAxios(`parlamentar/chamado/${props.idChamado}`);

      let view = 0;
      if((canAccess([1000]) && response.data[0].view === 1) || (!canAccess([1000]) && response.data[0].view === 2)){
        view = 0;
      }else if(canAccess([1000]) && response.data[0].view === 2){
        view = 2;
      }else if(response.data[0].view === 1){
        view = 1;
      }

      putAxios(`parlamentar/chamado/view/${props.idChamado}`, {view: view});

      let clone: any = Object.assign({}, response.data[0]);
      setChamado(clone);

      setLoading(false);
    }
  };

  useEffect(() => {
    init();
  }, [props?.idChamado]);

  return (
    <Fade in timeout={1000}>
      <div>
        <GABModal
          maxWidth={"60%"}
          title={`Chamado # ${chamado?.protocolo}`}
          open={props.isOpen}
          onClickRight={async () => {
            props.onClose();
          }}
          labelRight={"OK"}
        >
          <GABForm
            icon={"tdesign:task-marked"}
            title={"chamado"}
            subtile={"Detalhes da abertura do chamado"}
          >
            <Grid container spacing={1} padding={0}>
              <GABLabel
                size={4}
                title={"Categoria"}
                subTitle={chamado?.categoria}
              />

              <GABLabel
                size={4}
                title={"Assunto"}
                subTitle={chamado?.assunto}
              />

              <GABLabel size={4} title={"Status"} subTitle={chamado?.status} />

              <Grid item xs={12}>
                <Divider />
              </Grid>
              <Grid item xs={12}>
                {chamado ? (
                  <div
                    dangerouslySetInnerHTML={{ __html: chamado.descricao }}
                  ></div>
                ) : null}
              </Grid>
            </Grid>
          </GABForm>
          <GABForm
            icon={"grommet-icons:chat"}
            title={"Respostas"}
            subtile={`respostas referentes ao chamado`}
          >
            <Grid container spacing={3} padding={0}>
              {chamado?.mensagens?.map((t: Resposta, index: number) => (
                <Grid item xs={12}>
                  <fieldset>
                    <legend>{t.userName}</legend>
                    <p>{t.mensagem}</p>
                    <span style={{ float: "right" }}>
                      <small>
                        {" "}
                        enviado em:{" "}
                        {format(new Date(t?.dataHora), "dd/MM/yyyy HH:mm", {
                          locale: ptBR,
                        })}
                      </small>
                    </span>
                  </fieldset>
                </Grid>
              ))}
            </Grid>
            <Grid item xs={12}>
              <Divider style={{ height: "24px" }} />
            </Grid>
            <br />
            <br />
            {(chamado?.status && chamado?.status !== "FECHADO") ||
            canAccess([1000]) ? (
              <>
                <GabInput
                  label={"Mensagem"}
                  id={"mensagem"}
                  formik={formik}
                  rows={2}
                  size={12}
                />

                <GABCanAccess roles={[1000]}>
                    <Grid item xs={12} md={6}/>
                  <GabInput
                    label={"Status"}
                    id={"status"}
                    value={formik.values?.status !== "" ? formik.values?.status : chamado?.status}
                    formik={formik}
                    size={3}
                  >
                    {<option key={""} value={""}></option>}
                    {STATUS_CHAMADO.map((option) => (
                      <option key={option?.value} value={option?.label}>
                        {option?.label}
                      </option>
                    ))}
                  </GabInput>
                </GABCanAccess>
                <Grid item xs={12} md={3}>
                  <Button
                    fullWidth
                    style={{height: "100%"}}
                    variant="contained"
                    color="secondary"
                    onClick={formik.submitForm}
                    disabled={
                      !formik.values.mensagem || formik.values.mensagem === ""
                    }
                  >
                    ENVIAR
                  </Button>
                </Grid>
              </>
            ) : null}
          </GABForm>
        </GABModal>
      </div>
    </Fade>
  );
};
export default ChamadoDetail;
