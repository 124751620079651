import { Board, Demanda, Kanban } from "../component/typings";

export default class KanbanUtils {
  static newBoard = (): Board => {
    return {
      kb0: KanbanUtils.newKanban(0),
      kb1: KanbanUtils.newKanban(1),
      kb2: KanbanUtils.newKanban(2),
      kb3: KanbanUtils.newKanban(3),
      kb4: KanbanUtils.newKanban(4),
      kb5: KanbanUtils.newKanban(5),
      kb6: KanbanUtils.newKanban(6),
      kb7: KanbanUtils.newKanban(7),
      kb8: KanbanUtils.newKanban(8),
    } as Board;
  };
  static newKanban = (index: number): Kanban => {
    return {
      titulo: "",
      index: index,
      demanda: [],
      demandas: 0,
    } as Kanban;
  };
}
