import React, {useEffect, useState} from "react";
import "./App.css";
import AppProviderContext from "./app-provider";
import {useSnackbar, VariantType} from "notistack";
import axios from "axios";
import ThemeProvider from "./theme";
import ScrollToTop from "./components/ScrollToTop";
import Router from "./routes";
import {useNavigate} from "react-router-dom";
import {listaAcessos} from "./utils/select-utils.domain";
import GabModal from "./components/gab-modal";
import {Grid} from "@mui/material";
import GabLabel from "./components/gab-label";

export const IDAPP = "0f403419-a464-4d60-9502-666785028290";
export const APP_TOKEN = "PARLAMENTAR.APP_TOKEN";
export const APP_USUARIO = "PARLAMENTAR.APP_USUARIO";

function useAlert() {
    const {enqueueSnackbar} = useSnackbar();

    function addMessage(
        message: string,
        type: VariantType,
        autoHideDuration?: number
    ) {
        enqueueSnackbar(message, {
            variant: type,
            autoHideDuration: autoHideDuration
        });
    }

    return [addMessage];
}

function useMeAxios() {
    const [tokenStr, setTokenStr] = useState("");
    const navigator = useNavigate();
    const instance = axios.create({
        baseURL: `${process.env.REACT_APP_BASE_URL}`
    });

    async function getToken() {
        const token = await localStorage.getItem(APP_TOKEN);
        if (token) {
            return `Bearer ${token}`;
        }
        return `Bearer ${IDAPP}`;
    }

    async function setToken(token: string) {
        await localStorage.setItem(APP_TOKEN, token || "");
    }
    const checkError = (e:any) =>{
        if(e?.response?.status===403){
            localStorage.clear();
            navigator('/login')
        }
    }
    async function getAxios(url: string): Promise<any> {
        const token = await getToken();
        try {
            const resp: any = await instance.get(url, {
                headers: {
                    Authorization: `${token}`,
                    "Application-Authorization": IDAPP
                }
            });
            return Promise.resolve(resp);
        } catch (e) {
            checkError(e);
            return Promise.reject(e);
        }
    }

    async function delAxios(url: string): Promise<any> {
      try{
          const token = await getToken();
          const resp: any = await instance.delete(`${url}`, {
              headers: {
                  Authorization: `${token}`
              }
          });
          return Promise.resolve(resp);
      }catch (e) {
          checkError(e);
          return Promise.reject(e);
      }
    }

    async function postAxios(url: string, data?: any, options?: any): Promise<any> {
        const token = await getToken();
        try {
            const resp: any = await instance.post(url, data, {
                headers: {
                    ...options?.headers,
                    Authorization: `${token}`
                }
            });
            return Promise.resolve(resp);
        } catch (e:any) {
            checkError(e);
            return Promise.reject(e);
        }
    }

    async function getPdfAxios(url: string): Promise<any> {
        const token = await getToken();
        try {
            const resp: any = await instance.get(url, {
                headers: {
                    Authorization: `${token}`,
                    "Application-Authorization": IDAPP
                },
                responseType: "blob"
            });
            return Promise.resolve(resp);
        } catch (e) {
            checkError(e);
            return Promise.reject(e);
        }
    }

    async function putAxios(url: string, data: any): Promise<any> {
      try{
          const token = await getToken();
          const resp: any = await instance.put(url, data, {
              headers: {
                  Authorization: `${token}`
              }
          });
          return Promise.resolve(resp);
      }catch (e) {
          checkError(e);
          return Promise.reject(e);
      }
    }

    return [getAxios, postAxios, putAxios, setToken, delAxios, getPdfAxios];
}

const App: React.FC = () => {
    function useProfile() {
        const [profile, setProfile]: any = useState({});

        useEffect(() => {
            const prof = localStorage.getItem(APP_USUARIO);
            if (prof) {
                setProfile(JSON.parse(prof || ""));
            }
        }, []);

        return [profile, setProfile];
    }

    const [
        getAxios,
        postAxios,
        putAxios,
        setToken,
        delAxios,
        getPdfAxios
    ] = useMeAxios();

    async function getPicture(id: string): Promise<string> {
        try {
            const usrImage = await getAxios(`auth/usuario/image/${id}`);
            return usrImage.data;
        } catch (e) {
        }
        return "";
    }
    function canAccess (roles:number[]){
        const listaAcessos = profile?.acessos?.map((i:any)=>i.value);
        for (const item1 of listaAcessos || []) {
            if (roles.includes(item1)) {
                return true;
            }
        }

        if(!roles || roles.length === 0){
            return true;
        }

        return false;
    }
    const [isLoading, setLoading]: any = useState(false);
    const [editContato, setEditContato]: any = useState("");
    const [addMessage] = useAlert();

    const [profile, setProfile] = useProfile();
    const initValue = {
        addMessage,
        setToken,
        getAxios,
        postAxios,
        getPdfAxios,
        putAxios,
        isLoading,
        profile,
        setLoading,
        setProfile,
        editContato,
        setEditContato,
        delAxios,
        getPicture,
        canAccess
    };

    const VERSION_FEATURE ='@ISSHOW@_V1'
    const getNewFeature = ()=>{
        const isShow = localStorage.getItem(VERSION_FEATURE)
        return false; //isShow === null;
    }
    const [showModal, setShowModal] = useState(true);
    const getWindowFeature = ()=>{
    return(
        <GabModal open={showModal} title={'Nova Funcionalidade'} labelRight={'Ok'} onClickRight={()=>{
            setShowModal(false)
            localStorage.setItem(VERSION_FEATURE, "true")
        }}>
            <Grid container>
                    <Grid item xs={12}>
                        <img src='/static/feature/feature.png'/>
                    </Grid>
                <GabLabel title={'Kanban'} subTitle={'Agora você pode mudar a forma de visualizar o kanban'} flip/>
            </Grid>
        </GabModal>
    )
    }

    return (
        <AppProviderContext.Provider value={initValue}>
            <ThemeProvider>
                <ScrollToTop/>
                <Router/>
                {
                    getNewFeature()? getWindowFeature() : <></>
                }
                {isLoading && (
                    <div>
                        <div className="loading-screen">
                            <div className="Spinner SpinnerDots">
                                <div className="spinner-dot"/>
                                <div className="spinner-dot"/>
                                <div className="spinner-dot"/>
                            </div>
                        </div>
                    </div>
                )}
            </ThemeProvider>
        </AppProviderContext.Provider>
    );
};

export default App;
