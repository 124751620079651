import React from "react";
import {Menu} from '@mui/material';

interface propTypes {
    children?: React.ReactNode;
    anchorEl:any;
    handleClose: () => void;
    id?:string;
};

const GABMenu: React.FC<propTypes> = (props) => {
    const open = Boolean(props.anchorEl);
    return (
        <Menu
            id={props.id}
            open={open}
            anchorEl={props.anchorEl}
            onClose={props.handleClose}
            anchorOrigin={{
                vertical: 'center',
                horizontal: 'center',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }}
            MenuListProps={{
                'aria-labelledby': 'basic-button',
            }}
         >
            {props.children}
        </Menu>

    );
}

export default GABMenu;
