import React from "react";
import {
  Typography,
  Grid,
} from "@mui/material";
import useResponsive from "../../hooks/useResponsive";
import "./index.css";
import { useNavigate } from "react-router-dom";
import GABIcon from "../gab-icon";
import {useScroll} from "../../hooks/useScroll";

type Props = {
  label: string;
  icon?: string;
  href?: string;
  disabled?: boolean;
  size?: number;
  image?: string;
  onClick?: () => void;
  iconColor?:string;
};

const GABListItem: React.FC<Props> = (props) => {
  const isDesktop = useResponsive("up", "lg");
  const navigator = useNavigate();
  const {scrollToTop} = useScroll();
  return (
    <Grid item xs={isDesktop ? props?.size || 2 : 6} style={{}}>
      <div
        className={`${props.disabled? 'menu-bt-disabled' : 'menu-bt'}`}
        onClick={() => {

          if (props?.href && props.disabled !== true) {
            navigator(props?.href);
          }
          if (props?.onClick && props.disabled !== true) {
            props?.onClick();
          }
            scrollToTop();
        }}
      >
        <div className={`${props.disabled? 'menu-bt-item-disabled' : 'menu-bt-item'}`}>
          {props?.image && !props?.icon && (
            <div
              style={{
                paddingBottom: "1rem",
              }}
            >
              <img src={props.image} width={"90%"} />
            </div>
          )}
          {props?.icon && !props?.image && (
            <GABIcon color={props.iconColor} icon={props.icon} />
          )}
        </div>
        <Typography className="menu-bt-text" variant="body2">
          {props.label}
        </Typography>
      </div>
    </Grid>
  );
};
export default GABListItem;
