import {alpha, styled, useTheme} from "@mui/material/styles";
import {
    Box,
    Stack,
    AppBar,
    Toolbar,
    IconButton, Badge,
} from "@mui/material";
import AccountPopover from "./AccountPopover";
import React, {MouseEventHandler, useContext, useEffect, useState} from "react";
import useResponsive from "../../hooks/useResponsive";
import palette from "../../theme/palette";
import GABIcon from "../../components/gab-icon";
import theme from "tailwindcss/defaultTheme";
import AppProviderContext, {propsAppProviderContext} from "../../app-provider";

const DRAWER_WIDTH = 280;
const APPBAR_MOBILE = 64;
const APPBAR_DESKTOP = 92;

const RootStyleOpen = styled(AppBar)(({ theme }) => ({
  boxShadow: "none",
  backdropFilter: "blur(6px)",
  WebkitBackdropFilter: "blur(6px)", // Fix on Mobile
  backgroundColor: alpha(theme.palette.primary.dark, 0.85),
  [theme.breakpoints.up("lg")]: {
    width: `calc(100% - 0px)`,
  },
}));

const RootStyle = styled(AppBar)(({ theme }) => ({
  boxShadow: "none",
  backdropFilter: "blur(6px)",
  WebkitBackdropFilter: "blur(6px)",
  backgroundColor: alpha(theme.palette.primary.dark, 0.85),
  [theme.breakpoints.up("lg")]: {
    width: `calc(100% - ${DRAWER_WIDTH + 1}px)`,
  },
}));

const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
  minHeight: APPBAR_MOBILE,
  [theme.breakpoints.up("lg")]: {
    minHeight: APPBAR_DESKTOP,
    padding: theme.spacing(0, 5),
  },
}));

interface propsDashboardNavbar {
  onClick?: MouseEventHandler | undefined;
  sx?: any;
  isOpen: boolean;
}

const DashboardNavbar: React.FC<propsDashboardNavbar> = (props) => {
  const isDesktop = useResponsive("up", "lg");
  const theme = useTheme();
    const {
        getAxios,
        canAccess
    }: propsAppProviderContext = useContext(AppProviderContext);

    const [alertas, setAlertas] = useState(0);
    const getStatus = async()=>{
        const resp = canAccess([1000]) ? await getAxios(`/parlamentar/chamadoGeral/alerta`) : await getAxios(`/parlamentar/chamadoUser/alerta`);
        setAlertas(resp.data);
    }

    useEffect(()=>{
        getStatus();
        setInterval(
            async ()=>{
                getStatus();
            }, 60000
        )
    },[])

  const barItens = () => {
    return (
      <ToolbarStyle>
        <IconButton
          aria-label="delete"
          onClick={props.onClick}
          style={{
            color: palette.primary.lighter,
          }}
        >

            <GABIcon
            icon={
              props.isOpen
                ? "mdi:menu-close"
                : "material-symbols:menu-open"
            }
          />
        </IconButton>

        <div
          style={{
            display: "flex",
          }}
        >
        </div>
        <Box sx={{ flexGrow: 1 }} />
        <Stack
          direction="row"
          alignItems="center"
          spacing={{ xs: 0.5, sm: 1.5 }}
        >
            <Badge badgeContent={alertas || 0} color="success">
                    <GABIcon icon={alertas >0? 'fluent:alert-on-20-filled' : 'fluent:alert-32-filled'} height={'1.5rem'} width={'1.5rem'} color={alertas >0?  theme.palette.secondary.main : theme.palette.grey[600]}/>
            </Badge>

          <AccountPopover />
        </Stack>
      </ToolbarStyle>
    );
  };
  if (props.isOpen && isDesktop) {
    return <RootStyleOpen>{barItens()}</RootStyleOpen>;
  }
  return <RootStyle>{barItens()}</RootStyle>;
};

export default DashboardNavbar;
