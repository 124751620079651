import React, { CSSProperties, ReactElement } from "react";
import {
  Avatar,
  Card,
  CardContent,
  CardHeader,
  Grid,
  Tooltip,
} from "@mui/material";
import { grey } from "@mui/material/colors";
import useResponsive from "../../hooks/useResponsive";
import { useTheme } from "@mui/material/styles";
import GABIcon from "../gab-icon";

interface propsDashboardCard {
  title: string;
  subtile?: string;
  children?: React.ReactNode;
  icon?: string;
  bgColor?: string;
  style?: CSSProperties | undefined;
  action?: ReactElement | undefined;
  tip?: string;
  size?: number;
}
const GABDashboardCard: React.FC<propsDashboardCard> = (props) => {
  const isDesktop = useResponsive("up", "lg");
  const theme = useTheme();

  return (
    <Grid
      item
      xs={isDesktop? props?.size || 12 : 6}
      style={{
        width: "100%",
          padding : '0.2rem'
      }}
    >
      <Tooltip title={props?.tip}>
        <Card style={{ backgroundColor: props.bgColor || "#FFF" }}>
          <CardHeader
            avatar={
              props.icon ? (
                <GABIcon width={40} height={40} icon={props.icon} />
              ) : undefined
            }
            action={props.action}
            title={props.title}
            subheader={
              <div
                style={{
                  textOverflow: "ellipsis",
                  width: "100%",
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                }}
              >
                <small>{props.subtile}</small>
              </div>
            }
            style={{ padding: "12px 12px 0", display: props.icon ? "flex" : "block" }}
          />
          <CardContent style={{ padding: "0 12px 12px 12px" }}>
            {props.children ? (
              <Grid container rowSpacing={1} spacing={1}>
                {props.children}
              </Grid>
            ) : null}
          </CardContent>
        </Card>
      </Tooltip>
    </Grid>
  );
};

export default GABDashboardCard;
