import React from "react";
import Page from "../../components/Page";
import { Fade } from "@mui/material";
import GridCardMenu from "../../components/gab-grid-menu";
import GABListItem from "../../components/gab-list-item";

const ToolsMenu: React.FC = () => {
  return (
    <Fade in timeout={1000}>
      <div>
        <Page title="Administração">
          <GridCardMenu>
            <GABListItem
              label={"Exportar Contatos"}
              icon={"ri:file-user-line"}
              href={`/admin/tools/exportContacts`}
            />
            <GABListItem
              label={"Exportar Demandas"}
              icon={"material-symbols:export-notes-outline"}
              href={`/admin/tools/exportDemands`}
            />
            <GABListItem
              label={"Listar Propostas"}
              icon={"octicon:law-16"}
              href={`/admin/propostas`}
            />
          </GridCardMenu>
        </Page>
      </div>
    </Fade>
  );
};

export default ToolsMenu;
