import {styled} from "@mui/material/styles";
import {
    Button,
    Card,
    CardActions,
    CardContent,
    CardHeader,
    Divider,
    Fade, Grid,
} from "@mui/material";
import Page from "../components/Page";
import React, {useContext, useEffect, useState} from "react";
import "./login.css";
import {useLocation, useNavigate} from "react-router-dom";
import AppProviderContext, {propsAppProviderContext} from "../app-provider";
import Logo from "../components/Logo";
import {useFormik} from "formik";
import * as Yup from "yup";
import {APP_TOKEN, APP_USUARIO} from "../App";
import GabInput from "../components/gab-input";
import brasilia from "./images/pexels-flavia-sandriany-13618459.jpg"
import GABDataTable from "../components/gab-data-table";
import GABModal from "../components/gab-modal";

const ContentStyle = styled("div")(({theme}) => ({
    maxWidth: 480,
    margin: "auto",
    minHeight: "100vh",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    padding: theme.spacing(12, 0),
}));

export default function RemoverConta() {
    const [isDisabledForm, setIsDisabledForm] = useState(false);
    const [showModal, setShowModal] = useState(false)
    const navigate = useNavigate();
    const {
        postAxios,
        addMessage,
        setLoading,
    }: propsAppProviderContext = useContext(AppProviderContext);

    const location = useLocation();
    const init = async () => {
        const q = new URLSearchParams(location.search);
        let token = q.get('t');
        await postAxios(`parlamentar/removerConta/${token}`);
        setLoading(false);
    }

    useEffect(() => {
        setLoading(true);
        init();
    }, []);

    const renderLogin = () => {
        return (
            <ContentStyle>
                <Card>

                    <CardHeader
                        title="Remoção de conta"
                        subheader="Remoção total do registo no sistema"
                    />
                    <CardContent>
                        <div
                            style={{
                                padding: "0.5rem",
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "start",
                                alignItems: "end",
                                width: "100%",
                                height: "9rem",
                            }}
                        >
                            <center><h1>TODOS SEUS DADOS FORAM REMOVIDOS</h1></center>
                        </div>
                    </CardContent>
                    <Divider/>
                </Card>
                <div
                    style={{
                        textAlign: "center",
                        padding: "1rem",
                    }}
                >

                </div>

                <div
                    style={{
                        display: "flex",
                        justifyContent: "center"
                    }}
                >

                    <Logo
                        fullWhite={true}
                        sx={{
                            width: "40%",
                        }}
                    />
                </div>

            </ContentStyle>
        );
    };

    return (
        <Fade in timeout={1000}>
            <div>
                <Page title="Remover Cadastro">
                    <div
                        style={{
                            background: "linear-gradient(#DFE3E8, #637381)",
                            backgroundImage: `url(${brasilia})`,
                            backgroundSize: "cover",
                        }}
                    >
                        <div
                            title={"Flávia Sandriany"}
                            style={{
                                backgroundColor: 'rgba(0,0,0,0.6)',
                                padding: '1rem'
                            }}>
                                 <form onSubmit={()=>{}}>
                                {renderLogin()}
                            </form>
                            <div style={{
                                position: "absolute",
                                bottom: '0',
                                height : '2rem',
                                left: '5%',
                                width: '90%',
                                textAlign: 'center',
                                fontSize: '0.7rem',
                            }}>
                                <a
                                    style={{
                                        color: 'rgba(255,255,255,0.52)'
                                    }}
                                    href={'https://www.pexels.com/pt-br/foto/ponte-em-arco-ponte-arqueada-lago-lagoa-13618459'}
                                    target={'_blank'}>Foto por Fávia Sandriany</a>
                            </div>
                        </div>
                    </div>

                </Page>
            </div>
        </Fade>
    );
}
