import React, {useState} from "react";
import {Grid, TextField} from "@mui/material";
import useResponsive from "../../hooks/useResponsive";
import { IMaskInput } from 'react-imask';
import {InputProps as StandardInputProps} from "@mui/material/Input/Input";
import {FormikFormProps, FormikProps} from "formik";
import {DatePicker, DateTimePicker, LocalizationProvider} from "@mui/x-date-pickers";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import ptBR from "date-fns/locale/pt-BR";
import {format, parse} from "date-fns";
import {useTheme} from "@mui/material/styles";

export interface propsCardFormInput {
    label:string;
    id:string;
    size?:number;
    required?:boolean;
    value?:any;
    onChange?:any;
    onBlur?:any;
    onKeyUp?:any;
    error?:boolean;
    helperText?:React.ReactNode;
    multiline?:boolean;
    select?:boolean;
    rows?:number;
    children?: React.ReactNode;
    mask?: 'phone' | 'cpf' | 'cep' | 'date' | 'cnpj' | 'cpfCnpj' ;
    disabled?:boolean;
    style?: React.CSSProperties;
    inputProps?: Partial<StandardInputProps>;
    type?: React.InputHTMLAttributes<unknown>['type'];
    datePick?:boolean;
    formik?:FormikProps<any>
    format?:string;
    dateTimePick?:boolean
}
interface CustomProps {
    onChange?:any;
    name: string;
    id?:string;
    value?:string;

}

const MaskDate = React.forwardRef<HTMLElement, CustomProps>(
    function TextMaskCustom(props, ref:any) {
        const { onChange, ...other } = props;
        return (
            <IMaskInput
                {...other}
                mask="00/00/0000"
                inputRef={ref}
                onChange={onChange}
            />
        );
    },
);


const MaskPhone = React.forwardRef<HTMLElement, CustomProps>(
    function TextMaskCustom(props, ref:any) {
        const { onChange, ...other } = props;
        return (
            <IMaskInput
                {...other}
                mask="(00) 0 0000-0000"
                inputRef={ref}
                onChange={onChange}
            />
        );
    },
);
const MaskCep = React.forwardRef<HTMLElement, CustomProps>(
    function TextMaskCustom(props, ref:any) {
        const { onChange, ...other } = props;
        return (
            <IMaskInput
                {...other}
                mask="00.000-000"
                inputRef={ref}
                onChange={onChange}
            />
        );
    },
);

const MaskCpf = React.forwardRef<HTMLElement, CustomProps>(
    function TextMaskCustom(props, ref:any) {
        const { onChange, ...other } = props;
        return (
            <IMaskInput
                {...other}
                mask="000.000.000-00"
                inputRef={ref}
                onChange={onChange}
            />
        );
    },
);

const MaskCnpj = React.forwardRef<HTMLElement, CustomProps>(
    function TextMaskCustom(props, ref:any) {
        const { onChange, ...other } = props;
        return (
            <IMaskInput
                {...other}
                mask="00.000.000/0000-00"
                inputRef={ref}
                onChange={onChange}
            />
        );
    },
);

const MaskCpfCnpj = React.forwardRef<HTMLElement, CustomProps>(
    
    function TextMaskCustom(props, ref:any) {
        const { onChange, ...other } = props;

        const maskBuilder = (v:any) => {
            if (!v || v.length === 0) return '';
            const a = v.replace(/\D/g, "");
            return (a.length >= 12) ? '00.000.000/0000-00' : '000.000.000-000';
        }

        return (
            <IMaskInput
                {...other}
                mask={maskBuilder(other.value)}
                inputRef={ref}
                onChange={onChange}
            />
        );
    },
);
const getMasked = (props:any) => {
    const { mask } = props;
    switch (mask) {
        case 'date':
            return  {inputComponent: MaskDate as any, ...props?.inputProps};
        case 'cep':
            return  {inputComponent: MaskCep as any, ...props?.inputProps};
        case 'phone':
            return  {inputComponent: MaskPhone as any, ...props?.inputProps};
        case 'cpf':
            return  {inputComponent: MaskCpf as any, ...props?.inputProps};
        case 'cnpj':
            return  {inputComponent: MaskCnpj as any, ...props?.inputProps};
        case 'cpfCnpj':
            return  {inputComponent: MaskCpfCnpj as any, ...props?.inputProps};
    }
}
const defaultFormat ='dd/MM/yyyy';
const GABInput:React.FC<propsCardFormInput> = (props)=> {
    const isDesktop = useResponsive('up', 'lg');
    const theme = useTheme();

    if (props.datePick) {
        return (
            <Grid item xs={isDesktop ? (props?.size || 12) : 12} style={{
                width: '100%'
            }}>
                <LocalizationProvider
                    adapterLocale={ptBR}
                    dateAdapter={AdapterDateFns}>
                    <DatePicker
                        slotProps={{ textField: { variant: 'outlined' } }}
                        format={props.format || defaultFormat}
                        sx={{
                            svg: { color: `${theme.palette.text.primary}`},
                            width: '100%',

                        }}
                        value={parse(props?.value || props.formik?.values[props.id],'dd/MM/yyyy', new Date())}
                        onChange={(value) => {
                            if(value) {
                                props.formik?.setFieldValue(props.id,  format(value,defaultFormat)) || props?.onChange(value)
                            }
                        }}
                        label={props.label}
                        disabled={props.disabled}

                    />
                </LocalizationProvider>
            </Grid>
        );
    }

    if (props.dateTimePick) {
        return (
            <Grid item xs={isDesktop ? (props?.size || 12) : 12} style={{
                width: '100%'
            }}>
                <LocalizationProvider
                    adapterLocale={ptBR}
                    dateAdapter={AdapterDateFns}>
                    <DateTimePicker
                        format={'dd/MM/yyyy HH:mm'}
                        sx={{
                            width: '100%'
                        }}
                        label={props.label}
                        disabled={props.disabled}
                        value={props?.value || props.formik?.values[props.id]}
                        onChange={(value) => {
                            if(value) {
                                props.formik?.setFieldValue(props.id,  format(value,defaultFormat))

                            }
                        }}
                    />
                </LocalizationProvider>
            </Grid>
        );
    }
    const getMessageHelper = () =>{
        let msgError = props?.helperText || '';
        if(props.formik?.touched[props.id] && props.formik?.errors[props.id]){
            msgError =props.formik?.errors[props.id] as string;
        }

        return msgError;
    }

    return(
        <Grid item xs={isDesktop? (props?.size || 12) :  12} style={{
            width : '100%'
        }}>

            <TextField
                style={props?.style}
                id={props.id}
                label={props.label}
                variant="outlined"
                disabled={props.disabled}
                select={props?.children? true: props?.select}
                fullWidth
                required={props?.required}
                multiline = {props?.rows? true: props?.multiline}
                rows={props?.rows}
                type={props?.type}
                value={props?.value || props.formik?.values[props.id] || ""}
                onChange={props?.onChange || props.formik?.handleChange}
                error={props?.error || (props.formik?.touched[props.id] && Boolean(props.formik?.errors[props.id || ""]))}
                onBlur={props?.onBlur || props.formik?.handleBlur}
                SelectProps={{
                     native: true,
                }}
                onKeyUp={props?.onKeyUp}

                helperText={getMessageHelper()}

                InputProps={props?.mask ? getMasked(props) : props?.inputProps}
            >
                {props.children}
            </TextField>
        </Grid>
    );
}

export default GABInput;


