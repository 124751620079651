export default class Contato {
  _id = null;
  id = null;
  nome = "";
  nomeSocial = "";
  cpfCnpj = "";
  nascimento = "";
  rg = "";
  im = "";
  titulo = "";
  estadoCivil = "";
  sexo = "";
  tratamento = "";
  zona = "";
  secao = "";
  seguimento = "";
  escolaridade = "";
  referencia = "";
  lideranca = "";
  tecnico = "";
  regiao = "";
  lotacao = "";
  identificador = "";
  transporte = "";
  mobilizacao = "";
  tipoPessoa = "PF";
  endereco = new Endereco();
  redesSociais = new RedesSociais();
  listaTelefone: Telefone[] = [];
  obs = "";
  grupos: Grupo[] = [];
  tags: any[] = [];
}

export class Grupo {
  label = "";
  value = "";
}

export class Telefone {
  fone = "";
  nome = "";
  obs = "";
  isWpp = false;

  constructor(fone: string, nome: string, obs: string, isWpp: boolean) {
    this.fone = fone;
    this.nome = nome;
    this.obs = obs;
    this.isWpp = isWpp;
  }

  static EmptyPhone() {
    return new Telefone("", "", "", false);
  }
}
export class RedesSociais {
  facebook = "";
  twitter = "";
  linkedin = "";
  telegram = "";
  instagram = "";
  email = "";
  skype = "";
  whatsApp = "";
}
export class Endereco {
  uf = "";
  cep = "";
  logradouro = "";
  numero = "";
  complemento = "";
  bairro = "";
  cidade = "";
}
