import * as React from 'react';
import {
    Droppable, DropResult,
    OnDragEndResponder, ResponderProvided
} from 'react-beautiful-dnd';
import {Kanban} from './typings';
import GABDraggableCard from "./gab-draggable-card";
import {Button, Grid, IconButton, Paper, Typography} from "@mui/material";

import  "./gab-draggable-container.css";
import {GridSize} from "@mui/material/Grid/Grid";
import GABDialog from "../../../components/gab-dialog";
import {useEffect, useState} from "react";
import {useFormik} from "formik";
import GabInput from "../../../components/gab-input";
import GABIcon from "../../../components/gab-icon";
import GABCanAccess from '../../../components/gab-canAccess';

export type GABDraggableContainerProps = {
    kanban: Kanban;
    onDragEnd: OnDragEndResponder;
    className?:any;
    xs?: boolean | GridSize;
    md?: boolean | GridSize;
    sm?: boolean | GridSize;
    onUpdate: (kanban: Kanban) => void;
    viewGrid:boolean;
    sizeGrid:any;
};

const GABDraggableContainer = React.memo(({ kanban, onDragEnd, onUpdate, className,xs, md, sm,viewGrid, sizeGrid }: GABDraggableContainerProps) => {
    const [dialogOpen, setDialogOpen ] = useState(false);
    const [dialogColorOpen, setDialogColorOpen ] = useState(false);
    const [title, setTitle] = useState(kanban?.titulo || '')
    const [cor, setCor] = useState(kanban?.cor || '')
    const [corTmp, setCorTmp] = useState<string>()

    const formik = useFormik({
        initialValues: {nome: title},
        onSubmit: async (values) => {
             updateKanban();
        },
    });

    const updateKanban = ()=>{
        let clone: any = Object.assign({}, kanban);
        clone.cor = corTmp || cor;
        clone.titulo = title;
        onUpdate(clone);
    }
    useEffect(()=>{
        setTitle(kanban?.titulo )
        setCor(kanban?.cor|| '' )
        formik.setValues({
            nome: kanban?.titulo
        })
    },[
        kanban
    ])

    const colors = ["#ffcdd2", "#f8bbd0", '#e1bee7', '#d1c4e9', '#c5cae9', '#bbdefb', '#b3e5fc', '#b2ebf2', '#b2dfdb', '#c8e6c9', '#dcedc8', '#f0f4c3', '#fff9c4', '#ffecb3', '#ffe0b2', '#ffccbc', '#d7ccc8', '#f5f5f5', '#eceff1','#ffffff']
    const getButtonColor = (color:string) =>{
       return  <div className={'panel-colors'}>
           {
               colors.map((cor) =>{
                   return <Button className={'btn-cor'} style={{backgroundColor: `${cor}`}}
                                  onMouseLeave={() => {
                                      setCorTmp(undefined);

                                  }} onMouseOver={() => {
                       setCorTmp(cor);
                   }} onClick={ async () => {
                       setCor(cor)
                       setDialogColorOpen(false)
                       await setTimeout(
                           ()=>{
                               updateKanban();
                           }, 500
                       );
                   }}/>
               })
           }

       </div>

    }

    const getHeight = ()=>{
        let size ='20rem';
        switch (sizeGrid) {
            case "Pequeno":
                size ='20rem';
                break;
            case "Médio":
                size ='40rem';
                break;
            case "Grande":
                size ='80rem';
                break;
        }

        return size;
    }

    return (
                <Grid item xs={viewGrid? 4 : 12}>
                    <Droppable droppableId={`${kanban.index}`} key={`${kanban.index}`}>
                        {provided => (
                            <Paper
                                style={{
                                    backgroundColor : `${corTmp? corTmp: cor}`,
                                    height :`${getHeight()}`,
                                    margin : '0.5rem'
                                }}
                                elevation={10} ref={provided.innerRef} {...provided.droppableProps}  className={className || "paper-card"}>
                                <div className={"paper-card-title"}   style={{
                                    backgroundColor : `${kanban?.cor}`
                                }}>
                                    <Typography className={"paper-card-title-label"}>
                                        {title}
                                    </Typography>
                                    <div>
                                        <GABCanAccess roles={[9]}><IconButton
                                            onClick={()=>{
                                                setDialogOpen(true);
                                            }}

                                        >
                                            <GABIcon
                                                icon={"akar-icons:edit"}
                                            />
                                        </IconButton>

                                            <IconButton
                                                onClick={()=>{
                                                    setDialogColorOpen(true);
                                                }}
                                            >
                                                <GABIcon
                                                    icon={"ic:twotone-format-color-fill"}
                                                />
                                            </IconButton></GABCanAccess>
                                    </div>
                                </div>

                                <div className={'paper-card-content'}>
                                    {kanban?.demanda?.map((item, index) => (
                                        <GABDraggableCard item={item} index={index} key={`${item._id}`} />
                                    ))}
                                </div>

                                <GABDialog
                                    title ={"Renomear"}
                                    description={``}
                                    open={dialogOpen}
                                    onClickLeft={()=>{
                                        setDialogOpen(false)
                                    }}
                                    labelLeft={"Cancelar"}
                                    onClickRight={()=>{
                                        setDialogOpen(false)
                                        setTitle(formik.values.nome)
                                        formik.submitForm();
                                    }}
                                    labelRight={"Salvar"}
                                >
                                    <div style={{
                                        paddingTop: '0.5rem',
                                    }}>
                                        <GabInput
                                            label={"Nome"}
                                            id={"nome"}
                                            value={formik.values.nome}
                                            onChange={formik.handleChange}
                                            error={
                                                formik.touched.nome &&
                                                Boolean(formik.errors.nome)
                                            }
                                            helperText={
                                                formik.touched.nome &&
                                                formik.errors.nome
                                            }
                                        />
                                    </div>
                                </GABDialog>

                                <GABDialog
                                    title ={"Mudar a cor"}
                                    description={``}
                                    open={dialogColorOpen}
                                    onClickLeft={()=>{
                                        setDialogColorOpen(false)
                                    }}
                                    labelLeft={"Cancelar"}
                                >
                                    {
                                        getButtonColor("#ffcdd2")
                                    }
                                </GABDialog>

                            </Paper>
                        )}
                    </Droppable>
                </Grid>
    );
});

export default GABDraggableContainer;
