import React from "react";
import GABCrud from "../component/gab-crud";
import * as Yup from "yup";
import { format } from "date-fns";
import { ptBR } from "date-fns/locale";

const DemandaLogs: React.FC = () => {
    const url ='parlamentar/demanda/logs'
    const validation =  Yup.object().shape({
        nome: Yup.string().trim().required("informe o nome"),
        descricao: Yup.string().trim().required("informe a descrição"),
    });
    return (
        <GABCrud
        idField={'_id'}
        validationSchema={validation}
        service={url}
        pageTitle={'Atividade'}
        icon={"material-symbols:order-approve-rounded"}
        title={"Atividade"}
        serviceName={'atividade'}
        subtile={`Gerenciar atividades nas demandas`}
        noAction={true}
        columns={
            [
                {
                    name: "protocolo",
                    label: "Protocolo",
                },
                {
                    name: "msg",
                    label: "Atividade",
                },
                {
                    name: "usuario",
                    label: "Usuário",
                },
                {
                    name: "dataHora",
                    label: "Data",
                    options: {
                        customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
                          return format(value, "dd/MM/yyyy EEEE HH:mm", {
                                locale: ptBR,
                              });
                        },
                      }
                },
                {
                    name: "kanban",
                    label: "Kanban",
                },
                {
                    name: "status",
                    label: "Status",
                    options: {
                        customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
                          return value?.label;
                        },
                      }
                }
            ]
        }
        />
    )
}

export default DemandaLogs;


