export type Board ={
    kb0:Kanban;
    kb1:Kanban;
    kb2:Kanban;
    kb3:Kanban;
    kb4:Kanban;
    kb5:Kanban;
    kb6:Kanban;
    kb7:Kanban;
    kb8:Kanban;
}
export type Kanban ={
    demandas:number;
    demanda?:Demanda[];
    ativo?: boolean;
    titulo:string;
    cor?:string;
    index:number;
}

export type Demanda = {
    categoria: Categoria;
    interna: any;
    eleitor: string;
    _id: string;
    protocolo: string;
    pessoa: any;
    titulo?:string;
    prioridade?:string;
    responsaveis? :Responsavel[];
    prazoData:string;
    categorias:Categoria[];
    caixa:Caixa[];
};

export type Usuarios ={
    _id?:string;
    nome?:string;
    email?:string;
}
export type UsuariosResponsavel ={
    value?:string;
    label?:string;
    email?:string;
}
export type Regiao ={
    _id?:string;
    nome:string;
}
export type Caixa ={
    _id?:string;
    nome:string;
}
export type Categoria ={
    _id?:string;
    nome:string;
}

export type Responsavel ={
    value:string;
    label:string;
    email:string;
}

export type DemandaResponse ={
    dataPrazo:string;
    protocolo:string;
}


export class Generic {
    value?:string;
    label?:string;
    constructor(value: string, label: string) {
        this.value = value;
        this.label = label;
    }
}

export class DemandaPesquisa {
    idPessoa = '';
    categoria ='';
    caixa ='';
    statusGeral ='';
    "statusGeral.value" ='';
    "responsaveis.value" = '';
    responsaveis = '';
    prazoData ='';
    protocolo ='';
    descricao ='';
    regiao ='';
    titulo ='';
    eleitor ='';
}
