import React, { CSSProperties, ReactElement } from "react";
import {Avatar, Button, ButtonGroup, Card, CardContent, CardHeader, Grid} from "@mui/material";
import {green, grey} from "@mui/material/colors";
import useResponsive from "../../hooks/useResponsive";
import {useTheme} from "@mui/material/styles";
import {FormikProps} from "formik";

interface propsCardForm {
    labelSubmit?:string;
    labelReset?:string;
    disableReset?:boolean;
    size?:number;
    formik:FormikProps<any>
}
const GABFormButtons: React.FC<propsCardForm> = (props) => {
    const isDesktop = useResponsive('up', 'lg');
    const theme = useTheme();

    return (

        <Grid
            style={{
                padding : '0.5rem'
            }}
            item container
              xs={isDesktop? 12 : props?.size || 12} alignItems={"end"} alignContent={"end"} justifyContent={"flex-end"}>

            <Grid item xs={6} style={{padding :'0.5rem'}}>
                <Button
                    fullWidth
                    id="brClear"
                    type="reset"
                    disabled={props.disableReset}
                    onClick={()=>{props.formik.resetForm()}}
                    variant="contained"
                    color={"secondary"}
                >
                    {props?.labelReset || 'Limpar'}
                </Button>
            </Grid>
            <Grid item xs={6} style={{padding :'0.5rem'}}>
                <Button
                    fullWidth
                    disabled={!props.formik.isValid}
                    id="brClear"
                    type="submit"
                    variant="contained"
                    color={"primary"}
                    autoFocus
                >
                    {props?.labelSubmit || 'Salvar'}
                </Button>
            </Grid>
        </Grid>
    );
};

export default GABFormButtons;
