import { differenceInYears, parse } from "date-fns";

export function isValidDate(str: string) {
  var m = str.match(/^(\d{1,2})\/(\d{1,2})\/(\d{4})$/);
  return m ? !isNaN(Date.parse(`${m[3]}-${m[2]}-${m[1]}`)) : false;
}

export const formatCpf = (cpf?: string) => {
  if (cpf) {
    const numericCPF = cpf.replace(/\D/g, "");
    const formattedCPF = numericCPF.replace(
      /(\d{3})(\d{3})(\d{3})(\d{2})/,
      "$1.$2.$3-$4"
    );
    return formattedCPF;
  }
  return cpf;
};

export const getAge = (nascimento?: any): number => {
  if (nascimento) {
    const date = parse(nascimento, "dd/MM/yyyy", new Date());
    return differenceInYears(new Date(), date);
  }
  return 0;
};
