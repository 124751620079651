import React, {
  DependencyList,
  EffectCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import Page from "../../../components/Page";
import {
  Button,
  Chip,
  Divider,
  Fade,
  Grid,
  IconButton,
  TableCell,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import useResponsive from "../../../hooks/useResponsive";
import GABForm from "../../../components/gab-form";
import GABInput from "../../../components/gab-input";
import { useFormik } from "formik";
import {
  Caixa,
  Categoria,
  Demanda,
  DemandaPesquisa,
  Generic,
  Regiao,
  Usuarios,
  UsuariosResponsavel,
} from "../component/typings";
import AppProviderContext, {
  propsAppProviderContext,
} from "../../../app-provider";
import GabInput from "../../../components/gab-input";
import { STATUS_GERAL } from "../../../utils/select-utils.domain";
import GABDataTable from "../../../components/gab-data-table";
import { alpha, useTheme } from "@mui/material/styles";
import GABLabel from "../../../components/gab-label";
import GABIcon from "../../../components/gab-icon";
import GABChipStatusDemanda from "./component/gab-chip-status-demanda";
import GABMenu from "../../../components/gab-menu";
import GABMenuItem from "../../../components/gab-menu/item";
import { useNavigate } from "react-router-dom";
import GABDialog from "../../../components/gab-dialog";
import DemandaDetail from "../detail";
import GABFormButtons from "../../../components/gab-form-buttons";
import GABButtonIcon from "../../../components/gab-button-icon";
import { differenceInCalendarDays } from "date-fns";

const useEffectAfterMount = (
  cb: EffectCallback,
  dependencies?: DependencyList
) => {
  const mounted = useRef(true);

  useEffect(() => {
    if (!mounted.current) {
      return cb();
    }
    mounted.current = false;
  }, dependencies);
};

const DemandaList: React.FC = () => {
  const isDesktop = useResponsive("up", "lg");
  const [equipes, setEquipes] = useState<Generic[]>([]);
  const [categoria, setCategoria] = useState<Categoria[]>([]);
  const [regiao, setRegiao] = useState<Generic[]>([]);
  const [demandas, setDemandas] = useState<Demanda[]>([]);
  const [usuarios, setUsuarios] = useState<UsuariosResponsavel[]>([]);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogDetailOpen, setDialogDetailOpen] = useState(false);
  const [demandasSelected, setDemandasSelected] = useState("");
  const [editable, setEditable] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(parseInt(localStorage.getItem("DEMANDAS_PAGE") || "0"));
  const [count, setCount] = useState(0);
  const [filtros, setFiltros] = useState({});

  const {
    getAxios,
    postAxios,
    delAxios,
    setLoading,
    profile,
    canAccess,
  }: propsAppProviderContext = useContext(AppProviderContext);

  const diacriticSensitiveRegex = (string: string) => {
    return string
      .replace(/(A|a|á|à|ä|â)/g, "(a|á|à|ä|â|ã)")
      .replace(/(E|e|é|ë|è)/g, "(e|é|ë|è)")
      .replace(/(I|i|í|ï|ì)/g, "(i|í|ï|ì)")
      .replace(/(O|o|ó|ö|ò)/g, "(o|ó|ö|ò|õ)")
      .replace(/(U|u|ü|ú|ù)/g, "(u|ü|ú|ù)")
      .replace(/\s/g, "(\\s*)");
  };

  const formik = useFormik({
    initialValues: {} as DemandaPesquisa,
    onReset: async (values) => {
      localStorage.setItem("DEMANDAS_FILTER", "{}");
      localStorage.setItem("DEMANDAS_PAGE", "0");
    },
    onSubmit: async (values) => {
      let sender = {
        "statusGeral.value": values.statusGeral || "",
        categoria: values.categoria || "",
        caixa: values.caixa || "",
        "responsaveis.value": values.responsaveis || "",
        prazoData: values.prazoData || "",
        protocolo: values.protocolo || "",
        descricao: values.descricao || "",
        regiao: values.regiao || "",
        eleitor: values.eleitor || "",
        titulo: values.titulo || "",
        idPessoa: values.idPessoa || ""
      };

      sender.eleitor = diacriticSensitiveRegex(sender.eleitor);
      sender.titulo = diacriticSensitiveRegex(sender.titulo);

      localStorage.setItem("DEMANDAS_FILTER", JSON.stringify(values));
      localStorage.setItem("DEMANDAS_PAGE", "0");

      setFiltros(sender);
      buscar(page, rowsPerPage, sender);
    },
  });

  const updateEquipe = async () => {
    const request = await getAxios("parlamentar/demanda/caixa");
    const equipesReq = request.data.caixa.map(
      (c: Caixa) => new Generic(c?._id || "", c?.nome || "")
    );
    setEquipes(equipesReq);
  };

  const updateRegiao = async () => {
    const request = await getAxios("parlamentar/regiao");
    const regiaoReq = request.data.regiao.map(
      (c: Regiao) => new Generic(c?._id || "", c?.nome || "")
    );
    setRegiao(regiaoReq);
  };

  const updateCategoria = async () => {
    const request = await getAxios("parlamentar/demanda/categoria");
    setCategoria(request.data.categoria);
  };

  const updateUsuario = async () => {
    const request = await getAxios("auth/usuario");
    const usuaiosRequested: Usuarios[] = request.data;
    let tmp: UsuariosResponsavel[] = [];
    usuaiosRequested.forEach((u: Usuarios) => {
      tmp.push({
        value: u._id,
        label: u.nome,
        email: u.email,
      });
    });
    setUsuarios(tmp);
  };

  const init = async () => {
    setLoading(true);

    let dados =  JSON.parse(localStorage.getItem("DEMANDAS_FILTER") || "{}");

    await formik.setValues(dados);

    await updateEquipe();
    await updateCategoria();
    await updateRegiao();
    await updateUsuario();

    formik.submitForm();

    setLoading(false);
  };

  useEffect(() => {
    init();
  }, []);

  const buscar = async (page: number, rowsPerPage: number, dados?: any) => {
    if (!dados) {
      dados = filtros;
    }

    setLoading(true);

    const resp = await postAxios(
      `parlamentar/demanda_lista/${page}/${rowsPerPage}`,
      dados
    );

    setDemandas(resp.data.lista);
    setCount(resp.data.total);
    setTimeout(()=>{
      setLoading(false);
    }, 500);
    
  };

  useEffectAfterMount(() => {
    buscar(page, rowsPerPage);
    localStorage.setItem("DEMANDAS_PAGE", page.toString());
  }, [rowsPerPage, page]);

  const theme = useTheme();

  const [anchorEl, setAnchorEl] = React.useState(null);

  const [indexSelected, setIndexSelected] = useState(-1);
  const closeMenu = () => {
    setIndexSelected(-1);
    setAnchorEl(null);
  };

  const isResponsavel = (rowIndex: number) => {
    let lista = demandas[rowIndex].responsaveis
      ? demandas[rowIndex].responsaveis
      : [];
    if (lista) {
      return lista.findIndex((e) => e.value === profile._id) >= 0;
    }
    return false;
  };

  const handleClick = (event: any, rowIndex: number) => {
    setEditable(canAccess([9]) || isResponsavel(rowIndex));
    setAnchorEl(event.currentTarget);
    setIndexSelected(rowIndex);
  };

  const navigator = useNavigate();

  const edit = () => {
    const id = demandas[indexSelected]._id;
    navigator(`/admin/demanda/edit/${id}`);
  };
  const detail = () => {
    const id = demandas[indexSelected]._id;
    setDemandasSelected(id);
    setDialogDetailOpen(true);
    closeMenu();
  };
  const removeConfirme = async () => {
    setLoading(true);
    closeMenu();
    setDialogOpen(false);
    const id = demandas[indexSelected]._id;
    await delAxios(`parlamentar/${id}`);
    await init();
    await formik.submitForm();
    setLoading(false);
  };

  const customRenderTarefas = (data: any, dataIndex: any, rowIndex: any) => {
    let styleLocal: React.CSSProperties = {};
    if (rowIndex % 2 === 0) {
      styleLocal.backgroundColor = alpha(theme.palette.primary.light, 0.2);
    }

    const diasPraso = (data: any) => {
      if (data && data !== "") {
        let hoje = new Date();
        let praso = new Date(
          data.substr(6, 4),
          parseInt(data.substr(3, 2)) - 1,
          data.substr(0, 2)
        );
        return differenceInCalendarDays(hoje, praso);
      }
      return 0;
    };

    return (
      <TableRow style={styleLocal}>
        <TableCell align={"left"} valign={"top"} width={30}>
          <div style={{ display: "flex" }}>
            <GABLabel
              flip={true}
              title={
                demandas[rowIndex].interna === false
                  ? " Demanda Externa"
                  : "Demanda Interna"
              }
              subTitle={data[0]?.split("-")[1]}
              subTitleCopy
            />
          </div>
        </TableCell>
        <TableCell align={"left"} valign={"top"} width={400}>
          <GABLabel
            titleVariant={"body1"}
            title={
              demandas[rowIndex].titulo && demandas[rowIndex].titulo !== ""
                ? (demandas[rowIndex].titulo || "") + (demandas[rowIndex].eleitor ? " / " + demandas[rowIndex].eleitor : "")
                : demandas[rowIndex].eleitor || ""
            }
          />
        </TableCell>
        <TableCell align={"left"} valign={"top"}>
          <GABLabel
            titleVariant={"body1"}
            title={data[2]}
            titleBadge={
              ["3", "4"].includes(data[3]?.value) ? undefined : diasPraso(data[2]) > 0
                ? diasPraso(data[2])
                : diasPraso(data[2]) * -1
            }
            titleTips={
              ["3", "4"].includes(data[3]?.value) ? undefined : `${diasPraso(data[2])} dias ${diasPraso(data[2]) > 0 ? "de atraso" : "para o prazo"}`
            }
            colorBadge={diasPraso(data[2]) > 0 ? "error" : "success"}
          />
        </TableCell>
        <TableCell align={"left"} valign={"top"}>
          <GABChipStatusDemanda
            status={data[3]?.label}
            label={data[3]?.label}
          />
        </TableCell>

        <TableCell align={"left"} valign={"top"}>
          {data[4] ? (
            <GABLabel
              title={data[4][0]?.label || ""}
              titleBadge={
                data[4]?.length > 1 ? `+${data[4]?.length - 1}` : undefined
              }
              titleTips={`${data[4]?.map(
                (i: any) => `${i?.label.toLowerCase()} `
              )}`}
              subTitle={data[4][0]?.email}
              flip={true}
            />
          ) : (
            <></>
          )}
        </TableCell>

        <TableCell width={30}>
          <IconButton
            onClick={(event: any) => {
              handleClick(event, dataIndex);
            }}
          >
            <GABIcon icon={"iconamoon:menu-kebab-vertical-bold"} />
          </IconButton>
        </TableCell>
      </TableRow>
    );
  };

  const options = {
    search: false,
    print: false,
    download: false,
    filter: false,
    selectableRows: "none",
    viewColumns: false,
    responsive: "vertical",
    serverSide: true,
    count: count,
    page: page,
    onChangePage: (page: number) => {
      setPage(page);
    },
    onChangeRowsPerPage: (rows: number) => {
      setRowsPerPage(rows);
    },
    customRowRender: customRenderTarefas,
  } as any;

  return (
    <Fade in timeout={1000}>
      <div>
        <Page title="Listar Demanda">
          <form onSubmit={formik.handleSubmit}>
            <GABForm
              icon={"fluent:text-bullet-list-square-warning-24-regular"}
              title={"Demandas"}
              subtile={`Utilize o filtro para localizar sua demanda`}
            >
              <GabInput
                label={"Título"}
                id={"titulo"}
                formik={formik}
                size={4}
              />

              <GabInput
                label={"Solicitante"}
                id={"eleitor"}
                formik={formik}
                size={4}
              />
              <GabInput
                label={"Responsável"}
                id={"responsaveis"}
                size={4}
                formik={formik}
              >
                {<option key={""} value={""}></option>}
                {usuarios.map((option) => (
                  <option key={option?.value} value={option?.value}>
                    {option?.label}
                  </option>
                ))}
              </GabInput>
              <GabInput
                label={"Descrição"}
                id={"descricao"}
                formik={formik}
                size={4}
              />

              <GabInput label={"Equipe"} id={"caixa"} formik={formik} size={4}>
                {<option key={""} value={""}></option>}
                {equipes.map((option) => (
                  <option key={option?.value} value={option?.value}>
                    {option?.label}
                  </option>
                ))}
              </GabInput>

              <GabInput
                label={"Categoria"}
                id={"categoria"}
                formik={formik}
                size={4}
              >
                {<option key={""} value={""}></option>}
                {categoria.map((option) => (
                  <option key={option?._id} value={option?._id}>
                    {option?.nome}
                  </option>
                ))}
              </GabInput>

              <GabInput label={"Regiao"} id={"regiao"} formik={formik} size={3}>
                {<option key={""} value={""}></option>}
                {regiao.map((option) => (
                  <option key={option?.value} value={option?.value}>
                    {option?.label}
                  </option>
                ))}
              </GabInput>

              <GabInput
                label={"Status Geral"}
                id={"statusGeral"}
                formik={formik}
                size={3}
              >
                {STATUS_GERAL.map((option) => (
                  <option key={option?.value} value={option?.value}>
                    {option?.label}
                  </option>
                ))}
              </GabInput>

              <GabInput
                label={"Data prazo"}
                id={"prazoData"}
                size={3}
                value={formik.values.prazoData}
                mask={"date"}
                onChange={formik.handleChange}
                error={
                  formik.touched.prazoData && Boolean(formik.errors.prazoData)
                }
                helperText={formik.touched.prazoData && formik.errors.prazoData}
              />
              <GabInput
                label={"Protocolo"}
                id={"protocolo"}
                formik={formik}
                size={3}
              />

              <Grid item xs={8}></Grid>

              <GABFormButtons
                labelSubmit={"Pesquisar"}
                size={4}
                formik={formik}
              />
            </GABForm>
          </form>
          <div
            style={{
              paddingTop: "2rem",
            }}
          >
            <GABDataTable
              data={demandas}
              title={"Demandas"}
              columns={[
                {
                  name: "protocolo",
                  label: "Protocolo",
                },
                {
                  name: "titulo",
                  label: "Título/Solicitante",
                },
                {
                  name: "prazoData",
                  label: "Prazo",
                },
                {
                  name: "statusGeral",
                  label: "Status",
                },
                {
                  name: "responsaveis",
                  label: "Responsável",
                },
                {
                  name: "acao",
                  label: "Ação",
                },
              ]}
              options={options}
            />
          </div>
        </Page>

        <GABMenu id="basic-menu" anchorEl={anchorEl} handleClose={closeMenu}>
          <GABMenuItem
            onClick={detail}
            label={"Detalhe"}
            icon={"icon-park-outline:view-grid-detail"}
            tooltip={"Detahe Demanda"}
          />
          {editable ? (
            <>
              <GABMenuItem
                link={`/admin/demanda/edit/${demandas[indexSelected]?._id}`}
                onClick={edit}
                label={"Editar"}
                icon={"akar-icons:pencil"}
                tooltip={"Editar Demanda"}
              />
              <Divider />
              <GABMenuItem
                onClick={() => {
                  setDialogOpen(true);
                }}
                label={"Excluir"}
                icon={"akar-icons:trash-can"}
                tooltip={"Excluir Demanda"}
              />
            </>
          ) : null}
        </GABMenu>

        <GABDialog
          title={"Excluir?"}
          description={`Deseja excluir a demanda : ${
            demandas[indexSelected]?.protocolo || ""
          }?`}
          open={dialogOpen}
          onClickLeft={() => {
            setDialogOpen(false);
            closeMenu();
          }}
          labelLeft={"Não"}
          onClickRight={removeConfirme}
          labelRight={"Sim"}
        />
        <DemandaDetail
          idDemanda={demandasSelected}
          isOpen={dialogDetailOpen}
          onClose={async () => {
            setDialogDetailOpen(false);
            setDemandas([]);
            setDemandasSelected("");
            setTimeout(async () => {
              await formik.submitForm();
            }, 500);
          }}
        />
      </div>
    </Fade>
  );
};

export default DemandaList;
