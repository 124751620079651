import {styled} from "@mui/material/styles";
import {
    Button,
    Card,
    CardActions,
    CardContent,
    CardHeader,
    Divider,
    Fade, Grid,
} from "@mui/material";
import Page from "../components/Page";
import React, {useContext, useEffect, useState} from "react";
import "./login.css";
import {useLocation, useNavigate} from "react-router-dom";
import AppProviderContext, {propsAppProviderContext} from "../app-provider";
import Logo from "../components/Logo";
import {useFormik} from "formik";
import * as Yup from "yup";
import {APP_TOKEN, APP_USUARIO} from "../App";
import GabInput from "../components/gab-input";
import brasilia from "./images/pexels-flavia-sandriany-13618459.jpg"
import GABDataTable from "../components/gab-data-table";
import GABModal from "../components/gab-modal";

const ContentStyle = styled("div")(({theme}) => ({
    maxWidth: 480,
    margin: "auto",
    minHeight: "100vh",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    padding: theme.spacing(12, 0),
}));

export default function TrocarSenha() {
    const [isDisabledForm, setIsDisabledForm] = useState(false);
    const [showModal, setShowModal] = useState(false)
    const navigate = useNavigate();
    const {
        putAxios,
        addMessage,
        setLoading,
    }: propsAppProviderContext = useContext(AppProviderContext);


    const formik = useFormik({
        initialValues: {novaSenha1: "", novaSenha2: "", token :""},
        onSubmit: async (values) => {
            if(values.novaSenha1.length<3  ||  values.novaSenha1 !== values.novaSenha1){
                addMessage('As senhas não são iguais, ou são menores que 3 caracter', 'error', 2000);
                return;
            }

            setIsDisabledForm(true);
            setLoading(true);
            try {
                const result = await putAxios('auth/usuario/atualizarsenhaToken',values);
                addMessage(`Sucesso, sua senha foi trocada`, 'success', 2000);
                await setTimeout(
                    () => {
                        navigate("/login")
                    }, 1500
                );
            } catch (e) {
                setLoading(false);
                setIsDisabledForm(false);
                addMessage('Um erro ocorreu', 'error');
            }

        },
    });
    const location = useLocation();
    const init =()=>{
        const q = new URLSearchParams(location.search);
        formik.setFieldValue('token', q.get('t'))
    }

    useEffect(() => {
        setLoading(false);
        init();
    }, []);

    const renderLogin = () => {
        return (
            <ContentStyle>
                <Card>

                    <CardHeader
                        title="Olá, aqui você pode trocar sua senha"
                        subheader="informe sua senha, utilize senha forte"
                    />
                    <CardContent>
                        <div
                            style={{
                                padding: "0.5rem",
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "start",
                                alignItems: "end",
                                width: "100%",
                                height: "9rem",
                            }}
                        >
                            <GabInput
                                label={"Nova Senha"}
                                id={"novaSenha1"}
                                type={"password"}
                                formik={formik}
                                size={12}
                                required={true}
                            />

                            <GabInput
                                label={"Confirme Nova Senha"}
                                id={"novaSenha2"}
                                type={"password"}
                                formik={formik}
                                size={12}
                                required={true}
                            />
                        </div>
                    </CardContent>
                    <Divider/>

                    <CardActions>
                        <div
                            style={{
                                width: "100%",
                                display: "flex",
                                justifyContent: "center",
                            }}
                        >
                            <Button
                                disabled={isDisabledForm}
                                variant="contained" type={"submit"}>
                                Enviar
                            </Button>
                        </div>
                    </CardActions>
                </Card>
                <div
                    style={{
                        textAlign: "center",
                        padding: "1rem",
                    }}
                >

                </div>

                <div
                    style={{
                        display: "flex",
                        justifyContent: "center"
                    }}
                >

                    <Logo
                        fullWhite={true}
                        sx={{
                            width: "40%",
                        }}
                    />
                </div>

            </ContentStyle>
        );
    };

    return (
        <Fade in timeout={1000}>
            <div>
                <Page title="Login">
                    <div
                        style={{
                            background: "linear-gradient(#DFE3E8, #637381)",
                            backgroundImage: `url(${brasilia})`,
                            backgroundSize: "cover",
                        }}
                    >
                        <div
                            title={"Flávia Sandriany"}
                            style={{
                                backgroundColor: 'rgba(0,0,0,0.6)',
                                padding: '1rem'
                            }}>
                            <form onSubmit={formik.handleSubmit}>
                                {renderLogin()}
                            </form>


                            <div style={{
                                position: "absolute",
                                bottom: '0',
                                height : '2rem',
                                left: '5%',
                                width: '90%',
                                textAlign: 'center',
                                fontSize: '0.7rem',
                            }}>
                                <a
                                    style={{
                                        color: 'rgba(255,255,255,0.52)'
                                    }}
                                    href={'https://www.pexels.com/pt-br/foto/ponte-em-arco-ponte-arqueada-lago-lagoa-13618459'}
                                    target={'_blank'}>Foto por Fávia Sandriany</a>
                            </div>
                        </div>
                    </div>

                </Page>
            </div>
        </Fade>
    );
}
