import * as Yup from "yup";
import {Categoria} from "../component/typings";

export interface Demanda {
  _id: any
  id: any
  categoria: string
  categorias:Categoria[];
  prazo: number
  protocolo: string
  prazoData: string
  descricao: string
  caixa: Caixa[]
  tarefas: Tarefa[]
  responsaveis: Responsavel[]
  anexos: Anexo[]
  statusGeral: StatusGeral
  diasAviso: number
  atividades: Atividade[]
  ativa: number
  prioridade: string
  interna: boolean
  dataFinalizada: string
  regiao: Regiao[]
  idPessoa: string
  eleitor: string
  cpfCnpj: string
  pessoa?:any;
  status:any;
  titulo?:any;

}

export class DemandaUtils {
  static createNewDemanda = (id?:string):Demanda=>{
    return {
    id : null,
    idPessoa : id || null,
    protocolo: '',
    ativa: 1,
    } as Demanda;
  }

  static validationSchema = Yup.object().shape({

  });
}


export interface Caixa {
  value: string
  label: string
}

export interface Tarefa {
  responsavel: Responsavel
  prazo: string
  descricao: string
  status?:any;
}

export interface Responsavel {
  value: string
  label: string
  email: string
}

export interface Anexo {
  usuario: string
  nome: string
  caminho: string
}

export interface StatusGeral {
  value: string
  label: string
}

export interface Atividade {
  responsavel_nome: string
  responsavel_email: string
  data: string
  descricao: string
}

export interface Regiao {
  value: string
  label: string
}
