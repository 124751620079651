import React from "react";
import { Grid } from "@mui/material";
import GABLabel from "../../../../components/gab-label";
import GABTextEditor from "../../../../components/gab-text-editor";
import GABAutocomplete from "../../../../components/gab-autocomplete";
import GabInput from "../../../../components/gab-input";
import GABInput from "../../../../components/gab-input";
import {
  PRIORIDADES,
  STATUS_GERAL,
} from "../../../../utils/select-utils.domain";
import GABForm from "../../../../components/gab-form";
import { FormikProps } from "formik";
import { Solicitante } from "../../dto/solicitante";
import {
  Categoria,
  Generic,
  UsuariosResponsavel,
} from "../../component/typings";
import { formatCpf, getAge } from "../../../../utils/str-utils";

interface propsGABFormDemandaInfo {
  formik: FormikProps<any>;
  solicitante?: Solicitante;
  categorias: Categoria[];
  equipes: Generic[];
  kanban: Generic[];
  regioes: Generic[];
  responsaveis: UsuariosResponsavel[];
  modelType: any;
}
const GABFormDemandaInfo: React.FC<propsGABFormDemandaInfo> = (props) => {
  return (
    <GABForm
      icon={
        "streamline:interface-file-clipboard-text-edition-form-task-checklist-edit-clipboard"
      }
      title={"Nova Demanda"}
      subtile={`Cadastro de demanda`}
    >
      <Grid container spacing={1} padding={0}>
        {props.formik.values.interna === false ? (
          <>
            <GABLabel
              size={4}
              title={"Nome"}
              subTitle={props.solicitante?.nome}
            />
            <GABLabel
              size={4}
              title={"CPF/CNPJ"}
              subTitle={formatCpf(props.solicitante?.cpfCnpj)}
            />
            <GABLabel
              size={4}
              colorBadge={"secondary"}
              title={"Nascimento"}
              subTitle={props.solicitante?.nascimento}
              subTitleBadge={getAge(props.solicitante?.nascimento)}
            />

            <GABLabel
              size={4}
              title={"Logradouro"}
              subTitle={props.solicitante?.endereco.logradouro}
            />
            <GABLabel
              size={4}
              title={"Bairro"}
              subTitle={props.solicitante?.endereco.bairro}
            />
            <GABLabel
              size={3}
              title={"Cidade"}
              subTitle={props.solicitante?.endereco.cidade}
            />
            <GABLabel
              size={1}
              title={"UF"}
              subTitle={props.solicitante?.endereco.uf}
            />

            <GABLabel
              size={3}
              title={"Nome"}
              subTitle={props.solicitante?.listaTelefone[0]?.nome}
            />
            <GABLabel
              size={3}
              title={"Fone"}
              subTitle={props.solicitante?.listaTelefone[0]?.fone}
            />

            <GABLabel
              size={3}
              title={"Nome"}
              subTitle={props.solicitante?.listaTelefone[1]?.nome}
            />
            <GABLabel
              size={3}
              title={"Fone"}
              subTitle={props.solicitante?.listaTelefone[1]?.fone}
            />
          </>
        ) : null}

        <GABInput
          id={"titulo"}
          formik={props.formik}
          size={13}
          required={true}
          label={"Titulo"}
        />

        <GABTextEditor
          id={"descricao"}
          formik={props.formik}
          label={"Descrição"}
        />

        <GABAutocomplete
          id={"caixa"}
          formik={props.formik}
          size={4}
          label={"Equipe"}
          optionLabel={"nome"}
          options={props?.equipes}
        />

        <GabInput
          label={"categoria"}
          id={"categoria"}
          formik={props.formik}
          size={4}
        >
          {<option key={""} value={""}></option>}
          {props.categorias.map((option) => (
            <option key={option?._id} value={option?._id}>
              {option?.nome.toLowerCase()}
            </option>
          ))}
        </GabInput>

        <GABAutocomplete
          id={"regiao"}
          formik={props.formik}
          size={4}
          label={"Região"}
          optionLabel={"label"}
          options={props.regioes}
        />

        <GABInput
          id={"prazoData"}
          formik={props.formik}
          size={4}
          label={"Prazo de Entrega"}
          datePick
        />

        <GABAutocomplete
          id={"responsaveis"}
          limitTags={1}
          formik={props.formik}
          size={4}
          label={"Responsáveis"}
          optionLabel={"label"}
          options={props.responsaveis}
          selectAll
        />

        <GabInput
          label={"Prioridade"}
          id={"prioridade"}
          formik={props.formik}
          required={true}
          size={4}
        >
          {PRIORIDADES.map((option) => (
            <option key={option?.value} value={option?.value}>
              {option?.label.toLowerCase()}
            </option>
          ))}
        </GabInput>
        <GabInput
          label={"Status Geral"}
          id={"statusGeral"}
          value={props.formik.values?.statusGeral?.value}
          onChange={(e: any) => {
            const statusG = STATUS_GERAL.filter(
              (s) => s.value === e.target.value
            );
            props.formik.setFieldValue("statusGeral", statusG[0]);
          }}
          size={4}
        >
          {STATUS_GERAL.map((option) => (
            <option key={option?.value} value={option?.value}>
              {option?.label}
            </option>
          ))}
        </GabInput>

        <GabInput label={"Kanban"} id={"status"} formik={props.formik} size={4}>
          {<option key={""} value={""}></option>}
          {props.kanban?.map((option: Generic) => (
            <option key={option?.value} value={option?.value}>
              {option?.label?.toLowerCase()}
            </option>
          ))}
        </GabInput>
      </Grid>
    </GABForm>
  );
};

export default GABFormDemandaInfo;
