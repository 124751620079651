import React, {MouseEventHandler, useContext} from "react";
import {useTheme} from "@mui/material/styles";
import AppProviderContext, {propsAppProviderContext} from "../../app-provider";

interface propsGABCanAccess {
    children: React.ReactNode;
    roles:number[];
}
const GABCanAccess: React.FC<propsGABCanAccess> = (props) => {
    const {
        canAccess,
    }: propsAppProviderContext = useContext(AppProviderContext);
    return (
        <>
            {
                canAccess(props.roles)===true? props.children : <></>
            }
        </>
    );
};

export default GABCanAccess;
