import React from "react";
import { FormControlLabel, Grid, Switch, SwitchProps, styled } from "@mui/material";
import useResponsive from "../../hooks/useResponsive";

interface propsCardFormSwitch {
  label: string;
  id?: string;
  size?: number;
  required?: boolean;
  value?: boolean;
  onChange?: (e: any) => void;
  error?: boolean;
  helperText?: React.ReactNode;
  disabled?: boolean;
  labelPlacement?: "end" | "start" | "top" | "bottom";
}

const GABSwitch: React.FC<propsCardFormSwitch> = (props) => {
  const isDesktop = useResponsive("up", "lg");

  const IOSSwitch = styled((props: SwitchProps) => (
    <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
  ))(({ theme }) => ({
    marginRight: '4px',
    marginLeft: '8px',
    width: 42,
    height: 26,
    padding: 0,
    '& .MuiSwitch-switchBase': {
      padding: 0,
      margin: 2,
      transitionDuration: '300ms',
      '&.Mui-checked': {
        transform: 'translateX(16px)',
        color: '#fff',
        '& + .MuiSwitch-track': {
          backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#65C466',
          opacity: 1,
          border: 0,
        },
        '&.Mui-disabled + .MuiSwitch-track': {
          opacity: 0.5,
        },
      },
      '&.Mui-focusVisible .MuiSwitch-thumb': {
        color: '#33cf4d',
        border: '6px solid #fff',
      },
      '&.Mui-disabled .MuiSwitch-thumb': {
        color:
          theme.palette.mode === 'light'
            ? theme.palette.grey[100]
            : theme.palette.grey[600],
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
      },
    },
    '& .MuiSwitch-thumb': {
      boxSizing: 'border-box',
      width: 22,
      height: 22,
    },
    '& .MuiSwitch-track': {
      borderRadius: 26 / 2,
      backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
      opacity: 1,
      transition: theme.transitions.create(['background-color'], {
        duration: 500,
      }),
    },
  }));

  return (
    <Grid
      item
      xs={isDesktop ? props?.size || 6 : 12}
    >
      <FormControlLabel
        style={{
          display: "flex",
          justifyContent: "left",
        }}
        labelPlacement={
          props.labelPlacement
            ? props.labelPlacement
            : isDesktop
            ? "bottom"
            : "start"
        }
        control={
          <div
            style={{
              display: "flex",
              justifyContent: "end",
            }}
          >
            <IOSSwitch
              color="success"
              disabled={props?.disabled}
              checked={props?.value}
              required={props.required}
              onChange={props?.onChange}
              id={props.id}
            />
          </div>
        }
        label={props.label}
      />
    </Grid>
  );
};

export default GABSwitch;
