import {
  Fade,
  Grid,
  IconButton,
  TableCell,
  TableRow,
  Typography
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import AppProviderContext, {
  propsAppProviderContext
} from "../../app-provider";
import MUIDataTable from "mui-datatables";
import { useTheme } from "@mui/material/styles";
import { alpha } from "@mui/material/styles";
import GABMenuItem from "../../components/gab-menu/item";
import GABMenu from "../../components/gab-menu";
import GABForm from "../../components/gab-form";
import GABDialog from "../../components/gab-dialog";
import GABIcon from "../../components/gab-icon";

import { format } from "date-fns";
import ptBR from "date-fns/locale/pt-BR";
import Chamado from "./chamado";
import ChamadoDetail from "./chamado-detail";
import GABChip from "../../components/gab-chip";
import GABButtonIcon from "../../components/gab-button-icon";

const ChamadoList: React.FC = () => {
  const { getAxios, setLoading, canAccess }: propsAppProviderContext = useContext(
    AppProviderContext
  );
  const [chamados, setData] = useState<any[]>([]);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [chamado, setChamado] = useState("");

  const init = async () => {
    setLoading(true);
    const value = canAccess([1000]) ? await getAxios("parlamentar/chamadoGeral") : await getAxios("parlamentar/chamado");
    
    let dados = value.data.sort((a:any, b:any) => {
      let ai = 0;
      let bi = 0;
      switch (a.status) {
        case "ABERTO":
          ai = 1;
          break;
        case "EM ANDAMENTO":
          ai = 2;
          break;
        case "RESPONDIDO":
          ai = 3;
          break;
        case "FECHADO":
          ai = 4;
          break;
        default:
          ai = 0;
      }
      switch (b.status) {
        case "ABERTO":
          bi = 1;
          break;
        case "EM ANDAMENTO":
          bi = 2;
          break;
        case "RESPONDIDO":
          bi = 3;
          break;
        case "FECHADO":
          bi = 4;
          break;
        default:
          bi = 0;
      }

      return ai - bi;
    });
    
    setData(dados);
    setLoading(false);
  };

  useEffect(() => {
    init();
  }, []);

  const [anchorEl, setAnchorEl] = React.useState(null);

  const [indexSelected, setIndexSelected] = useState(-1);
  const handleClick = (event: any, rowIndex: number) => {
    setAnchorEl(event.currentTarget);
    setIndexSelected(rowIndex);
  };
  const closeMenu = () => {
    setIndexSelected(-1);
    setAnchorEl(null);
  };

  const columns = [
    {
      name: "protocolo",
      label: "Protocolo"
    },
    {
      name: "assunto",
      label: "Assunto"
    },
    {
      name: "dataHora",
      label: "Data e Hora"
    },
    {
      name: "status",
      label: "Status"
    },
    {
      name: "Ação"
    }
  ];
  const theme = useTheme();

  const statusColor = (status: string): string => {
    switch (status) {
      case "ABERTO":
        return theme.palette.secondary.light;
      case "EM ANDAMENTO":
        return theme.palette.success.light;
      case "RESPONDIDO":
        return theme.palette.error.light;
      case "FECHADO":
        return theme.palette.info.light;
    }
    return theme.palette.primary.light;
  };

  const showBell = (dataIndex:number) =>{
    if((canAccess([1000]) && chamados[dataIndex].view && chamados[dataIndex].view === 1) || (!canAccess([1000]) && chamados[dataIndex].view && chamados[dataIndex].view === 2)){
      return true;
    }else{
      return false;
    }
  }

  const options = {
    selectableRows: "none",
    viewColumns: false,
    responsive: "vertical",
    customRowRender: (data: any, dataIndex: any, rowIndex: any) => {
      let styleLocal: React.CSSProperties = {};
      if (rowIndex % 2 === 0) {
        styleLocal.backgroundColor = alpha(theme.palette.primary.light, 0.2);
      }
      return (
        <TableRow style={styleLocal}>
          <TableCell>
            <Typography>{data[0]}</Typography>
          </TableCell>
          <TableCell>
            <Typography>{data[1]}</Typography>
          </TableCell>
          <TableCell>
            {format(new Date(data[2]), "dd/MM/yyyy HH:mm", { locale: ptBR })}
          </TableCell>
          <TableCell width={210}>
            <Typography>
              <GABChip label={data[3]} backgroundColor={statusColor(data[3])} />&nbsp;&nbsp;
              {showBell(dataIndex) ? <GABButtonIcon
                icon="akar-icons:bell"
                color={theme.palette.warning.light}
                textColor={theme.palette.warning.contrastText}
                size={18}
                tip="Nova resposta ao chamado"
              /> : null}
            </Typography>
          </TableCell>
          <TableCell width={30}>
            <IconButton
              onClick={(event: any) => {
                handleClick(event, dataIndex);
              }}
            >
              <GABIcon icon={"iconamoon:menu-kebab-vertical-bold"} />
            </IconButton>
          </TableCell>
        </TableRow>
      );
    }
  } as any;

  return (
    <Fade in timeout={1000}>
      <div>
        <GABMenu id="basic-menu" anchorEl={anchorEl} handleClose={closeMenu}>
          <GABMenuItem
            onClick={() => {
              setChamado(chamados[indexSelected]._id);
              setDialogOpen(true);
              closeMenu();
            }}
            label={"Detalhes"}
            icon={"akar-icons:info"}
            tooltip={"Detalhes do chamado"}
          />
        </GABMenu>
        <GABForm
          icon={"jam:task-list"}
          title={"Chamados Abertos"}
          subtile={`Lista de chamados abertos`}
        >
          <Grid item xs={12}>
            <MUIDataTable
              title={"Chamados"}
              data={chamados}
              columns={columns}
              options={options}
            />
          </Grid>
        </GABForm>
        <ChamadoDetail
          isOpen={dialogOpen}
          idChamado={chamado}
          onClose={() => {
            setDialogOpen(false);
            init();
            setChamado("");
          }}
        />
      </div>
    </Fade>
  );
};
export default ChamadoList;
