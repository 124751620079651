import * as Yup from "yup";
import {Endereco, Telefone} from "../../contato/contato";

export class Solicitante {
  id?:string;
  _id="";
  nome ="";
  cpfCnpj="";
  nascimento ="";
  endereco: Endereco = new Endereco();
  listaTelefone: Telefone[] = [];
}
