import React from "react";
import * as Yup from "yup";
import GABCrud from "../demanda/component/gab-crud";

const RootClients: React.FC = () => {
    const url ='/parlamentar/clientes'
    const validation =  Yup.object().shape({
        nome: Yup.string().trim().required("informe o nome"),
    });

    return (
        <GABCrud
            idField={'_id'}
            validationSchema={validation}
            service={url}
            pageTitle={'Clientes'}
            serviceName={''}
            icon={"la:business-time"}
            title={"Clientes"}
            subtile={`Gerenciar Clientes`}
            fields={
                [
                    {
                        id: "nome",
                        label: "Nome",
                        size : 12
                    }
                ]
            }
            columns={
                [
                    {
                        name: "nome",
                        label: "Nome",
                    },
                    {
                        name: "situacaoFinanceira",
                        label: "Situacao Financeira",
                    },
                    {
                        name: "status",
                        label: "Status do Sistema",
                    },
                    {
                        name: "acao",
                        label: "Ação",
                    }
                ]
            }
        />
    )
}

export default RootClients;


