import React, { useContext, useEffect, useState } from "react";
import Page from "../../components/Page";
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Fade,
  Grid
} from "@mui/material";
import GABForm from "../../components/gab-form";
import GabInput from "../../components/gab-input/index";
import { useFormik } from "formik";
import * as Yup from "yup";
import AppProviderContext, {
  propsAppProviderContext
} from "../../app-provider";
import { useParams } from "react-router-dom";
import Evento, { Participante } from "./evento";
import { Calendar, dateFnsLocalizer } from "react-big-calendar";
import {
  format,
  parse,
  startOfWeek,
  getDay,
  differenceInMonths
} from "date-fns";
import ptBR from "date-fns/locale/pt-BR";
import custonEventM from "./component/gab-customEventM";
import customAgenda from "./component/gab-customAgenda";
import "react-big-calendar/lib/css/react-big-calendar.css";
import "./style/agenda.css";
import CustomToolbar from "./component/gab-customToolBar";
import GABDialog from "../../components/gab-dialog";
import GABInput from "../../components/gab-input/index";
import GABAutocomplete from "../../components/gab-autocomplete";
import GABIcon from "../../components/gab-icon";
import GABSwitch from "../../components/gab-switch";
import GABCanAccess from "../../components/gab-canAccess";

const locales = {
  "pt-BR": ptBR
};
const localizer = dateFnsLocalizer({
  format,
  parse,
  startOfWeek,
  getDay,
  locales
});

interface propsAgenda {
  disabled?: boolean;
  toDisabled?: string[];
}

const Agenda: React.FC<propsAgenda> = props => {
  const [events, setEvents] = useState<Evento[]>([]);
  const [idAgenda, setIdAgenda] = useState<string>();
  const [idAgendaSec, setIdAgendaSec] = useState<string>();
  const [idGoogle, setIdGoogle] = useState("");
  const [idExcluir, setIdExcluir] = useState("");
  const [diferenca, setDiferenca] = useState(new Date());
  const [cores, setCores] = useState(["#6dc4ce"]);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalColorOpen, setModalColorOpen] = useState(false);
  const [usuarios, setUsuarios] = useState<Participante[]>([]);

  const validationSchema = Yup.object().shape({
    title: Yup.string()
      .trim()
      .required("informe o título do evento")
  });
  const validationConvidado = Yup.object().shape({
    displayName: Yup.string()
      .trim()
      .required("informe o nome"),
    email: Yup.string()
      .trim()
      .required("informe o email")
  });

  const {
    setLoading,
    postAxios,
    putAxios,
    delAxios,
    addMessage,
    getAxios,
    canAccess
  }: propsAppProviderContext = useContext(AppProviderContext);

  let { agendaType } = useParams();

  const formik = useFormik({
    initialValues: new Evento(),
    validationSchema: validationSchema,
    onSubmit: async values => {
      setLoading(true);
      const event = {
        id: "",
        summary: values.title,
        location: values.local,
        description: values.descricao,
        colorId: values.colorId,
        start: {
          dateTime: values.start
        },
        end: {
          dateTime: values.end
        },
        recurrence: [],
        attendees: values.participantes,
        reminders: {
          useDefault: true
        }
      };

      var resposta: any;

      if (values.id && values.id !== "") {
        event.id = values.id;

        resposta = await putAxios(`agenda/${idAgenda}`, {
          evento: event
        });

        if (values.salvarDuplo) {
          await putAxios(`agenda/${idAgendaSec}`, {
            evento: event
          });
        }
      } else {
        resposta = await postAxios(`agenda/${idAgenda}`, { evento: event });
        if (values.salvarDuplo) {
          await postAxios(`agenda/${idAgendaSec}`, {
            evento: event
          });
        }
      }

      if (resposta.status === 200) {
        const lista = [...events];

        const idx = events.findIndex(e => e.id === resposta.data.data.id);

        if (idx >= 0) {
          lista[idx] = {
            ...values,
            resource: resposta.data.data
          };
        } else {
          lista.push({
            ...values,
            resource: resposta.data.data
          });
        }

        setEvents(lista);
        setModalOpen(false);
        formik.resetForm();
        formikConvidados.resetForm();
        addMessage("Evento salvo com sucesso!", "success");
      } else {
        addMessage("Houve um erro ao salvar o evento", "error");
      }

      setLoading(false);
    }
  });

  const formikConvidados = useFormik({
    initialValues: new Participante(),
    validationSchema: validationConvidado,
    onSubmit: async values => { }
  });

  const updateUsuario = async () => {
    const request = await getAxios("auth/usuario");
    const lista = request.data.map((e: any) => {
      return {
        displayName: e.nome,
        email: e.email
      };
    });
    setUsuarios(lista);
  };

  const config = async () => {
    let agendas = await getAxios("agenda/idsAgenda");
    var id = "";
    var idSec = "";
    if (agendaType === "gabinete") {
      id = agendas.data.id_agenda_gabinete;
      idSec = agendas.data.id_agenda;
    } else {
      id = agendas.data.id_agenda;
      idSec = agendas.data.id_agenda_gabinete;
    }

    if (id !== "") {
      try{
        let cores = await getAxios(`agenda/cores/${id}`);
        if (cores.data && cores.data.cores) {
          const coresList = Object.keys(cores.data.cores).map(
            (e: any) => cores.data.cores[e].background
          );
  
          setCores(["", ...coresList]);
        }
      }catch(error){
        setCores([]);
      }
    }

    setIdAgenda(id);
    setIdAgendaSec(idSec);
  };

  const init = async () => {
    setLoading(true);
    await updateUsuario();
    await config();
    setLoading(false);
  };

  const getEvents = async (time?: number) => {
    setLoading(true);

    var resposta;

    try{
      if (!time) {
        let r = await getAxios(`agenda/${idAgenda}`);
        resposta = r.data;
      } else {
        let r = await getAxios(`agenda/data/${idAgenda}/${time}`);
        resposta = r.data;
      }
    }catch(e){
      resposta = {message: "Agenda não encontrada"};
      setIdAgenda("");
    }

    var eventos: Evento[] = [];

    if (resposta && resposta.events) {
      resposta.events.forEach((item: any, index: number) => {
        eventos.push({
          id: item.id,
          title: item.summary,
          local: item.location,
          participantes: item.attendees,
          descricao: item.description,
          start: new Date(item.start.dateTime),
          end: new Date(item.end.dateTime),
          allDay: false,
          colorId: item.colorId,
          resource: item,
          salvarDuplo: false
        });
      });

      setEvents(eventos);
    } else {
      if (resposta.message && resposta.message === "Agenda não encontrada") {
        setIdAgenda("");
      } else {
        setEvents(eventos);
      }

      addMessage(resposta.message, "error");
    }

    setLoading(false);
  };

  useEffect(() => {
    init();
  }, []);

  useEffect(() => {
    if (idAgenda && idAgenda !== "") getEvents();
  }, [idAgenda]);

  const viewEvent = (evento: Evento, e: any) => {
    editEvent(evento, e);
    setModalOpen(true);
  };

  const editEvent = (evento: Evento, e: any) => {
    formik.setValues(evento);
    formik.handleChange(e);
    setModalOpen(true);
  };

  const newEvent = (data: any) => {
    var eventoNovo = new Evento();
    const now = new Date();

    eventoNovo.start = data.start;
    eventoNovo.end = data.start;

    eventoNovo.start.setHours(now.getHours());
    eventoNovo.end.setHours(now.getHours() + 1);

    eventoNovo.start.setMinutes(now.getMinutes());
    eventoNovo.end.setMinutes(now.getMinutes());


    formik.setValues(eventoNovo);
    setModalOpen(true);
  };

  const removeEvent = async (id: string) => {
    await delAxios(`agenda/${id}/${idAgenda}`);

    let lista = [...events];

    lista = lista.filter(x => x.id !== id);

    setEvents(lista);
    setIdExcluir("");
    setModalOpen(false);

    addMessage("Evento excluido com sucesso", "success");
  };

  const salvarConfig = async () => {
    const dados =
      agendaType === "parlamentar"
        ? {
          id_agenda: idGoogle
        }
        : {
          id_agenda_gabinete: idGoogle
        };

    await putAxios("parlamentar/bio", dados);

    setIdAgenda(idGoogle);
  };

  const eventStyleGetter = (event: any) => {
    var style = {
      backgroundColor: `${cores[parseInt(event.colorId)]}`,
      color: "#FFF"
    };
    return {
      style: style
    };
  };

  const getButtonColor = (color: string) => {
    return (
      <div className={"panel-colors"}>
        {cores.map((cor, i) => {
          return cor !== "" ? (
            <Button
              className={"btn-cor"}
              style={{ backgroundColor: `${cor}` }}
              onClick={async e => {
                formik.values.colorId = i.toString();
                setModalColorOpen(false);
              }}
            />
          ) : null;
        })}
      </div>
    );
  };

  const getToolbar = (toolbar: any) => {
    return CustomToolbar(
      toolbar,
      agendaType === "parlamentar" ? 1 : 2,
      idAgenda
    );
  };

  return (
    <Fade in timeout={1000}>
      <div>
        <Page title={`Agenda do ${agendaType}`}>
          <section id={"calendario"}>
            <GABForm
              icon={"flat-color-icons:calendar"}
              title={`Agenda do ${agendaType}`}
              subtile={"Eventos e tarefas na agenda"}
            >
              {idAgenda !== "" ? (
                <Grid item xs={12}>
                  <Calendar
                    selectable
                    localizer={localizer}
                    messages={{
                      date: "Data",
                      time: "Hora",
                      event: "Evento",
                      noEventsInRange: "Nenhum evento no período",
                      allDay: "Dia todo",
                      showMore: (total: number) => `+${total} Eventos`
                    }}
                    events={events}
                    scrollToTime={new Date(2010, 1, 1, 6)}
                    defaultDate={new Date()}
                    length={7}
                    culture="pt-BR"
                    views={{
                      week: true,
                      month: true,
                      day: true,
                      agenda: true
                    }}
                    onSelectEvent={viewEvent}
                    onDoubleClickEvent={editEvent}
                    onSelectSlot={canAccess([8]) ? newEvent : () => { }}
                    onNavigate={e => {
                      if (!(differenceInMonths(e, diferenca) === 0)) {
                        setDiferenca(new Date(e));
                        getEvents(e.getTime());
                      }
                    }}
                    components={{
                      toolbar: getToolbar,
                      agenda: {
                        event: customAgenda
                      },
                      month: {
                        event: custonEventM
                      }
                    }}
                    eventPropGetter={eventStyleGetter}
                    popup
                  />
                </Grid>
              ) : (
                <>
                  <Grid item xs={2} />
                  <Grid item xs={8}>
                    <Card style={{ textAlign: "center" }}>
                      <CardHeader title="Configurar Agenda" />
                      <CardContent>
                        <p>
                          Para utilizar a agenda é preciso fazer a sincronia com
                          uma agenda do Google. <br />
                          Siga este{" "}
                          <a
                            href={require("../../assets/parlamentar/agenda.pdf")}
                            target="_BLANK"
                            rel="noreferrer"
                          >
                            passo a passo
                          </a>{" "}
                          para fazer a configuração. <br />
                          Usuario Para sincronização da agenda: <br />
                          <span style={{ color: "blue" }}>
                            &nbsp;&nbsp;472936633414-compute@developer.gserviceaccount.com
                            &nbsp;&nbsp;
                          </span>
                          <br />
                          Depois copie o o ID da agenda e cole no campo abaixo
                        </p>
                        <br />
                        <GabInput
                          id="agenda"
                          type="text"
                          value={idGoogle}
                          onChange={(e: any) => {
                            setIdGoogle(e.target.value);
                          }}
                          label="ID da agenda do google"
                        />
                        <br />
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={salvarConfig}
                        >
                          Salvar
                        </Button>
                      </CardContent>
                    </Card>
                  </Grid>
                </>
              )}
            </GABForm>
          </section>
          <GABDialog
            title={"Evento"}
            description=""
            maxWidth={"md"}
            open={modalOpen}
            onClickLeft={() => {
              formik.resetForm();
              formikConvidados.resetForm();
              setModalOpen(false);
            }}
            labelLeft={"Cancelar"}
            onClickRight={formik.submitForm}
            labelRight={"Salvar"}
            disabled={!canAccess([8])}
            onClose={() => { setModalOpen(false); }}
          >
            <form onSubmit={formik.handleSubmit} style={{ marginTop: "10px" }}>
              <Grid container spacing={3}>
                <GABInput
                  size={6}
                  id={"start"}
                  label={"Data Inicio"}
                  value={formik.values.start}
                  formik={formik}
                  dateTimePick
                  disabled={!canAccess([8])}
                />
                <GABInput
                  id={"end"}
                  size={6}
                  label={"Data Fim"}
                  value={formik.values.end}
                  formik={formik}
                  dateTimePick
                  disabled={!canAccess([8])}
                />
                <GabInput
                  label={"Título"}
                  id={"title"}
                  size={8}
                  required
                  value={formik.values.title}
                  formik={formik}
                  disabled={!canAccess([8])}
                />

                <Grid item xs={4}>
                  <Button
                    variant="contained"
                    type="button"
                    style={{
                      backgroundColor: `${cores[parseInt(formik.values.colorId)]
                        }`,
                      width: "100%",
                      height: "56px"
                    }}
                    onClick={() => {
                      setModalColorOpen(true);
                    }}
                    disabled={!canAccess([8])}
                  >
                    Cor Do Evento
                  </Button>
                </Grid>

                <GabInput
                  label={"Descrição"}
                  id={"descricao"}
                  size={12}
                  rows={4}
                  value={formik.values.descricao}
                  formik={formik}
                  disabled={!canAccess([8])}
                />

                {formik.values.resource.hangoutLink &&
                  formik.values.resource.hangoutLink !== "" ? (
                  <Grid item xs={12}>
                    <fieldset>
                      <legend>Link para conferência</legend>
                      <a
                        href={formik.values.resource.hangoutLink}
                        target="_BLANK"
                        rel="noreferrer"
                      >
                        {formik.values.resource.hangoutLink}
                      </a>
                    </fieldset>
                  </Grid>
                ) : null}

                <GabInput
                  label={"Local"}
                  id={"local"}
                  size={12}
                  required={true}
                  value={formik.values.local}
                  formik={formik}
                  disabled={!canAccess([8])}
                />
                <div
                  style={{ width: "100%", display: "block", height: "10px" }}
                />
                <GABAutocomplete
                  size={12}
                  label={"participantes"}
                  limitTags={2}
                  optionLabel={"displayName"}
                  options={usuarios}
                  value={formik.values.participantes}
                  formik={formik}
                  id={"participantes"}
                />
                <Grid item xs={12}>
                  <GABCanAccess roles={[8]}><fieldset>
                    <legend>Convidar Externos:</legend>
                    <Grid container spacing={3}>
                      <GabInput
                        label={"Nome"}
                        id={"displayName"}
                        size={4}
                        required
                        value={formikConvidados.values.displayName}
                        formik={formikConvidados}
                      />
                      <GabInput
                        label={"Email"}
                        id={"email"}
                        size={5}
                        value={formikConvidados.values.email}
                        formik={formikConvidados}
                        required
                      />

                      <Grid item xs={3}>
                        <Button
                          id="btSave"
                          variant="contained"
                          color="primary"
                          onClick={e => {
                            setLoading(true);
                            const values = {...formikConvidados.values};
                            formikConvidados.submitForm();
                            if (formikConvidados.isValid) {
                              const lista = [...usuarios];
                              const dados = {...formik.values};
                              lista.push(values);
                              setUsuarios(lista);
                              dados.participantes.push(values);
                              formikConvidados.resetForm(); 
                              formik.setValues(dados);
                            }
                            setLoading(false);
                          }}
                          style={{ width: "100%", height: "56px" }}
                        >
                          <GABIcon icon="akar-icons:circle-chevron-up" />
                          <span style={{ marginLeft: "8px" }}>Adicionar</span>
                        </Button>
                      </Grid>
                    </Grid>
                  </fieldset></GABCanAccess>
                </Grid>
                <Grid item xs={12}>
                  <Divider sx={{ height: "16px" }} />
                </Grid>
                <GABCanAccess roles={[8]}>
                  <Grid item xs={3}>
                    {formik.values.id !== "" ? (
                      <Button
                        id="btDelete"
                        variant="contained"
                        color="error"
                        onClick={e => {
                          setIdExcluir(formik.values.id);
                        }}
                        style={{ width: "100%" }}
                      >
                        Remover Evento
                      </Button>
                    ) : null}
                  </Grid>
                  <Grid item xs={3} />
                  <GABSwitch
                    size={6}
                    label={`Salvar também na agenda do ${agendaType === "gabinete" ? "PARLAMENTAR" : "GABINETE"
                      }`}
                    labelPlacement="end"
                    onChange={event => {
                      formik.values.salvarDuplo = event.target.checked;
                    }}
                  />
                </GABCanAccess>
              </Grid>
            </form>
          </GABDialog>
          <GABDialog
            title={"Cor do Evento"}
            description=""
            open={modalColorOpen}
            onClickLeft={() => {
              setModalColorOpen(false);
            }}
            labelLeft={"Cancelar"}
          >
            {getButtonColor("#ffcdd2")}
          </GABDialog>
          <GABDialog
            title={"Remover Evento"}
            description="Tem certeza que deseja excluir este evento da agenda?"
            open={idExcluir !== ""}
            onClickLeft={() => {
              setIdExcluir("");
            }}
            labelLeft={"Cancelar"}
            onClickRight={() => {
              removeEvent(idExcluir);
            }}
            labelRight="Confirmar"
          />
        </Page>
      </div>
    </Fade>
  );
};

export default Agenda;
