import React, {useContext, useEffect, useState} from "react";
import Page from "../../../components/Page";
import {Fade, Grid, Typography, useTheme} from "@mui/material";
import useResponsive from "../../../hooks/useResponsive";
import GABDraggableContainer from "../component/gab-draggable-container";
import {DragDropContext, DropResult} from "react-beautiful-dnd";
import {Board, Demanda, Kanban} from "../component/typings";
import AppProviderContext, {propsAppProviderContext} from "../../../app-provider";
import KanbanUtils from "./kanban.utils";
import './kanban.css';
import GABButtonIcon from "../../../components/gab-button-icon";
import {listaGeneros} from "../../../utils/select-utils.domain";
import GabInput from "../../../components/gab-input";

const DemandaKanban: React.FC = () => {
    const isDesktop = useResponsive('up', 'lg');
    const [limited, setLimited] = useState(false);
    const [board, setBoard] = React.useState<Board>(KanbanUtils.newBoard());


    const {
        setLoading,
        getAxios,
        putAxios,
        postAxios,
        canAccess,
        profile
    }: propsAppProviderContext = useContext(AppProviderContext);

    const update = async ()=>{
        await updateDemanda();
    }
    const updateDemanda = async ()=>{
        setLoading(true);
        try{
            const resultKanban = await getAxios('parlamentar/demanda/kanban');
            const board:Board = resultKanban.data.kanban;
            const resultDemanda = canAccess([9]) ? await getAxios('parlamentar/demanda/ativa') : await getAxios(`/parlamentar/demanda/responsavel/${profile._id}`);
            const demandas:any[] = resultDemanda.data.demanda;

            setLimited(demandas.length >= 1000);
            
            board.kb0.demanda =demandas.filter((d) =>parseInt(d.status) === 0)
            board.kb1.demanda =demandas.filter((d) =>parseInt(d.status) === 1)
            board.kb2.demanda =demandas.filter((d) =>parseInt(d.status) === 2)
            board.kb3.demanda =demandas.filter((d) =>parseInt(d.status) === 3)
            board.kb4.demanda =demandas.filter((d) =>parseInt(d.status) === 4)
            board.kb5.demanda =demandas.filter((d) =>parseInt(d.status) === 5)
            board.kb6.demanda =demandas.filter((d) =>parseInt(d.status) === 6)
            board.kb7.demanda =demandas.filter((d) =>parseInt(d.status) === 7)
            board.kb8.demanda =demandas.filter((d) =>parseInt(d.status) === 8)
            setBoard(board);

        }catch (e) {
        }

        setLoading(false);
    }

    const setKanbanByIndex = (droppableId:string, kanban:Kanban) =>{
        const index = parseInt(droppableId);
        let newBoard = board || {} as Board;
        switch (index) {
            case 0 :
                newBoard.kb0 = kanban;
                break
            case 1 :
                newBoard.kb1 = kanban;
                break
            case 2 :
                newBoard.kb2 = kanban;
                break
            case 3 :
                newBoard.kb3 = kanban;
                break
            case 4 :
                newBoard.kb4 = kanban;
                break
            case 5 :
                newBoard.kb5 = kanban;
                break
            case 6 :
                newBoard.kb6 = kanban;
                break
            case 7 :
                newBoard.kb7 = kanban;
                break
            case 8 :
                newBoard.kb8 = kanban;
                break
        }
        setBoard(newBoard);
    }
    const getKanbanByIndex = (droppableId:string):Kanban =>{
        const index = parseInt(droppableId);
        switch (index) {
            case 0 :
                return board.kb0
            case 1 :
                return board.kb1
            case 2 :
                return board.kb2
            case 3 :
                return board.kb3
            case 4 :
                return board.kb4
            case 5 :
                return board.kb5
            case 6 :
                return board.kb6
            case 7 :
                return board.kb7
            case 8 :
                return board.kb8
        }
        return {} as Kanban;
    }


    useEffect(()=>{
        update();
    },[]);


    const onUpdateKanban =async  (kanban:Kanban) =>{
            setLoading(true)
            setKanbanByIndex(kanban.index.toString(), kanban);

            const dados = {...board};
            
            dados.kb0.demandas  = dados.kb0.demanda?.length || 0;
            delete dados.kb0.demanda;
            
            dados.kb1.demandas  = dados.kb1.demanda?.length || 0;
            delete dados.kb1.demanda;

            dados.kb2.demandas  = dados.kb2.demanda?.length || 0;
            delete dados.kb2.demanda;

            dados.kb3.demandas  = dados.kb3.demanda?.length || 0;
            delete dados.kb3.demanda;

            dados.kb4.demandas  = dados.kb4.demanda?.length || 0;
            delete dados.kb4.demanda;

            dados.kb5.demandas  = dados.kb5.demanda?.length || 0;
            delete dados.kb5.demanda;

            dados.kb6.demandas  = dados.kb6.demanda?.length || 0;
            delete dados.kb6.demanda;

            dados.kb7.demandas  = dados.kb7.demanda?.length || 0;
            delete dados.kb7.demanda;

            dados.kb8.demandas  = dados.kb8.demanda?.length || 0;
            delete dados.kb8.demanda;

            await setTimeout(
                async ()=>{
                    try {
                        await postAxios('parlamentar/demanda/kanban', board);
                    }catch (e) {

                    }
                     setLoading(false)           
                }, 1000
            );
    }

    const onDragEnd = async ({destination, source}: DropResult) => {
        if (!destination) return;
        if (destination.droppableId === source.droppableId && destination.index === source.index) {
            return;
        }
        const kDestination = getKanbanByIndex(destination?.droppableId);
        const kSource = getKanbanByIndex(source?.droppableId);
        const item = kSource?.demanda ? kSource?.demanda[source.index] : {} as Demanda;

        kDestination?.demanda?.splice(destination.index,0, item );
        kSource?.demanda?.splice(source.index,1);

        setKanbanByIndex(destination?.droppableId, kDestination);
        setKanbanByIndex(source?.droppableId, kSource);
        try{
            await putAxios('parlamentar/demanda/status', {
                id: item._id,
                status:  destination.droppableId
            });
        }catch (e) {
            console.error(e);
        }
    };

    const theme = useTheme();
    const [viewGrid, setviewGrid] = useState(false)
    const [sizeGrid,setSizeGrid] =useState("")

    useEffect(
        ()=>{
            const sizeGridTmp= localStorage.getItem('sizeGrid');
            const viewGridTmp = localStorage.getItem('viewGrid');

            if(viewGridTmp){
                console.log('viewGridTmp', viewGridTmp)
                setviewGrid(viewGridTmp ==='true'? true: false)
            }

            if(sizeGridTmp) {
                console.log('sizeGridTmp', sizeGridTmp)
                setSizeGrid(sizeGridTmp)
            }

            },[]
    )
    useEffect(
        ()=>{
                localStorage.setItem('sizeGrid',sizeGrid);
            localStorage.setItem('viewGrid',viewGrid.toString());
        },[sizeGrid,viewGrid]
    )


    return (
        <Fade in timeout={1000}>
            <div  style={{
                padding : '0rem',
                overflowY : 'hidden',
                overflowX : 'hidden',
            }}>
                <Page title="Kanban ">
                    {limited ? <Typography variant="subtitle1" style={{color: theme.palette.warning.main}}> O número de demandas carregadas no kanban está limitado a no máximo 1000, para preservar o funcionamento correto do sistema. <br/> Sugerimos que finalize algumas demandas para que possa trabalhar com 100% da exibição</Typography> : null}
                    <Grid container style={{padding: '0.5rem'}}>
                        <Grid item xs={1} style={{
                            paddingTop :'0.3rem',
                            textAlign :'center'
                        }}>
                            <GABButtonIcon
                                onClick={
                                    ()=>{
                                        setviewGrid(!viewGrid)
                                    }
                                }
                                icon={viewGrid? 'bi:grid-fill' : 'gg:view-cols'}/>

                        </Grid>
                        <GabInput
                            value={sizeGrid}
                            onChange={(value:any)=>{
                                setSizeGrid(value.target.value)
                            }}
                            label={"Tamanho"}
                            id={"tamanho"}
                            size={2}
                        >
                            {["","Pequeno", "Médio", "Grande"].map((option) => (
                                <option key={option} value={option}>
                                    {option}
                                </option>
                            ))}
                        </GabInput>
                                            </Grid>
                    <DragDropContext onDragEnd={onDragEnd}>
                        <Grid container={viewGrid} className={'boardKanban'}>
                            <GABDraggableContainer sizeGrid={sizeGrid} viewGrid={viewGrid} kanban={board?.kb0} onDragEnd={onDragEnd} onUpdate={onUpdateKanban}/>
                            <GABDraggableContainer sizeGrid={sizeGrid} viewGrid={viewGrid} kanban={board?.kb1} onDragEnd={onDragEnd} onUpdate={onUpdateKanban}/>
                            <GABDraggableContainer sizeGrid={sizeGrid} viewGrid={viewGrid} kanban={board?.kb2} onDragEnd={onDragEnd} onUpdate={onUpdateKanban}/>
                            <GABDraggableContainer sizeGrid={sizeGrid} viewGrid={viewGrid} kanban={board?.kb3} onDragEnd={onDragEnd} onUpdate={onUpdateKanban}/>
                            <GABDraggableContainer sizeGrid={sizeGrid} viewGrid={viewGrid} kanban={board?.kb4} onDragEnd={onDragEnd} onUpdate={onUpdateKanban}/>
                            <GABDraggableContainer sizeGrid={sizeGrid} viewGrid={viewGrid} kanban={board?.kb5} onDragEnd={onDragEnd} onUpdate={onUpdateKanban}/>
                            <GABDraggableContainer sizeGrid={sizeGrid} viewGrid={viewGrid} kanban={board?.kb6} onDragEnd={onDragEnd} onUpdate={onUpdateKanban}/>
                            <GABDraggableContainer sizeGrid={sizeGrid} viewGrid={viewGrid} kanban={board?.kb7} onDragEnd={onDragEnd} onUpdate={onUpdateKanban}/>
                            <GABDraggableContainer sizeGrid={sizeGrid} viewGrid={viewGrid} kanban={board?.kb8} onDragEnd={onDragEnd} onUpdate={onUpdateKanban}/>
                        </Grid>
                    </DragDropContext>
                </Page>
            </div>

        </Fade>
    )
}

export default DemandaKanban;


