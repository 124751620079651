import React, {useEffect, useState} from "react";
import {Autocomplete, Grid, TextField} from "@mui/material";
import useResponsive from "../../hooks/useResponsive";
import {FormikProps} from "formik";
export interface propsCardFormInput {
    size?:number;
    options:any[];
    label:string;
    onChange?: (
        event: React.SyntheticEvent,
        value: any,
    ) => void;
    limitTags?: number;
    value?: any;
    optionLabel:string;
    formik?:FormikProps<any>
    groupBy?:string;
    selectAll?:boolean;
    id:string;
}

const GABAutocomplete:React.FC<propsCardFormInput> = (props)=>{
    const [selectedOptions, setSelectedOptions] = useState( props?.value || props.formik?.values[props.id] || []) ; // Estado controlado

    const isDesktop = useResponsive('up', 'lg');
    const handleChange = (e:any, value:any)=>{

        if(value.findIndex((e:any) => e.value === "selectALL") > -1){
            value = props?.options || [];
        }

        setSelectedOptions(value);
        props.formik?.setFieldValue(props.id, value)
    }
    let group = "";
    if(props?.groupBy)
    group = props?.groupBy;

    useEffect(
        ()=>{
            if(props.formik?.values[props.id]){
                setSelectedOptions(props.formik?.values[props.id]);
            }
        },[props.formik?.values[props.id]]
    )
    useEffect(
        ()=>{
            if(props.value){
                setSelectedOptions(props.value);
            }
        },[props.value]
    )


    return(
        <Grid item xs={isDesktop? (props?.size || 12) :  12} style={{
            paddingTop : '0.5rem',
            width : '100%'
        }}>
            <Autocomplete
                multiple
                id={props.id}
                options={props?.options && props?.selectAll ? [{[props?.optionLabel]: "Selecionar Todos", value: "selectALL"}, ...props?.options] : (props?.options || [])}
                onChange={props?.onChange ||  handleChange}
                limitTags={props?.limitTags ? props.limitTags : 1}
                disableCloseOnSelect={true}
                filterSelectedOptions
                isOptionEqualToValue={(option:any, value:any) => option?.[props?.optionLabel] === value?.[props?.optionLabel] || option?.[props?.optionLabel] === value}
                value={selectedOptions}
                getOptionLabel={ (option) => option?.[props?.optionLabel]}
                groupBy={ group !== "" ? (option) => option?.[group] : undefined}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        variant="outlined"
                        label={props?.label}
                    />
                )}
            />
        </Grid>
    );
}

export default GABAutocomplete;


