import React, { useContext, useEffect, useState } from "react";
import { useTheme } from "@mui/material/styles";
import Page from "../../components/Page";
import {
  Button,
  ButtonGroup,
  CardActions,
  Divider,
  Fade,
  Grid,
  IconButton,
  InputAdornment,
  TableCell,
  TableRow,
  Typography,
  alpha,
} from "@mui/material";
import { green } from "@mui/material/colors";
import GABForm from "../../components/gab-form";
import GabInput from "../../components/gab-input/index";
import { cpf, cnpj } from "cpf-cnpj-validator";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  listaEscolaridade,
  listaEstadoCivil,
  listaEstados,
  listaGeneros,
  listaTratamentos,
} from "../../utils/select-utils.domain";
import AppProviderContext, {
  propsAppProviderContext,
} from "../../app-provider";
import Contato, { Telefone } from "./contato";
import axios from "axios";
import MUIDataTable from "mui-datatables";
import { useNavigate, useParams } from "react-router-dom";
import GABTextEditor from "../../components/gab-text-editor";
import GABAutocomplete from "../../components/gab-autocomplete";
import { isValidDate } from "../../utils/str-utils";
import GabIcon from "../../components/gab-icon";
import GABDataTable from "../../components/gab-data-table";
import { useScroll } from "../../hooks/useScroll";
import GABFormButtons from "../../components/gab-form-buttons";
import useResponsive from "../../hooks/useResponsive";
import GABIcon from "../../components/gab-icon";
import GABMenu from "../../components/gab-menu";
import GABMenuItem from "../../components/gab-menu/item";
import GABSwitch from "../../components/gab-switch";

interface propsNewContact {
  onSubmit?: (values: any) => void;
  initialValue: Contato;
  toDisabled?: string[];
  disabled?: boolean;
}

const FormContact: React.FC<propsNewContact> = (props) => {
  let { idUser, personType } = useParams();
  const [grupos, setGrupos] = useState([]);
  const isDesktop = useResponsive("up", "lg");

  const validationSchema = Yup.object().shape({
    nome: Yup.string().trim().required("informe o nome"),
    cpfCnpj: Yup.string()
      .trim()
      .test(
        "test-invalid-cpf",
        personType === "pf" ? "CPF inválido" : "CNPJ inválido",
        (e) => {
          let valid =
            !e ||
            e === "" ||
            (personType === "pf"
              ? cpf.isValid(e ? e : "")
              : cnpj.isValid(e ? e : ""));
          return valid;
        }
      ),
    nascimento: Yup.string()
      .trim()
      .test("test-invalid-date", "Data inválida", (e) => {
        return !e || e === "" || isValidDate(e);
      }),
    redesSociais: Yup.object().shape({
      email: Yup.string().trim().nullable().email("Email inválido"),
    }),
  });

  const navigator = useNavigate();
  const theme = useTheme();

  const {
    setLoading,
    isLoading,
    postAxios,
    addMessage,
    setEditContato,
    editContato,
    getAxios,
  }: propsAppProviderContext = useContext(AppProviderContext);
  const { scrollToTop } = useScroll();
  const formik = useFormik({
    initialValues: props.initialValue,
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      try {
        setLoading(true);
        let clone: any = Object.assign({}, values);
        if (clone._id === undefined || clone._id === "") {
          delete clone._id;
        }

        clone.tipoPessoa = personType?.toUpperCase();

        await postAxios("parlamentar/pessoa", clone);
        addMessage("Contato salvo com sucesso!", "success");
        navigator("/admin/contacts");
      } catch (e: any) {
        addMessage("Não foi possível salvar o contato", "error");
      }
      setLoading(false);
      scrollToTop();
    },
  });

  const formikTelefones = useFormik({
    initialValues: Telefone.EmptyPhone(),
    onSubmit: async (values) => {
      setLoading(true);
      formik.values.listaTelefone.push(values);
      formikTelefones.resetForm();
      setLoading(false);
    },
  });

  const isDisabled = (id: string): boolean => {
    
    if (props?.disabled === true) {
      return true;
    }

    const tod = props.toDisabled?.filter((f) => f === id);

    if (tod && tod?.length > 0) {
      return true;
    }
    return isLoading;
  };

  const [anchorEl, setAnchorEl] = React.useState(null);

  const [indexSelected, setIndexSelected] = useState(-1);
  const handleClick = (event: any, rowIndex: number) => {
    setAnchorEl(event.currentTarget);
    setIndexSelected(rowIndex);
  };
  const closeMenu = () => {
    setIndexSelected(-1);
    setAnchorEl(null);
  };

  const removeTel = async (telIndex: any) => {
    if (telIndex !== -1) {
      let contato = formik.values;
      contato.listaTelefone.splice(telIndex, 1);
      await formik.setValues(contato);
    }
  };

  const isWpp = async (telIndex: any) => {
    setLoading(true);
    if (telIndex !== -1) {
      let contato = formik.values;
      contato.listaTelefone[telIndex].isWpp =
        !contato.listaTelefone[telIndex].isWpp;
      await formik.setValues(contato);
    }
    setLoading(false);
  };

  const findCep = async (e: any) => {
    const cepStr = formik.values.endereco.cep.replace(/\D/g, "");
    if (cepStr && cepStr.length === 8) {
      setLoading(true);
      const endereco: any = await axios.get(
        `https://viacep.com.br/ws/${cepStr}/json/`
      );
      if (endereco?.data) {
        const data = endereco?.data;
        if (data) {
          formik.setFieldValue("endereco.bairro", data?.bairro || "");
          formik.setFieldValue("endereco.logradouro", data?.logradouro || "");
          formik.setFieldValue("endereco.cidade", data?.localidade || "");
          formik.setFieldValue("endereco.uf", data?.uf || "");
        }
      }
    }
    setLoading(false);
  };

  const init = async () => {
    if (idUser) {
      setLoading(true);
      setEditContato(true);
      const response = await getAxios(`parlamentar/pessoa_id/${idUser}`);

      const contato: Contato = response.data as Contato;
      await formik.setValues(contato);
      setLoading(false);
    } else {
      setEditContato(false);
    }
  };

  const carregaGrupos = async () => {
    const value = await getAxios(`parlamentar/grupo/`);
    setGrupos(value.data.grupos);
  };

  const updateAutocomplete = (e: any, v: any) => {
    formik.values.grupos = v.map((e: any) => {
      return { label: e.nome, value: e._id };
    });
    formik.handleChange(e);
  };

  useEffect(() => {
    init();
    carregaGrupos();
  }, []);

  const columns = [
    {
      name: "isWpp",
      label: " ",
    },
    {
      name: "nome",
      label: "Nome",
    },
    {
      name: "fone",
      label: "Fone",
    },
    {
      name: "obs",
      label: "obs",
    },
    {
      name: "Ação",
      options: {
        filter: false,
        sort: false,
        empty: true,
      },
    },
  ];

  const options = {
    filter: false,
    selectableRows: "none",
    search: true,
    print: false,
    download: false,
    viewColumns: false,
    responsive: "vertical",
    customRowRender: (data: any, dataIndex: any, rowIndex: any) => {
      let styleLocal: React.CSSProperties = {};
      if (rowIndex % 2 === 0) {
        styleLocal.backgroundColor = alpha(theme.palette.primary.light, 0.2);
      }
      return (
        <TableRow style={styleLocal}>
          <TableCell width={30}>
            {data[0] ? (
              <GABIcon width={20} height={20} icon={"logos:whatsapp-icon"} />
            ) : null}
          </TableCell>
          <TableCell>
            <Typography>{data[1]}</Typography>
          </TableCell>
          <TableCell>
            <Typography>{data[2]}</Typography>
          </TableCell>
          <TableCell>
            <Typography>{data[3]}</Typography>
          </TableCell>
          <TableCell width={30}>
            <IconButton
              onClick={(event: any) => {
                handleClick(event, dataIndex);
              }}
            >
              <GABIcon icon={"iconamoon:menu-kebab-vertical-bold"} />
            </IconButton>
          </TableCell>
        </TableRow>
      );
    },
  } as any;

  return (
    <Fade in timeout={1000}>
      <div>
        <form onSubmit={formik.handleSubmit}>
          <Page title="Novo Contato">
              <GABMenu
                id="basic-menu"
                anchorEl={anchorEl}
                handleClose={closeMenu}
              >
                <GABMenuItem
                  onClick={() => {
                    removeTel(indexSelected);
                  }}
                  label={"Excluir"}
                  icon={"akar-icons:trash-can"}
                  tooltip={"Excluir telefone"}
                />
                <GABMenuItem
                  onClick={() => {
                    isWpp(indexSelected);
                  }}
                  label={"WhatsApp"}
                  icon={"ri:whatsapp-line"}
                  tooltip={"Marcar/Desmarcar como WhatsApp"}
                />
              </GABMenu>
              {personType === "pf" ? (
                <GABForm
                  icon={"twemoji:identification-card"}
                  title={"Dados Pessoais"}
                  subtile={"informe os dados pessoais"}
                >
                  <GabInput
                    label={"Tratamento"}
                    id={"tratamento"}
                    size={3}
                    disabled={isDisabled("tratamento")}
                    value={formik.values.tratamento}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.tratamento &&
                      Boolean(formik.errors.tratamento)
                    }
                    helperText={
                      formik.touched.tratamento && formik.errors.tratamento
                    }
                  >
                    {listaTratamentos.map((option) => (
                      <option key={option?.value} value={option?.value}>
                        {option?.label}
                      </option>
                    ))}
                  </GabInput>

                  <GabInput
                    label={"Nome completo"}
                    id={"nome"}
                    size={5}
                    disabled={isDisabled("nome")}
                    required={true}
                    value={formik.values.nome}
                    onChange={formik.handleChange}
                    error={formik.touched.nome && Boolean(formik.errors.nome)}
                    helperText={formik.touched.nome && formik.errors.nome}
                  />

                  <GabInput
                    label={"Nome Social"}
                    id={"nomeSocial"}
                    size={4}
                    disabled={isDisabled("nomeSocial")}
                    value={formik.values.nomeSocial}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.nomeSocial &&
                      Boolean(formik.errors.nomeSocial)
                    }
                    helperText={
                      formik.touched.nomeSocial && formik.errors.nomeSocial
                    }
                  />

                  <GabInput
                    label={"Nascimento"}
                    id={"nascimento"}
                    size={3}
                    disabled={isDisabled("nascimento")}
                    value={formik.values.nascimento}
                    mask={"date"}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.nascimento &&
                      Boolean(formik.errors.nascimento)
                    }
                    helperText={
                      formik.touched.nascimento && formik.errors.nascimento
                    }
                  />
                  <GabInput
                    label={"sexo"}
                    id={"sexo"}
                    size={3}
                    disabled={isDisabled("sexo")}
                    value={formik.values.sexo}
                    onChange={formik.handleChange}
                    error={formik.touched.sexo && Boolean(formik.errors.sexo)}
                    helperText={formik.touched.sexo && formik.errors.sexo}
                  >
                    {listaGeneros.map((option) => (
                      <option key={option?.value} value={option?.value}>
                        {option?.label}
                      </option>
                    ))}
                  </GabInput>

                  <GabInput
                    label={"CPF"}
                    id={"cpfCnpj"}
                    size={3}
                    mask={"cpf"}
                    disabled={isDisabled("cpfCnpj")}
                    value={formik.values.cpfCnpj}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.cpfCnpj && Boolean(formik.errors.cpfCnpj)
                    }
                    helperText={formik.touched.cpfCnpj && formik.errors.cpfCnpj}
                  />
                  <GabInput
                    label={"RG"}
                    id={"rg"}
                    size={3}
                    disabled={isDisabled("rg")}
                    value={formik.values.rg}
                    onChange={formik.handleChange}
                    error={formik.touched.rg && Boolean(formik.errors.rg)}
                    helperText={formik.touched.rg && formik.errors.rg}
                  />

                  <GabInput
                    label={"Estado Civil"}
                    id={"estadoCivil"}
                    size={3}
                    disabled={isDisabled("estadoCivil")}
                    value={formik.values.estadoCivil}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.estadoCivil &&
                      Boolean(formik.errors.estadoCivil)
                    }
                    helperText={
                      formik.touched.estadoCivil && formik.errors.estadoCivil
                    }
                  >
                    {listaEstadoCivil.map((option) => (
                      <option key={option?.value} value={option?.value}>
                        {option?.label}
                      </option>
                    ))}
                  </GabInput>

                  <GabInput
                    label={"Escolaridade"}
                    id={"escolaridade"}
                    size={3}
                    disabled={isDisabled("escolaridade")}
                    value={formik.values.escolaridade}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.escolaridade &&
                      Boolean(formik.errors.escolaridade)
                    }
                    helperText={
                      formik.touched.escolaridade && formik.errors.escolaridade
                    }
                  >
                    {listaEscolaridade.map((option) => (
                      <option key={option?.value} value={option?.value}>
                        {option?.label}
                      </option>
                    ))}
                  </GabInput>
                </GABForm>
              ) : (
                <GABForm
                  icon={"twemoji:identification-card"}
                  title={"Dados Empresariais"}
                  subtile={"informe os dados da empresa"}
                >
                  <GabInput
                    label={"Razão Social"}
                    id={"nome"}
                    size={6}
                    disabled={isDisabled("nome")}
                    required={true}
                    value={formik.values.nome}
                    onChange={formik.handleChange}
                    error={formik.touched.nome && Boolean(formik.errors.nome)}
                    helperText={formik.touched.nome && formik.errors.nome}
                  />

                  <GabInput
                    label={"Nome Fantasia"}
                    id={"nomeSocial"}
                    size={6}
                    disabled={isDisabled("nomeSocial")}
                    value={formik.values.nomeSocial}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.nomeSocial &&
                      Boolean(formik.errors.nomeSocial)
                    }
                    helperText={
                      formik.touched.nomeSocial && formik.errors.nomeSocial
                    }
                  />

                  <GabInput
                    label={"Abertura"}
                    id={"nascimento"}
                    size={3}
                    disabled={isDisabled("nascimento")}
                    value={formik.values.nascimento}
                    mask={"date"}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.nascimento &&
                      Boolean(formik.errors.nascimento)
                    }
                    helperText={
                      formik.touched.nascimento && formik.errors.nascimento
                    }
                  />

                  <GabInput
                    label={"CNPJ"}
                    id={"cpfCnpj"}
                    size={3}
                    mask={"cnpj"}
                    disabled={isDisabled("cpfCnpj")}
                    value={formik.values.cpfCnpj}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.cpfCnpj && Boolean(formik.errors.cpfCnpj)
                    }
                    helperText={formik.touched.cpfCnpj && formik.errors.cpfCnpj}
                  />

                  <GabInput
                    label={"IE - Inscrição Estadual"}
                    id={"rg"}
                    size={3}
                    disabled={isDisabled("rg")}
                    value={formik.values.rg}
                    onChange={formik.handleChange}
                    error={formik.touched.rg && Boolean(formik.errors.rg)}
                    helperText={formik.touched.rg && formik.errors.rg}
                  />

                  <GabInput
                    label={"IM - Inscrição Municipal"}
                    id={"im"}
                    size={3}
                    disabled={isDisabled("im")}
                    value={formik.values.im}
                    onChange={formik.handleChange}
                    error={formik.touched.im && Boolean(formik.errors.im)}
                    helperText={formik.touched.im && formik.errors.im}
                  />
                </GABForm>
              )}
              <div style={{ height: "10px" }} />
              <GABForm
                icon={"flat-color-icons:contacts"}
                title={"Contato"}
                subtile={"informe dados de contato"}
              >
                <GabInput
                  label={"email"}
                  id={"redesSociais.email"}
                  size={6}
                  disabled={isDisabled("redesSociais.email")}
                  value={formik.values.redesSociais.email}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.redesSociais &&
                    Boolean(formik.errors.redesSociais?.email)
                  }
                  helperText={
                    formik.touched.redesSociais?.email &&
                    formik.errors.redesSociais?.email
                  }
                  inputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Typography variant={"h6"}>@</Typography>
                      </InputAdornment>
                    ),
                  }}
                />

                <GabInput
                  label={"facebook"}
                  id={"redesSociais.facebook"}
                  size={3}
                  disabled={isDisabled("redesSociais.facebook")}
                  value={formik.values.redesSociais.facebook}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.redesSociais &&
                    Boolean(formik.errors.redesSociais?.facebook)
                  }
                  helperText={
                    formik.touched.redesSociais?.facebook &&
                    formik.errors.redesSociais?.facebook
                  }
                  inputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <GabIcon icon="fa6-brands:facebook-square" />
                      </InputAdornment>
                    ),
                  }}
                />

                <GabInput
                  label={"instagram"}
                  id={"redesSociais.instagram"}
                  size={3}
                  disabled={isDisabled("redesSociais.instagram")}
                  value={formik.values.redesSociais.instagram}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.redesSociais &&
                    Boolean(formik.errors.redesSociais?.instagram)
                  }
                  helperText={
                    formik.touched.redesSociais?.instagram &&
                    formik.errors.redesSociais?.instagram
                  }
                  inputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <GabIcon icon="fa6-brands:instagram-square" />
                      </InputAdornment>
                    ),
                  }}
                />

                <GabInput
                  label={"twitter"}
                  id={"redesSociais.twitter"}
                  size={3}
                  disabled={isDisabled("redesSociais.twitter")}
                  value={formik.values.redesSociais.twitter}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.redesSociais &&
                    Boolean(formik.errors.redesSociais?.twitter)
                  }
                  helperText={
                    formik.touched.redesSociais?.twitter &&
                    formik.errors.redesSociais?.twitter
                  }
                  inputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <GabIcon icon="fa6-brands:twitter-square" />
                      </InputAdornment>
                    ),
                  }}
                />

                <GabInput
                  label={"linkedin"}
                  id={"redesSociais.linkedin"}
                  size={3}
                  disabled={isDisabled("redesSociais.linkedin")}
                  value={formik.values.redesSociais.linkedin}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.redesSociais &&
                    Boolean(formik.errors.redesSociais?.linkedin)
                  }
                  helperText={
                    formik.touched.redesSociais?.linkedin &&
                    formik.errors.redesSociais?.linkedin
                  }
                  inputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <GabIcon icon="akar-icons:linkedin-box-fill" />
                      </InputAdornment>
                    ),
                  }}
                />

                <GabInput
                  label={"telegram"}
                  id={"redesSociais.telegram"}
                  size={3}
                  disabled={isDisabled("redesSociais.telegram")}
                  value={formik.values.redesSociais.telegram}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.redesSociais &&
                    Boolean(formik.errors.redesSociais?.telegram)
                  }
                  helperText={
                    formik.touched.redesSociais?.telegram &&
                    formik.errors.redesSociais?.telegram
                  }
                  inputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <GabIcon icon="akar-icons:telegram-fill" />
                      </InputAdornment>
                    ),
                  }}
                />

                <div
                  style={{
                    width: "100%",
                    marginTop: "1rem",
                  }}
                >
                  <Divider style={{ height: "0.5rem" }} />
                </div>

                <GabInput
                  label={"Nome"}
                  id={"nome"}
                  size={3}
                  value={formikTelefones.values.nome}
                  onChange={formikTelefones.handleChange}
                  error={
                    formikTelefones.touched.nome &&
                    Boolean(formikTelefones.errors.nome)
                  }
                  helperText={
                    formikTelefones.touched.nome && formikTelefones.errors.nome
                  }
                />
                <GabInput
                  label={"Telefone"}
                  id={"fone"}
                  size={3}
                  mask={"phone"}
                  value={formikTelefones.values.fone}
                  onChange={formikTelefones.handleChange}
                  error={
                    formikTelefones.touched.fone &&
                    Boolean(formikTelefones.errors.fone)
                  }
                  helperText={
                    formikTelefones.touched.fone && formikTelefones.errors.fone
                  }
                />
                <GabInput
                  label={"obs"}
                  id={"obs"}
                  size={3}
                  value={formikTelefones.values.obs}
                  onChange={formikTelefones.handleChange}
                  error={
                    formikTelefones.touched.obs &&
                    Boolean(formikTelefones.errors.obs)
                  }
                  helperText={
                    formikTelefones.touched.obs && formikTelefones.errors.obs
                  }
                />

                <GABSwitch
                    size={3}
                    label={"WhatsApp"}
                    id="isWpp"
                    onChange={(event) => {
                      formikTelefones.values.isWpp = event.target.checked;
                    }}
                />
                <Grid item container xs={12} md={6}/>

                <Grid item container xs={12} md={6}>
                  <Button
                      id="btSave"
                      fullWidth
                      variant="contained"
                      color="primary"
                      onClick={() => {
                        formikTelefones.submitForm();
                      }}
                      style={{ width: "100%", height: "100%" }}
                  >
                    Adicionar
                  </Button>
                </Grid>

                <Grid
                  item
                  xs={12}
                  style={{
                    width: "100%",
                  }}
                >
                  <GABDataTable
                    title={"Telefones"}
                    data={formik.values.listaTelefone}
                    columns={columns}
                    options={options}
                  />
                </Grid>
              </GABForm>
              <GABForm
                icon={"noto:house-with-garden"}
                title={"Endereço"}
                subtile={"informe o endereço"}
              >
                <GabInput
                  label={"Cep"}
                  id={"endereco.cep"}
                  size={6}
                  mask={"cep"}
                  disabled={isDisabled("cep")}
                  value={formik.values.endereco?.cep}
                  onChange={formik.handleChange}
                  onKeyUp={(e: any) => {
                    findCep(e);
                  }}
                  error={
                    formik.touched.endereco?.cep &&
                    Boolean(formik.errors.endereco?.cep)
                  }
                  helperText={
                    formik.touched.endereco?.cep && formik.errors.endereco?.cep
                  }
                />

                <GabInput
                  label={"Logradouro"}
                  id={"endereco.logradouro"}
                  size={6}
                  disabled={isDisabled("logradouro")}
                  value={formik.values.endereco?.logradouro}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.endereco?.logradouro &&
                    Boolean(formik.errors.endereco?.logradouro)
                  }
                  helperText={
                    formik.touched.endereco?.logradouro &&
                    formik.errors.endereco?.logradouro
                  }
                />

                <GabInput
                  label={"Numero"}
                  id={"endereco.numero"}
                  disabled={isDisabled("numero")}
                  size={6}
                  value={formik.values.endereco?.numero}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.endereco?.numero &&
                    Boolean(formik.errors.endereco?.numero)
                  }
                  helperText={
                    formik.touched.endereco?.numero &&
                    formik.errors.endereco?.numero
                  }
                />

                <GabInput
                  label={"Complemento"}
                  id={"endereco.complemento"}
                  size={6}
                  disabled={isDisabled("complemento")}
                  value={formik.values.endereco?.complemento}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.endereco?.complemento &&
                    Boolean(formik.errors.endereco?.complemento)
                  }
                  helperText={
                    formik.touched.endereco?.complemento &&
                    formik.errors.endereco?.complemento
                  }
                />
                <GabInput
                  label={"Bairro"}
                  id={"endereco.bairro"}
                  size={6}
                  disabled={isDisabled("bairro")}
                  value={formik.values.endereco?.bairro}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.endereco?.bairro &&
                    Boolean(formik.errors.endereco?.bairro)
                  }
                  helperText={
                    formik.touched.endereco?.bairro &&
                    formik.errors.endereco?.bairro
                  }
                />

                <GabInput
                  label={"Cidade"}
                  id={"endereco.cidade"}
                  size={6}
                  disabled={isDisabled("cidade")}
                  value={formik.values.endereco?.cidade}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.endereco?.cidade &&
                    Boolean(formik.errors.endereco?.cidade)
                  }
                  helperText={
                    formik.touched.endereco?.cidade &&
                    formik.errors.endereco?.cidade
                  }
                />

                <GabInput
                  label={"UF"}
                  id={"endereco?.uf"}
                  size={3}
                  disabled={isDisabled("endereco?.uf")}
                  value={formik.values.endereco?.uf}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.endereco?.uf &&
                    Boolean(formik.errors.endereco?.uf)
                  }
                  helperText={
                    formik.touched.endereco?.uf && formik.errors.endereco?.uf
                  }
                >
                  {listaEstados.map((option) => (
                    <option key={option?.id} value={option?.id}>
                      {option?.estado}
                    </option>
                  ))}
                </GabInput>
              </GABForm>


              <GABForm
                icon={"noto:busts-in-silhouette"}
                title={"Grupos"}
                subtile={"selecione os grupos dos quais o contato faz parte"}
              >
                <GABAutocomplete
                  id={"grupos"}
                  size={12}
                  label={"Grupos"}
                  limitTags={-1}
                  optionLabel={"nome"}
                  value={formik.values.grupos.map((e: any) => {
                    return { nome: e.label, _id: e.value };
                  })}
                  options={grupos}
                  onChange={updateAutocomplete}
                />
              </GABForm>
              <GABForm
                icon={"noto:notebook"}
                title={"Observaçoes"}
                subtile={"Observaçoes e notas sobre este contato"}
              >
                <GABTextEditor
                  id={"obs"}
                  size={12}
                  label={""}
                  value={formik.values.obs}
                  onChange={(texto: string) => {
                    formik.values.obs = texto;
                  }}
                />
              </GABForm>
            <GABFormButtons size={12} formik={formik} />
          </Page>
        </form>
      </div>
    </Fade>
  );
};

export default FormContact;
