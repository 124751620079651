import React from "react";
import { Grid} from '@mui/material';

interface propsGABGridMenu {
    children: React.ReactNode;
}
const GABGridMenu: React.FC<propsGABGridMenu> = (props) => {

    return (
            <Grid container spacing={2} padding={2}>
                {props?.children}
            </Grid>
    )
}
export default GABGridMenu;
