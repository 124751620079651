export default class Evento {
  id = "";
  title = "";
  local = "";
  participantes: Participante[] = [];
  descricao = "";
  start = new Date();
  end = new Date();
  allDay = false;
  colorId = "0";
  resource = {
    hangoutLink: ""
  };
  salvarDuplo = false;
}

export class Participante {
  displayName = "";
  email = "";
}
