import React, {MouseEventHandler} from "react";
import {Box, Grid, IconButton, TableCell, TableRow, Tooltip, Typography} from "@mui/material";
import {alpha, useTheme} from "@mui/material/styles";
import {Icon} from "@iconify/react";
import MUIDataTable, {MUIDataTableColumnDef, MUIDataTableOptions} from "mui-datatables";
import GabIcon from "../gab-icon";
import GABButtonIcon from "../gab-button-icon";
import GABIcon from "../gab-icon";
import { nanoid } from 'nanoid'

interface propsCardForm {
    data: any;
    options?: any;
    title: string ;
    columns: any[];
    print?: boolean;
    download?: boolean;
    search?: boolean;
    filter?: boolean;
    buttons?: React.ReactNode
    onClickMenu?: (event:any, dataIndex: any)=>void;
    customRowRender?: (data: any[], dataIndex: any, rowIndex: any)=>void;
    iconMenu?:string;
    rowsPerPage?: number;
    noAction?: boolean;
}
const GABDataTable: React.FC<propsCardForm> = (props) => {

    const getRow = (value:any,dataIndex:any) =>{
            return  <TableCell    key={nanoid()}>
                <Typography>{value}</Typography>
            </TableCell>
    }
    const getRowAction = (dataIndex:any) =>{
        return  !props.noAction ? <TableCell width={30}  key={nanoid()}>
            <IconButton onClick={
                (event: any) => {
                    if(props.onClickMenu) {
                        props.onClickMenu(event, dataIndex)
                    }
                }
            }>
                <GABIcon
                    icon={props.iconMenu? props.iconMenu : "iconamoon:menu-kebab-vertical-bold"}
                />
            </IconButton>
        </TableCell> : null
    }
    const theme = useTheme();
    const options = {
        filter: props.filter || false,
        selectableRows: "none",
        search: props.search || false,
        print: props.print || false,
        download: props.download || false,
        viewColumns: false,
        rowsPerPage: props.rowsPerPage || 10,
        responsive: 'vertical',
        customToolbar: () => {
            return (
                < >
                    {
                        props.buttons
                    }
                 </ >
            );
        },
        customRowRender: props.customRowRender? props.customRowRender: (data: any[], dataIndex: any, rowIndex: any) => {
        let styleLocal: React.CSSProperties = {};
        if (rowIndex % 2 === 0) {
            styleLocal.backgroundColor = alpha(theme.palette.primary.light, 0.20);
        }
        return (
            <TableRow style={styleLocal} key={nanoid()}>
                {
                    data.map(
                        (item, idx) =>{
                            return (
                                (item=== undefined && (idx+1) === data.length)? getRowAction(dataIndex) : getRow(item,dataIndex)
                            )
                        }
                    )
                }
            </TableRow>
        )
    }
    } as any;


    return (
        <Grid item xs={12} >
            <MUIDataTable
                title={props.title}
                data={props.data}
                columns={props.columns}
                options={ props.options || options }
            />
        </Grid>

    );
};

export default GABDataTable;
