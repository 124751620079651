import { Button, Grid, IconButton, Tooltip } from "@mui/material";
import GABIcon from "../../../components/gab-icon";
import AppProviderContext, {
  propsAppProviderContext
} from "../../../app-provider";
import { useContext } from "react";
import useResponsive from "../../../hooks/useResponsive";

const CustomToolbar = (toolbar: any, tipo: number, idAgenda: any) => {
  const isDesktop = useResponsive('up', 'md');
  const { getPdfAxios, setLoading }: propsAppProviderContext = useContext(AppProviderContext);

  const goToBack = () => {
    if (toolbar.view === "month") {
      toolbar.date.setMonth(toolbar.date.getMonth() - 1);
    } else if (toolbar.view === "week") {
      toolbar.date.setDate(toolbar.date.getDate() - 7);
    } else if (toolbar.view === "day") {
      toolbar.date.setDate(toolbar.date.getDate() - 1);
    } else {
      toolbar.date.setDate(toolbar.date.getDate() - 7);
    }
    toolbar.onNavigate("prev");
  };
  const goToNext = () => {
    if (toolbar.view === "month") {
      toolbar.date.setMonth(toolbar.date.getMonth() + 1);
    } else if (toolbar.view === "week") {
      toolbar.date.setDate(toolbar.date.getDate() + 7);
    } else if (toolbar.view === "day") {
      toolbar.date.setDate(toolbar.date.getDate() + 1);
    } else {
      toolbar.date.setDate(toolbar.date.getDate() + 7);
    }
    toolbar.onNavigate("next");
  };
  const goToCurrent = () => {
    const now = new Date();
    toolbar.date.setDate(now.getDate());
    toolbar.date.setMonth(now.getMonth());
    toolbar.date.setYear(now.getFullYear());
    toolbar.onNavigate("current");
    toolbar.onView("day");
  };
  const viewMes = () => {
    toolbar.onView("month");
  };
  const viewSemana = () => {
    toolbar.onView("week");
  };
  const viewDia = () => {
    toolbar.onView("day");
  };
  const viewAgenda = () => {
    toolbar.onView("agenda");
  };
  const label = () => {
    let data1, data2;
    switch(toolbar.view){
      case "month":
        return (
          <span>
            <b>{toolbar.localizer.format(toolbar.date, "MMMM")}</b>
            <span> {toolbar.localizer.format(toolbar.date, "yyyy")}</span>
          </span>
        );
      case "day":
        return (
          <span>
            {toolbar.localizer.format(toolbar.date, "dd")} de{" "}
            <b>{toolbar.localizer.format(toolbar.date, "MMMM")}</b>
            <span> {toolbar.localizer.format(toolbar.date, "yyyy")}</span>
          </span>
        );
      case "week":
        data1 = new Date(toolbar.date.getTime());
        data1.setDate(toolbar.date.getDate() - toolbar.date.getDay());

        data2 = new Date(toolbar.date.getTime());
        data2.setDate(toolbar.date.getDate() + (6 - toolbar.date.getDay()));

        return (
          <span>
            {toolbar.localizer.format(data1, "dd")} de{" "}
            <b>{toolbar.localizer.format(data1, "MMMM")}</b> a <br />
            {toolbar.localizer.format(data2, "dd")} de{" "}
            <b>{toolbar.localizer.format(data2, "MMMM")}</b>
            <span> {toolbar.localizer.format(data2, "yyyy")}</span>
          </span>
        );
      case "agenda":
        data1 = new Date(toolbar.date.getTime());
        data1.setDate(toolbar.date.getDate());

        data2 = new Date(toolbar.date.getTime());
        data2.setDate(toolbar.date.getDate() + 6);

        if (
          toolbar.localizer.format(data1, "dd") ===
            toolbar.localizer.format(data2, "dd") &&
          toolbar.localizer.format(data1, "MMMM") ===
            toolbar.localizer.format(data2, "MMMM")
        ) {
          return (
            <span>
              {toolbar.localizer.format(toolbar.date, "dd")} de{" "}
              <b>{toolbar.localizer.format(toolbar.date, "MMMM")}</b>
              <span> {toolbar.localizer.format(toolbar.date, "yyyy")}</span>
            </span>
          );
        } else {
          return (
            <span>
              {toolbar.localizer.format(data1, "dd")} de{" "}
              <b>{toolbar.localizer.format(data1, "MMMM")}</b> a <br />
              {toolbar.localizer.format(data2, "dd")} de{" "}
              <b>{toolbar.localizer.format(data2, "MMMM")}</b>
              <span> {toolbar.localizer.format(data2, "yyyy")}</span>
            </span>
          );
        }
    }
  };
  return (
    <Grid
      container
      className="agendaHeader"
      style={{ textAlign: "center", marginBottom: "8px", flexDirection: !isDesktop ? "column" : "row" }}
    >
      <Grid item md={3} sm={12} style={{ verticalAlign: "middle" }}>
        <IconButton color="success" onClick={goToBack} sx={{ m: 0.5 }}>
          <GABIcon icon={"raphael:arrowleft"} />
        </IconButton>
        <Button
          variant="contained"
          color="primary"
          sx={{ m: 0.5 }}
          onClick={goToCurrent}
        >
          Hoje
        </Button>
        <IconButton color="secondary" onClick={goToNext} sx={{ m: 0.5 }}>
          <GABIcon icon={"raphael:arrowright"} />
        </IconButton>
      </Grid>
      <Grid item md={4} sm={12} style={{ verticalAlign: "middle" }}>
        <h3 style={{ lineHeight: "30px", fontSize: "1.5em" }}>{label()}</h3>
      </Grid>
      <Grid item md={5} sm={12} style={{ verticalAlign: "middle" }}>
        <Button
          className="toolbarBtn"
          variant="contained"
          color="primary"
          sx={{ m: 0.5 }}
          onClick={viewMes}
        >
          Mensal
        </Button>
        <Button
          className="toolbarBtn"
          variant="contained"
          color="primary"
          sx={{ m: 0.5 }}
          onClick={viewSemana}
        >
          Semanal
        </Button>
        <Button
          className="toolbarBtn"
          variant="contained"
          color="primary"
          sx={{ m: 0.5 }}
          onClick={viewDia}
        >
          Diário
        </Button>
        <Button
          className="toolbarBtn"
          variant="contained"
          color="primary"
          sx={{ m: 0.5 }}
          onClick={viewAgenda}
        >
          Agenda
        </Button>
      </Grid>
      <Grid item xs={11} />
      {["agenda", "week", "day"].indexOf(toolbar.view) > -1 ? (
        <Grid item xs={1}>
          <Tooltip title="Imprimir Agenda">
            <IconButton
              color="secondary"
              onClick={async () => {
                setLoading(true);
                var data1, data2;
                switch (toolbar.view) {
                  case "agenda":
                    data1 = new Date(toolbar.date.getTime());
                    data1.setDate(toolbar.date.getDate());

                    data2 = new Date(toolbar.date.getTime());
                    data2.setDate(toolbar.date.getDate() + 6);

                    break;
                  case "week":
                    data1 = new Date(toolbar.date.getTime());
                    data1.setDate(
                      toolbar.date.getDate() - toolbar.date.getDay()
                    );

                    data2 = new Date(toolbar.date.getTime());
                    data2.setDate(
                      toolbar.date.getDate() + (6 - toolbar.date.getDay())
                    );
                    break;
                  case "day":
                    data1 = new Date(toolbar.date.getTime());
                    data2 = new Date(toolbar.date.getTime());
                }

                let doc = await getPdfAxios(
                  `agenda/pdf/${tipo}/${idAgenda}/${data1?.getTime()}/${data2?.getTime()}`
                );

                if (doc.data) {
                  const url = window.URL.createObjectURL(
                    new Blob([doc.data], { type: "application/pdf" })
                  );
                  window.open(url);
                }
                setLoading(false);
              }}
            >
              <GABIcon icon="mingcute:print-fill" />
            </IconButton>
          </Tooltip>
        </Grid>
      ) : null}
    </Grid>
  );
};

export default CustomToolbar;
