import React, { useEffect, useState} from "react";
import ReactQuill from "react-quill";
import "./index.css"
import {FormikProps} from "formik";
import {Grid, Typography} from "@mui/material";
interface propsGABTextEditor {
    id:string;
    size?:number;
    label:string;
    onChange?: (
        value: string,
    ) => void;
    value?: string;
    isWpp?: boolean;
    formik?:FormikProps<any>
}

const GABTextEditor: React.FC<propsGABTextEditor> = (props) => {
    const [val, setVal] = useState("");
    
    useEffect(()=>{
        if(props.value){
            setVal(props.value);
        }
    }, [props.value]);

    useEffect(
        ()=>{
            if(props.formik?.values[props.id]){
                setVal(props.formik?.values[props.id]);
            }
        },[props.formik?.values[props.id]]
    )


    return (
        <Grid item xs={props?.size || 12} style={{
            paddingTop : '0.5rem',
            width : '100%'
        }}>
            <Typography variant={"caption"}>{props.label}</Typography>
            <ReactQuill
                className="GABTextEditor"
                theme="snow"
                modules={{
                    toolbar: props?.isWpp ? [
                        ["bold", "italic", "strike"],
                    ] : [
                        [{ header: "1" }, { header: "2" }, { font: [] }],
                        [{ size: [] }],
                        ["bold", "italic", "underline", "strike", "blockquote"],
                        [{ list: "ordered" }, { list: "bullet" }, { indent: "-1" }, { indent: "+1" }],
                        ["link", "image", "video"],
                        ["clean"],
                    ],
                    clipboard: {
                        // toggle to add extra line breaks when pasting HTML:
                        matchVisual: false,
                    },
                }}
                formats={props?.isWpp ?
                    [
                        "bold",
                        "italic",
                        "strike"
                    ] : [
                        "header",
                        "font",
                        "size",
                        "bold",
                        "italic",
                        "underline",
                        "strike",
                        "blockquote",
                        "list",
                        "bullet",
                        "indent",
                        "link",
                        "image",
                        "video",
                    ]}
                value={val}
                onChange={(e) => {
                    setVal(e);
                    if(props.formik) {
                        props.formik.setFieldValue(props.id, e)
                    }
                    if (props.onChange) {
                        props.onChange(e);
                    }
                }}
            />
        </Grid>
  );
};

export default GABTextEditor;
