export default class User {
  _id = null;
  id = null;
  nome = "";
  nomeSocial = "";
  cpfCnpj = "";
  nascimento = "";
  rg = "";
  titulo = "";
  estadoCivil = "";
  sexo = "";
  tratamento = "";
  escolaridade = "";
  referencia = "";
  regiao = "";
  cargo = "";
  endereco = new Endereco();
  email = "";
  listaTelefone: Telefone[] = [];
  telefones: string[] = [];
  obs = "";
  tags: any[] = [];
  acessos: any[] = [];
  status: boolean = true;
}

export class Telefone {
  fone = "";
  nome = "";
  obs = "";
  isWpp = false;

  constructor(fone: string, nome: string, obs: string, isWpp: boolean) {
    this.fone = fone;
    this.nome = nome;
    this.obs = obs;
    this.isWpp = isWpp;
  }

  static EmptyPhone() {
    return new Telefone("", "", "", false);
  }
}

export class Endereco {
  uf = "";
  cep = "";
  logradouro = "";
  numero = "";
  complemento = "";
  bairro = "";
  cidade = "";
}
