import * as React from 'react';
import {useTheme} from "@mui/material/styles";
import GABChip from "../../../../components/gab-chip";


export type propsGABChipStatusDemanda = {
        status:string;
        label:string;
};
const GABChipStatusDemanda: React.FC<propsGABChipStatusDemanda> = (props) => {

    const theme = useTheme();

    const getColor = () =>{
        switch (props.status) {
            case 'ABERTA':
                return theme.palette.secondary.light;
            case 'EM ANDAMENTO':
                return theme.palette.success.light;
            case 'CANCELADA':
                return theme.palette.error.light;
            case 'FINALIZADA':
                return theme.palette.info.light;
        }
        return theme.palette.primary.light;
    }

    return (
        <GABChip label={props.label} backgroundColor={getColor()} />
    );
};

export default GABChipStatusDemanda;
