import React, {useContext, useEffect, useRef, useState} from 'react';
import {Link as RouterLink} from 'react-router-dom';
import {Box, Divider, Typography, Stack, MenuItem, Avatar, IconButton} from '@mui/material';
import MenuPopover from '../../components/MenuPopover';
import AppProviderContext, {propsAppProviderContext} from "../../app-provider";

const MENU_OPTIONS = [

    {
        label: 'Meus dados',
        linkTo: '/admin/profile',
    },
    {
        label: 'Trocar Senha',
        linkTo: '/admin/password',
    },
];


const AccountPopover: React.FC = () => {
    const anchorRef = useRef(null);
    const {profile}:propsAppProviderContext = useContext(AppProviderContext);

    const {imageUrl, nome, email} = profile;

    const [open, setOpen] = useState(null);

    const handleOpen = (event: any) => {
        setOpen(event.currentTarget);
    };

    const handleClose = () => {
        setOpen(null);
    };

    useEffect(()=>{},[imageUrl])

    return (
        <>
            <IconButton
                ref={anchorRef}
                onClick={handleOpen}
            >
                <Avatar src={imageUrl} alt="photoURL"/>
            </IconButton>

            <MenuPopover
                open={Boolean(open)}
                anchorEl={open}
                onClose={handleClose}
                sx={{
                    p: 0,
                    mt: 1.5,
                    ml: 0.75,
                    '& .MuiMenuItem-root': {
                        typography: 'body2',
                        borderRadius: 0.75,
                    },
                }}
            >
                {
                    profile?.email && (
                        <Box sx={{my: 1.5, px: 2.5}}>
                            <Typography variant="subtitle2" noWrap>
                                {nome}
                            </Typography>
                            <Typography variant="body2" sx={{color: 'text.secondary'}} noWrap>
                                {email}
                            </Typography>
                        </Box>
                    )
                }


                <Divider sx={{borderStyle: 'dashed'}}/>

                {
                    profile?.email && (
                        <Stack sx={{p: 1}}>
                            {MENU_OPTIONS.map((option) => (
                                <MenuItem key={option.label} to={option.linkTo} component={RouterLink} onClick={handleClose}>
                                    {option.label}
                                </MenuItem>
                            ))}
                        </Stack>
                    )
                }

                <Divider sx={{borderStyle: 'dashed'}}/>
                <MenuItem
                    onClick={
                        ()=>{
                            window.location.href ='/login';
                        }
                    }
                    sx={{m: 1}} >
                    {
                        profile?.email ? 'Sair' : 'Entrar'

                    }
                </MenuItem>
            </MenuPopover>
        </>
    );
}
export default AccountPopover;
