import React from "react";
import Page from "../../components/Page";
import { Fade } from "@mui/material";
import GridCardMenu from "../../components/gab-grid-menu";
import GABListItem from "../../components/gab-list-item";

const RootMenu: React.FC = () => {
  return (
    <Fade in timeout={1000}>
      <div>
        <Page title="Administrador do Sistema">
          <GridCardMenu>
            <GABListItem
                label={"Conectar Whatsapp"}
                icon={"ic:baseline-qrcode"}
                href={`/admin/root/config`}
            />

            <GABListItem
                label={"Clientes"}
                icon={"la:business-time"}
                href={`/admin/root/clients`}
            />

          </GridCardMenu>
        </Page>
      </div>
    </Fade>
  );
};

export default RootMenu;
