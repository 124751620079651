import React, {useContext} from "react";
import { Navigate, Outlet, useRoutes } from "react-router-dom";
import DashboardLayout from "./layouts/dashboard";
import NotFound from "./pages/Page404";
import { useEffect } from "react";
import DashboardApp from "./pages/dashboard";
import Login from "./pages/Login";
import ContatoMenu from "./pages/contato/contato.menu";
import FormContact from "./pages/contato/form-contact";
import Contato from "./pages/contato/contato";
import ContatoList from "./pages/contato/contato-list";
import DemandaMenu from "./pages/demanda/index.menu";
import DemandaKanban from "./pages/demanda/kanban/kanban";
import ContatoSearch from "./pages/contato/contato-search";
import DemandaNew from "./pages/demanda/new/index";
import AgendaMenu from "./pages/agenda/agenda.menu";
import Agenda from "./pages/agenda/agenda";
import FormGroups from "./pages/grupo/form-groups";
import AgendaConfig from "./pages/agenda/agenda-config";
import DemandaList from "./pages/demanda/list";
import AdminMenu from "./pages/admin/admin.menu";
import FormUser from "./pages/admin/form-user";
import User from "./pages/admin/user";
import UserList from "./pages/admin/user-list";
import FormCargos from "./pages/cargo/form-cargo";
import ToolsMenu from "./pages/tools/tools.menu";
import ContatoExport from "./pages/tools/contato-export";
import DemandaExport from "./pages/tools/demanda-export";
import MessagesMenu from "./pages/messages/messages.menu";
import MessagesSend from "./pages/messages/messages.send";
import MessagesShedule from "./pages/messages/messages.shedule";
import DemandaCategory from "./pages/demanda/category";
import DemandaTeam from "./pages/demanda/team";
import DemandaLocation from "./pages/demanda/location";
import MessageConfig from "./pages/messages/config";
import SenderWhatsapp from "./pages/messages/sender.whatsapp";
import Suporte from "./pages/suport/chamado-new";
import ChamadoList from "./pages/suport/chamado-list";
import ChamadoMenu from "./pages/suport/chamado.menu";
import PropostaList from "./pages/propostas/proposta-list";
import FormProfile from "./pages/profile/form-profile";
import FormPassword from "./pages/profile/form-password";
import AppProviderContext, {propsAppProviderContext} from "./app-provider";
import RootMenu from "./pages/root/menu";
import RootClients from "./pages/root/clients";
import TrocarSenha from "./pages/TrocarSenha";
import Cadastro from "./pages/AutoCadastro/form-teste";
import SenderWhatsappGroup from "./pages/messages/group.whatsapp";
import DemandaClean from "./pages/demanda/clean";
import MessagesMedia from "./pages/messages/messages.media";
import MessagesPacks from "./pages/messages/messages.packs";
import DemandaLogs from "./pages/demanda/logs";
import RemoverConta from "./pages/remover";
import RemoverCadastro from "./pages/admin/remover";

const Router: React.FC = () => {
  useEffect(() => {}, []);
  const isLogged = (componet: any) => componet;
  const {
    profile
  }: propsAppProviderContext = useContext(AppProviderContext);
  return useRoutes([
    {
      path: "/admin",
      element: isLogged(<DashboardLayout />),
      children: [
        { path: "/admin/dashboard", element: <DashboardApp /> },
        { path: "/admin/contacts", element: <ContatoMenu /> },
        {
          path: "/admin/contacts/:personType/new",
          element: <FormContact initialValue={new Contato()} />
        },
        {
          path: "/admin/contacts/:personType/edit/:idUser",
          element: <FormContact initialValue={new Contato()} />
        },
        { path: "/admin/contacts/:personType/list", element: <ContatoList /> },
        { path: "/admin/contacts/search", element: <ContatoSearch /> },
        { path: "/admin/groups", element: <FormGroups /> },
        { path: "/admin/demanda", element: <DemandaMenu /> },
        { path: "/admin/demanda/kanban", element: <DemandaKanban /> },
        { path: "/admin/demanda/new/:modelType", element: <DemandaNew /> },
        { path: "/admin/demanda/new/:modelType", element: <DemandaNew /> },
        { path: "/admin/demanda/edit/:id", element: <DemandaNew /> },
        { path: "/admin/demanda/list", element: <DemandaList /> },
        { path: "/admin/demanda/category", element: <DemandaCategory /> },
        { path: "/admin/demanda/team", element: <DemandaTeam /> },
        { path: "/admin/demanda/clean", element: <DemandaClean /> },
        { path: "/admin/demanda/location", element: <DemandaLocation /> },
        { path: "/admin/demanda/activity", element: <DemandaLogs /> },
        { path: "/admin/calendar", element: <AgendaMenu /> },
        { path: "/admin/calendar/:agendaType", element: <Agenda /> },
        { path: "/admin/agenda/config", element: <AgendaConfig /> },
        { path: "/admin/config", element: <AdminMenu /> },
        { path: "/admin/remover", element: <RemoverCadastro /> },
        {
          path: "/admin/user/new",
          element: <FormUser initialValue={new User()} />
        },
        { path: "/admin/user/list", element: <UserList /> },
        {
          path: "/admin/user/edit/:idUser",
          element: <FormUser initialValue={new User()} />
        },
        {
          path: "/admin/cargos",
          element: <FormCargos />
        },
        {
          path: "/admin/tools",
          element: <ToolsMenu />
        },
        {
          path: "/admin/tools/exportContacts",
          element: <ContatoExport />
        },
        {
          path: "/admin/tools/exportDemands",
          element: <DemandaExport />
        },
        // {
        //   path: "/admin/messages",
        //   element: <MessagesMenu />
        // },
        // {
        //   path: "/admin/messages/simple",
        //   element: <MessagesSend />
        // },
        // {
        //   path: "/admin/messages/media",
        //   element: <MessagesMedia />
        // },
        // {
        //   path: "/admin/messages/pack",
        //   element: <MessagesPacks />
        // },
        // {
        //   path: "/admin/messages/whatsapp",
        //   element: <SenderWhatsapp />
        // },
        // {
        //   path: "/admin/messages/whatsapp-group",
        //   element: <SenderWhatsappGroup />
        // },
        // {
        //   path: "/admin/messages/shedule",
        //   element: <MessagesShedule />
        // },
        {
          path: "/admin/suport",
          element: <ChamadoMenu />
        },
        {
          path: "/admin/suport/new",
          element: <Suporte />
        },
        {
          path: "/admin/suport/list",
          element: <ChamadoList />
        },
        {
          path: "/admin/propostas",
          element: <PropostaList />
        },
        {
          path: "/admin/messages/config",
          element: <MessageConfig client={profile?.idParlamentar} />
        },
        {
          path: "/admin/profile",
          element: <FormProfile />
        },
        {
          path: "/admin/password",
          element: <FormPassword />
        },
        {
          path: "/admin/root",
          element: <RootMenu/>
        },
        {
          path: "/admin/root/config",
          element: <MessageConfig client={process.env.REACT_APP_WHATSAPP_ASSISTENT|| ''} />
        },
        {
          path: "/admin/root/clients",
          element: <RootClients/>
        },
      ]
    },

    {
      path: "/",
      element: <Outlet />,
      children: [
        { path: "/", element: <Navigate to="admin/dashboard" /> },
        { path: "login", element: <Login /> },
        { path: "autocadastro", element: <Cadastro /> },
        { path: "/login", element: <Login /> },
        { path: "/remover/*", element: <RemoverConta /> },
        { path: "/senha/*", element: <TrocarSenha /> },
        { path: "*", element: <Navigate to="/" /> }
      ]
    },
    { path: "*", element: <Navigate to="/" replace /> }
  ]);
};

export default Router;
