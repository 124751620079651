import React from "react";
import Page from "../../components/Page";
import { Fade } from "@mui/material";
import GABGridMenu from "../../components/gab-grid-menu";
import GABListItem from "../../components/gab-list-item";

const AgendaMenu: React.FC = () => {
  return (
    <Fade in timeout={1000}>
      <div>
        <Page title="Agenda">
          <GABGridMenu>
            <GABListItem
              label={"Agenda do Parlamentar"}
              icon={"mdi:calendar-account"}
              href={`/admin/calendar/parlamentar`}
            />
            <GABListItem
              label={"Agenda do Gabinete"}
              icon={"mdi:calendar"}
              href={`/admin/calendar/gabinete`}
            />
            <GABListItem
              label={"Configurar Agendas"}
              icon={"mdi:calendar-cursor"}
              href={`/admin/agenda/config`}
            />
          </GABGridMenu>
        </Page>
      </div>
    </Fade>
  );
};

export default AgendaMenu;
