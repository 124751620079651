import React from "react";
import Page from "../../components/Page";
import { Fade } from "@mui/material";
import GABGridMenu from "../../components/gab-grid-menu";
import GABListItem from "../../components/gab-list-item";

const ContatoMenu: React.FC = () => {
  return (
    <Fade in timeout={1000}>
      <div>
        <Page title="Contatos">
          <GABGridMenu>
            <GABListItem
              label={"Adicionar Pessoa Física"}
              icon={"akar-icons:person-add"}
              href={`/admin/contacts/pf/new`}
            />
            <GABListItem
              label={"Listar Pessoa Física"}
              icon={"fluent:task-list-square-person-20-regular"}
              href={`/admin/contacts/pf/list`}
            />
            <GABListItem
              label={"Adicionar Pessoa Jurídica"}
              icon={"bi:building-add"}
              href={`/admin/contacts/pj/new`}
            />
            <GABListItem
              label={"Listar Pessoa Jurídica"}
              icon={"solar:buildings-broken"}
              href={`/admin/contacts/pj/list`}
            />
            <GABListItem
              label={"Pesquisa Avançada"}
              icon={"akar-icons:search"}
              href={`/admin/contacts/search`}
            />

            <GABListItem
              label={"Gerenciar Grupos"}
              icon={"akar-icons:people-group"}
              href={`/admin/groups`}
            />
          </GABGridMenu>
        </Page>
      </div>
    </Fade>
  );
};

export default ContatoMenu;
