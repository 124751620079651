import React, { useContext } from "react";
import Page from "../../components/Page";
import {
  CardActions,
  Fade,
} from "@mui/material";
import GABForm from "../../components/gab-form";
import GabInput from "../../components/gab-input/index";
import { useFormik } from "formik";
import * as Yup from "yup";
import AppProviderContext, {
  propsAppProviderContext,
} from "../../app-provider";
import GABFormButtons from "../../components/gab-form-buttons";

class Senha {
  senha:string = "";
  novaSenha:string = "";
  senhaConf:string = "";
}

const FormPassword: React.FC = () => {

  const validationSchema = Yup.object().shape({
    senha: Yup.string().trim().required("informe a senha atual"),
    novaSenha: Yup.string().trim().required("informe a nova senha"),
    senhaConf: Yup.string().trim().required("confirme a nova senha").oneOf([Yup.ref('novaSenha')], "A confirmação deve ser igual a nova senha"),
  });

  const {
    setLoading,
    putAxios,
    addMessage,
  }: propsAppProviderContext = useContext(AppProviderContext);

  const formik = useFormik({
    initialValues: new Senha(),
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      try {
        setLoading(true);
        let clone: any = Object.assign({}, values);
        if (clone._id === undefined || clone._id === "") {
          delete clone._id;
        }

        const r = await putAxios('/auth/usuario/atualizarsenha', {
          senhaAtual: values.senha,
          novaSenha1: values.novaSenha,
          novaSenha2: values.senhaConf
        });

        if (r.data.erro) {
          addMessage(r.data.mensagem, "error");
        } else {
          addMessage("Senha atualizada com sucesso!", "success");
        }
      } catch (e: any) {
        addMessage("Não foi possível alterar a senha", "error");
      }
      setLoading(false);
    },
  });

  return (
    <Fade in timeout={1000}>
      <div>
        <Page title="Alterar Senha">
          <form onSubmit={formik.handleSubmit}>
            <section id={"dados_password"}>
              <GABForm
                icon={"ph:key-bold"}
                title={"Alterar senha"}
                subtile={"informe a senha atual, a nova senha e confirme a nova senha"}
              >
                <GabInput
                  label={"Senha Atual"}
                  id={"senha"}
                  size={12}
                  formik={formik}
                  type="password"
                />
                <GabInput
                  label={"Nova Senha"}
                  id={"novaSenha"}
                  size={12}
                  formik={formik}
                  type="password"
                />
                <GabInput
                  label={"Confirmar Nova Senha"}
                  id={"senhaConf"}
                  size={12}
                  formik={formik}
                  type="password"
                />
              </GABForm>
            </section>

            <CardActions
              disableSpacing
              hidden={true}
            >
              <GABFormButtons size={12} formik={formik} />
            </CardActions>
          </form>
        </Page>
      </div>
    </Fade>
  );
};

export default FormPassword;
