import React from "react";
import { Box } from "@mui/material";


type logoProps = {
  disabledLink?: boolean;
  sx?: any;
  white?: boolean;
  fullWhite?: boolean;
};

const Logo: React.FC<logoProps> = (props) => {
  let img = "/static/logo_label.png";

  if (props?.white && props.white === true) {
    img = "/static/logo_label_white.png";
  }
  if (props?.fullWhite && props.fullWhite === true) {
    img = "/static/logo_white_label_white.png";
  }

  return (
    <Box sx={{ ...props.sx }}>
      <div style={{
        padding :'10px',
          width : '100%',
      }}>
        <img src={img} alt="Logo" width={'90%'} />
      </div>
    </Box>
  );
};

export default Logo;
