import { styled } from "@mui/material/styles";
import {
  Button,
  Divider,
  Fade,
} from "@mui/material";
import Page from "../../components/Page";
import React, { useContext, useEffect, useState } from "react";
import "../login.css";
import { useNavigate } from "react-router-dom";
import AppProviderContext, {
  propsAppProviderContext,
} from "../../app-provider";
import Logo from "../../components/Logo";
import { useFormik } from "formik";
import * as Yup from "yup";
import GabInput from "../../components/gab-input";
import brasilia from "../images/pexels-flavia-sandriany-13618459.jpg";
import { listaAcessos, listaEstados } from "../../utils/select-utils.domain";
import GABForm from "../../components/gab-form";

const ContentStyle = styled("div")(({ theme }) => ({
  maxWidth: 480,
  margin: "auto",
  minHeight: "100vh",
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
  padding: theme.spacing(12, 0),
}));

export default function Cadastro() {
  const [isDisabledForm, setIsDisabledForm] = useState(false);
  const navigate = useNavigate();
  const {
    getAxios,
    postAxios,
    addMessage,
    setLoading,
  }: propsAppProviderContext = useContext(AppProviderContext);

  const validationSchema = Yup.object().shape({
    email: Yup.string().required("Informe o Email").email("Email Inválido"),
  });

  const formik = useFormik({
    initialValues: {
      email: "",
      nome: "",
      uf: "DF",
      cidade: "",
      telefone: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      setIsDisabledForm(true);
      setLoading(true);
      try {
        var existe = await getAxios("auth/existe/" + values.email);
        if (!existe.data.existe) {
          let dadosSistema = {
            ...values,
            dataCriacao: new Date(),
            autoCadastro: true,
          };

          var idApp = await postAxios("parlamentar/cliente/", dadosSistema);

          if (idApp.data && idApp.data.id) {
            var dadosUsuario = {
              nome: values.nome,
              email: values.email,
              telefones: [{ fone: values.telefone }],
              acessos: listaAcessos,
              status: true,
            };

            var usuario = await postAxios(
              `auth/usuario/${idApp.data.id}`,
              dadosUsuario
            );

            setLoading(false);
            navigate("/login");

          } else {
            setLoading(false);
            setIsDisabledForm(false);
            addMessage("Erro ao criar sua conta, tente novamente", "error");
          }

          
        } else {
          setLoading(false);
          setIsDisabledForm(false);
          addMessage("Email já cadastrado no sistema", "error");
        }
      } catch (e) {
        setLoading(false);
        setIsDisabledForm(false);
        addMessage("Houve um erro ao criar seu acesso", "error");
      }
    },
  });

  useEffect(() => {
    setLoading(false);
  }, []);

  const renderForm = () => {
    return (
      <ContentStyle>
          <GABForm
            title="Olá, Bem-vindo ao GABINETE VIRTUAL"
            subtile="Entre com suas informações para criar sua conta, e fazer o teste GRATIS" 
            icon="carbon:rule-test"      
        >
            <GabInput
                label={"Email"}
                id={"email"}
                size={12}
                required={true}
                disabled={isDisabledForm}
                value={formik.values.email}
                onChange={formik.handleChange}
                error={formik.touched.email && Boolean(formik.errors.email)}
                helperText={formik.touched.email && formik.errors.email}
              />
              <GabInput
                label={"Nome"}
                id={"nome"}
                size={12}
                required={true}
                disabled={isDisabledForm}
                value={formik.values.nome}
                onChange={formik.handleChange}
                error={formik.touched.nome && Boolean(formik.errors.nome)}
                helperText={formik.touched.nome && formik.errors.nome}
              />
              <GabInput
                label={"Telefone"}
                id={"telefone"}
                size={12}
                required={true}
                mask={"phone"}
                disabled={isDisabledForm}
                value={formik.values.telefone}
                onChange={formik.handleChange}
                error={
                  formik.touched.telefone && Boolean(formik.errors.telefone)
                }
                helperText={formik.touched.telefone && formik.errors.telefone}
              />
              <GabInput
                label={"UF"}
                id={"uf"}
                size={12}
                disabled={isDisabledForm}
                value={formik.values.uf}
                onChange={formik.handleChange}
                error={formik.touched.uf && Boolean(formik.errors.uf)}
                helperText={formik.touched.uf && formik.errors.uf}
              >
                {listaEstados.map((option) => (
                  <option key={option?.id} value={option?.id}>
                    {option?.estado}
                  </option>
                ))}
              </GabInput>
            <br/>
            <Divider />
            <br/>
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                marginTop: "18px"
              }}
            >
              <Button
                disabled={isDisabledForm}
                variant="contained"
                type="reset"
                onClick={()=>{ 
                    navigate("/login");
                }}
              >
                Limpar
              </Button>
              <div style={{ minWidth: "2rem" }}></div>
              <Button
                disabled={isDisabledForm}
                variant="contained"
                color="secondary"
                type={"submit"}
              >
                Criar
              </Button>
            </div>
        </GABForm>
        <div
          style={{
            textAlign: "center",
            padding: "1rem",
          }}
        ></div>

        <div
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Logo
            fullWhite={true}
            sx={{
              width: "40%",
            }}
          />
        </div>
      </ContentStyle>
    );
  };

  return (
    <Fade in timeout={1000}>
      <div>
        <Page title="Login">
          <div
            style={{
              background: "linear-gradient(#DFE3E8, #637381)",
              backgroundImage: `url(${brasilia})`,
              backgroundSize: "cover",
            }}
          >
            <div
              style={{
                backgroundColor: "rgba(0,0,0,0.6)",
                padding: "1rem",
              }}
            >
              <form onSubmit={formik.handleSubmit}>{renderForm()}</form>

              <div
                style={{
                  position: "absolute",
                  bottom: "0",
                  height: "2rem",
                  left: "5%",
                  width: "90%",
                  textAlign: "center",
                  fontSize: "0.7rem",
                }}
              >
                <a
                  style={{
                    color: "rgba(255,255,255,0.52)",
                  }}
                  href={
                    "https://www.pexels.com/pt-br/foto/ponte-em-arco-ponte-arqueada-lago-lagoa-13618459"
                  }
                  target={"_blank"}
                >
                  Foto por Fávia Sandriany
                </a>
              </div>
            </div>
          </div>
        </Page>
      </div>
    </Fade>
  );
}
