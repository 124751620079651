export default class Chamado {
  _id = null;
  idSistema = "";
  userId = "";
  categoria = "";
  assunto = "";
  descricao = "";
  protocolo = "";
  dataHora = new Date();
  status = "";
  fone = "";
  mensagens: Resposta[] = [];
}

export class Resposta {
  status = "";
  userId = "";
  userName = "";
  mensagem = "";
  dataHora = new Date();
}
