import { Grid, IconButton, Tooltip } from "@mui/material";
import GABIcon from "../../../components/gab-icon";
import AppProviderContext, {
  propsAppProviderContext
} from "../../../app-provider";
import { useContext } from "react";
import useResponsive from "../../../hooks/useResponsive";

const CustomToolbarDay = (toolbar: any, tipo: number, idAgenda: any) => {
  const isDesktop = useResponsive('up', 'md');
  const { getPdfAxios, setLoading }: propsAppProviderContext = useContext(AppProviderContext);

  const label = () => {
      return (
        <span>
          {toolbar.localizer.format(toolbar.date, "dd")} de{" "}
          <b>{toolbar.localizer.format(toolbar.date, "MMMM")}</b>
          <span> {toolbar.localizer.format(toolbar.date, "yyyy")}</span>
        </span>
      );
  
  };
  return (
    <Grid
      container
      className="agendaHeader"
      style={{ textAlign: "center", marginBottom: "8px", flexDirection: !isDesktop ? "column" : "row" }}
    >
      
      <Grid item md={12} sm={12} style={{ verticalAlign: "middle" }}>
        <h3 style={{ lineHeight: "30px", fontSize: "1.5em" }}>{label()}</h3>
      </Grid>
      
      <Grid item xs={11} />
        <Grid item xs={1}>
          <Tooltip title="Imprimir Agenda">
            <IconButton
              color="secondary"
              onClick={async () => {
                setLoading(true);
                var data1, data2;
                
                data1 = new Date(toolbar.date.getTime());
                data2 = new Date(toolbar.date.getTime());
                
                let doc = await getPdfAxios(
                  `agenda/pdf/${tipo}/${idAgenda}/${data1?.getTime()}/${data2?.getTime()}`
                );

                if (doc.data) {
                  const url = window.URL.createObjectURL(
                    new Blob([doc.data], { type: "application/pdf" })
                  );
                  window.open(url);
                }
                setLoading(false);
              }}
            >
              <GABIcon icon="mingcute:print-fill" />
            </IconButton>
          </Tooltip>
        </Grid>
    </Grid>
  );
};

export default CustomToolbarDay;