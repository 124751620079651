import React from "react";
import { Tooltip, Typography, MenuItem } from "@mui/material";
import GABIcon from "../gab-icon";

interface propTypes {
  label: string;
  icon?: string;
  link?: string;
  tooltip?: string;
  onClick?: () => void;
  disabled?: boolean;
}

const GABMenuItem: React.FC<propTypes> = (props) => {
  return (
    <Tooltip title={props.tooltip} placement="left-start">
      {props.link ? (
        <a
          href={props.link}
          style={{ color: "inherit", textDecoration: "none" }}
        >
          <MenuItem>
            <GABIcon icon={props.icon} />
            <Typography
              variant={"button"}
              style={{
                paddingLeft: "0.4rem",
              }}
            >
              {" "}
              {props.label}
            </Typography>
          </MenuItem>
        </a>
      ) : (
        <MenuItem disabled={props.disabled} onClick={props.onClick}>
          <GABIcon icon={props.icon} />
          <Typography
            variant={"button"}
            style={{
              paddingLeft: "0.4rem",
            }}
          >
            {" "}
            {props.label}
          </Typography>
        </MenuItem>
      )}
    </Tooltip>
  );
};

export default GABMenuItem;
