import React from "react";
import GABIcon from "./components/gab-icon";

const getIcon = (name) => <GABIcon icon={name} width={22} height={22} />;

const navConfig = [
  {
    id: 1,
    roles: [],
    title: "Dashboard",
    path: "/admin/dashboard",
    icon: getIcon("akar-icons:statistic-up"),
  },

  {
    id: 3,
    roles: [3],
    title: "Contato",
    path: "/admin/contacts",
    icon: getIcon("akar-icons:people-group"),
  },
  {
    id: 5,
    roles: [4],
    title: "Demanda",
    path: "/admin/demanda",
    icon: getIcon("akar-icons:triangle-alert"),
  },
  {
    id: 6,
    roles: [2],
    title: "Agenda",
    path: "/admin/calendar",
    icon: getIcon("akar-icons:calendar"),
  },

  {
    id: 12,
    roles: [3],
    title: "Ferramentas",
    path: "/admin/tools",
    icon: getIcon("carbon:tool-kit"),
  },
  // {
  //   id: 13,
  //   roles: [1],
  //   title: "WhatsApp",
  //   path: "/admin/messages",
  //   icon: getIcon("ic:baseline-whatsapp"),
  // },
  {
    id: 11,
    roles: [1],
    title: "Administração",
    path: "/admin/config",
    icon: getIcon("akar-icons:settings-vertical"),
  },
  {
    id: 99,
    divider: "true",
  },
  {
    id: 14,
    roles: [],
    title: "Suporte",
    path: "/admin/suport",
    icon: getIcon("akar-icons:chat-approve"),
  },
  {
    id: 99,
    divider: "true",
  },
  {
    id: 1000,
    roles: [1000],
    title: "Administrador do sistema",
    path: "/admin/root",
    icon: getIcon("dashicons:admin-users"),
  },
];

export default navConfig;
