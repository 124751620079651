import React from "react";
import {
    Categoria,
} from "../component/typings";
import GABCrud from "../component/gab-crud";
import * as Yup from "yup";


const DemandaTeam: React.FC = () => {
    const url ='/parlamentar/demanda/caixa'
    const validation =  Yup.object().shape({
        nome: Yup.string().trim().required("informe o nome"),
        descricao: Yup.string().trim().required("informe a descrição"),
    });
    return (
        <GABCrud
            validationSchema={validation}
            idField={'_id'}
            service={url}
            pageTitle={'Equipe'}
            serviceName={'caixa'}
            icon={"fluent:people-team-28-filled"}
            title={"Equipe"}
            subtile={`Gerenciar Equipe`}
            fields={
                [
                    {
                        id: "nome",
                        label: "Categoria",
                        size : 12
                    },
                    {
                        id: "descricao",
                        label: "Descrição",
                        size : 12
                    }

                ]
            }
            columns={
                [
                    {
                        name: "nome",
                        label: "Categoria",
                    },
                    {
                        name: "descricao",
                        label: "Descrição",
                    },
                    {
                        name: "acao",
                        label: "Ação",
                    }
                ]
            }
        />
    )
}

export default DemandaTeam;


