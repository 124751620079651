import {
  Fade,
  Grid,
  IconButton,
  TableCell,
  TableRow,
  Typography
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import AppProviderContext, {
  propsAppProviderContext
} from "../../app-provider";
import MUIDataTable from "mui-datatables";
import { useTheme } from "@mui/material/styles";
import { alpha } from "@mui/material/styles";
import GABForm from "../../components/gab-form";
import GABIcon from "../../components/gab-icon";

import { format } from "date-fns";
import ptBR from "date-fns/locale/pt-BR";
import axios from "axios";

const PropostaList: React.FC = () => {
  const { getAxios, setLoading }: propsAppProviderContext = useContext(
    AppProviderContext
  );
  const [data, setData] = useState<any[]>([]);

  const init = async () => {
    setLoading(true);

    let proposituras = await getAxios(
      "parlamentar/widget/proposituraLista/",
      true
    );

    if (Array.isArray(proposituras.data)) {
      setData(proposituras.data);
    } else if (proposituras.data !== "") {
      var propostas = await axios.post(
        "https://ple.cl.df.gov.br/pleservico/api/public/proposicao/filter?page=0&size=10000&sort=",
        {
          intervaloNumeracao: false,
          ementa: false,
          documentoInicial: false,
          todosDocumentos: false,
          autoria: proposituras.data,
          unidadePesquisadora: "0"
        }
      );

      let lista = [];

      if (propostas.data && propostas.data.content) {
        lista = propostas.data.content;
      }

      lista = lista.map((proposta: any) => {
        let aux = proposta.siglaNumeroAno.split(" ");
        let aux2 = aux[1].split("/");
        let tipo = aux[0];
        let numero = aux2[0];
        let ano = aux2[1];

        return {
          _id: proposta.id,
          proposicao: {
            tipo: tipo,
            numero: numero,
            ano: ano
          },
          tipo: tipo,
          urlRedacaoInicial: null,
          dataLeitura: proposta.dataLeitura,
          norma: null,
          urlLei: "cldf",
          situacao: proposta.etapa,
          ementa: proposta.ementa,
          autores: proposta.autoria.split(","),
          emendas: [],
          pareceres: [],
          historico: []
        };
      });

      setData(lista);
    }

    setLoading(false);
  };

  useEffect(() => {
    init();
  }, []);

  const baixarPdf = async (idProposta: string) => {
    setLoading(true);
    let docs = await axios.post(
      `https://ple.cl.df.gov.br/pleservico/api/public/proposicao/${idProposta}/documento/ativas/order-by-pageable?page=0&size=50&sort=`,
      {}
    );

    if (docs.data && docs.data.content && docs.data.content.length > 0) {
      let docId = docs.data.content[0].id;

      let doc = await axios.post(
        `https://ple.cl.df.gov.br/pleservico/api/public/proposicao/exportar/${idProposta}/pdf`,
        [docId],
        { responseType: "blob" }
      );

      if (doc.data) {
        const url = window.URL.createObjectURL(
          new Blob([doc.data], { type: "application/pdf" })
        );
        window.open(url);
      }
    }
    setLoading(false);
  };

  const columns = [
    {
      name: "dataLeitura",
      label: "Data"
    },
    {
      name: "tipo",
      label: "Tipo"
    },
    {
      name: "ementa",
      label: "Ementa"
    },
    {
      name: "situacao",
      label: "Sitiação"
    },
    {
      name: "urlLei",
      label: "Ação"
    }
  ];
  const theme = useTheme();

  const options = {
    selectableRows: "none",
    filter: false,
    viewColumns: false,
    responsive: "vertical",
    customRowRender: (item: any, dataIndex: any, rowIndex: any) => {
      let styleLocal: React.CSSProperties = {};
      if (rowIndex % 2 === 0) {
        styleLocal.backgroundColor = alpha(theme.palette.primary.light, 0.2);
      }
      return (
        <TableRow style={styleLocal}>
          <TableCell>
            {format(new Date(item[0]), "dd/MM/yyyy", { locale: ptBR })}
          </TableCell>
          <TableCell>
            <Typography>{item[1]}</Typography>
          </TableCell>
          <TableCell>
            <Typography>{item[2]}</Typography>
          </TableCell>
          <TableCell>
            <Typography>{item[3]}</Typography>
          </TableCell>
          <TableCell width={30}>
            {item[4] && item[4] !== null ? (
              <IconButton
                onClick={(event: any) => {
                  if (item[4] === "cldf") {
                    baixarPdf(data[dataIndex]._id);
                  } else {
                    window.open(item[4], "_blank");
                  }
                }}
              >
                <GABIcon icon={"teenyicons:pdf-outline"} />
              </IconButton>
            ) : null}
          </TableCell>
        </TableRow>
      );
    }
  } as any;

  return (
    <Fade in timeout={1000}>
      <div>
        <GABForm
          icon={"jam:task-list"}
          title={"Propostas"}
          subtile={`Lista de propostas de autoria do parlamentar`}
        >
          <Grid item xs={12}>
            <MUIDataTable
              title={""}
              data={data}
              columns={columns}
              options={options}
            />
          </Grid>
        </GABForm>
      </div>
    </Fade>
  );
};
export default PropostaList;
