import React from "react";
import Page from "../../components/Page";
import { Fade } from "@mui/material";
import GABGridMenu from "../../components/gab-grid-menu";
import GABListItem from "../../components/gab-list-item";

const ChamadoMenu: React.FC = () => {
  return (
    <Fade in timeout={1000}>
      <div>
        <Page title="Suporte">
          <GABGridMenu>
            <GABListItem
              label={"Abrir Chamado"}
              icon={"ic:sharp-help-outline"}
              href={`/admin/suport/new`}
            />
            <GABListItem
              label={"Chamados Abertos"}
              icon={"mdi:table-help"}
              href={`/admin/suport/list`}
            />
          </GABGridMenu>
        </Page>
      </div>
    </Fade>
  );
};

export default ChamadoMenu;
