import React from "react";
import Page from "../../components/Page";
import { Fade } from "@mui/material";
import GridCardMenu from "../../components/gab-grid-menu";
import GABListItem from "../../components/gab-list-item";

const AdminMenu: React.FC = () => {
  return (
    <Fade in timeout={1000}>
      <div>
        <Page title="Administração">
          <GridCardMenu>
            <GABListItem
              label={"Adicionar Usuário"}
              icon={"akar-icons:person-add"}
              href={`/admin/user/new`}
            />
            <GABListItem
              label={"Listar Usuários"}
              icon={"fluent:task-list-square-person-20-regular"}
              href={`/admin/user/list`}
            />
            <GABListItem
              label={"Gerenciar Cargos"}
              icon={"tdesign:user-setting"}
              href={`/admin/cargos`}
            />
            <GABListItem
              label={"Gerenciar Grupos"}
              icon={"akar-icons:people-group"}
              href={`/admin/groups`}
            />
            <GABListItem
              label={"Categoria"}
              icon={"material-symbols:order-approve-rounded"}
              href={`/admin/demanda/category`}
            />
            <GABListItem
              label={"Gerenciar Equipes"}
              icon={"fluent:people-team-28-filled"}
              href={`/admin/demanda/team`}
            />
            <GABListItem
              label={"Gerenciar Regiões"}
              icon={"tabler:map-2"}
              href={`/admin/demanda/location`}
            />
            <GABListItem
              label={"Configurar Agendas"}
              icon={"mdi:calendar-cursor"}
              href={`/admin/agenda/config`}
            />
            <GABListItem
              label={"Excluir Gabinete"}
              icon={"material-symbols:dangerous-outline-rounded"}
              href={`/admin/remover`}
            />
          </GridCardMenu>
        </Page>
      </div>
    </Fade>
  );
};

export default AdminMenu;
