import React, { useContext, useEffect, useState } from "react";
import Page from "../../components/Page";
import { Button, Fade, Grid } from "@mui/material";
import GABForm from "../../components/gab-form";
import GabInput from "../../components/gab-input/index";
import AppProviderContext, {
  propsAppProviderContext
} from "../../app-provider";

const AgendaConfig: React.FC = () => {
  const [idGoogle, setIdGoogle] = useState("");
  const [idGoogleGab, setIdGoogleGab] = useState("");

  const {
    setLoading,
    putAxios,
    getAxios,
    addMessage
  }: propsAppProviderContext = useContext(AppProviderContext);

  const config = async () => {
    setLoading(true);
    let agendas = await getAxios("agenda/idsAgenda");
    setIdGoogle(agendas.data.id_agenda);
    setIdGoogleGab(agendas.data.id_agenda_gabinete);
    setLoading(false);
  };

  useEffect(() => {
    config();
  }, []);

  const salvarConfigP = async () => {
    setLoading(true);
    const dados = {
      id_agenda: idGoogle,
    };

    await putAxios("parlamentar/bio", dados);
    setLoading(false);
    addMessage("Configuraçoes da agenda do PARLAMENTAR salvas com sucesso!", "success");
    config();
  };

  const salvarConfigG = async () => {
    setLoading(true);
    const dados = {
      id_agenda_gabinete: idGoogleGab
    };

    await putAxios("parlamentar/bio", dados);
    setLoading(false);
    addMessage("Configuraçoes da agenda do GABINETE salvas com sucesso!", "success");
    config();
  };

  return (
    <Fade in timeout={1000}>
      <div>
        <Page title={`Configurar Agendas`}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <GABForm
                icon={"flat-color-icons:contacts"}
                title={`Configurar Agendas`}
                subtile={"configurar sincronização das agendas do Google"}
              >
                <Grid item xs={12}>
                  <p>
                    Para utilizar a agenda é preciso fazer a sincronia com uma
                    agenda do Google. <br />
                    <br />
                    Siga este &nbsp;
                    <a
                      href={require("../../assets/parlamentar/agenda.pdf")}
                      target="_BLANK"
                      rel="noreferrer"
                    >
                      passo a passo
                    </a>
                    &nbsp; para fazer a configuração. <br />
                    <br />
                    Usuario Para sincronização da agenda:
                    <span style={{ color: "blue" }}>
                      &nbsp;&nbsp;472936633414-compute@developer.gserviceaccount.com
                      &nbsp;&nbsp;
                    </span>
                    <br />
                    <br />
                    Depois copie o o ID da agenda do Google e cole nos campos das
                    respectivas agendas abaixo:
                  </p>
                </Grid>
              </GABForm>
            </Grid>
            <Grid item xs={6}>
              <GABForm
                icon={"openmoji:spiral-calendar"}
                title={`Agenda do Parlamentar`}
                subtile={"Agenda de compromissos do parlamentar"}
              >
                <GabInput
                  id="id_agenda"
                  type="text"
                  value={idGoogle}
                  onChange={(e: any) => {
                    setIdGoogle(e.target.value);
                  }}
                  label="ID AGENDA DO PARLAMENTAR"
                />
                <Grid item xs={9} />
                <Grid item xs={3}>
                  <Button
                    fullWidth
                    variant="contained"
                    color="secondary"
                    onClick={salvarConfigP}
                  >
                    Salvar
                  </Button>
                </Grid>
              </GABForm>
            </Grid>
            <Grid item xs={6}>
              <GABForm
                icon={"openmoji:calendar"}
                title={`Agenda do gabinete`}
                subtile={"Agenda de compromissos do gabinete"}
              >
                <GabInput
                  id="id_agenda_gabinete"
                  type="text"
                  value={idGoogleGab}
                  onChange={(e: any) => {
                    setIdGoogleGab(e.target.value);
                  }}
                  label="ID AGENDA DO GABINETE"
                />
                <Grid item xs={9} />
                <Grid item xs={3}>
                  <Button
                    fullWidth
                    variant="contained"
                    color="secondary"
                    onClick={salvarConfigG}
                  >
                    Salvar
                  </Button>
                </Grid>
              </GABForm>
            </Grid>
          </Grid>
        </Page>
      </div>
    </Fade>
  );
};

export default AgendaConfig;
