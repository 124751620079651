import {
    Fade,
    Grid,
    IconButton,
    TableCell,
    TableRow,
    Typography,
    alpha,
    useTheme,
} from "@mui/material";
import React, { useContext, useEffect, useState, useRef, EffectCallback, DependencyList } from "react";
import AppProviderContext, {
    propsAppProviderContext,
} from "../../app-provider";
import * as Yup from "yup";
import { useFormik } from "formik";
import GABMenuItem from "../../components/gab-menu/item";
import GABMenu from "../../components/gab-menu";
import GABForm from "../../components/gab-form";
import GABDialog from "../../components/gab-dialog";
import GabInput from "../../components/gab-input/index";
import Grupo from "./grupo";
import GABModal from "../../components/gab-modal";
import GABDataTable from "../../components/gab-data-table";
import GABButtonIcon from "../../components/gab-button-icon";
import GABIcon from "../../components/gab-icon";

const FormGroups: React.FC = () => {
    const { getAxios, postAxios, setLoading, delAxios }: propsAppProviderContext = useContext(AppProviderContext);
    const [data, setData] = useState<Grupo[]>([]);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [indexSelected, setIndexSelected] = useState(-1);

    const validationSchema = Yup.object().shape({
        nome: Yup.string().trim().required("informe o nome do grupo"),
      });

    useEffect(() => {
        buscar();
    }, []);

    const buscar = async () => {
        setLoading(true);
        const value = await getAxios(`parlamentar/grupo`);
        setData(value.data.grupos);
        setLoading(false);
    };

    const handleClick = (event: any, rowIndex:number) => {
        setAnchorEl(event.currentTarget);
        setIndexSelected(rowIndex);
    };

    const closeMenu = () => {
        setIndexSelected(-1);
        setAnchorEl(null);
    };

    const editarGrupo = async () => {
        setLoading(true)

        const grupo: Grupo = data[indexSelected] as Grupo;
        await formik.setValues(grupo);

        setModalOpen(true);
        closeMenu();
        setLoading(false)
    };

    const excluirGrupo = async () => {
        const id = data[indexSelected]._id;
        setDialogOpen(false)
        setLoading(true)
        await delAxios(`parlamentar/grupo/${id}`);
        buscar();
        setLoading(false)
        closeMenu();
    };

    const columns = [
        {
            name: "nome",
            label: "Nome",
        },
        {
            name: "descricao",
            label: "Descrição",
        },
        {
            name: "acao",
            label: "Ação",
        }
    ];

    const theme = useTheme();

    const options = {
        selectableRows: "none",
        viewColumns: false,
        filter: false,
        responsive: 'vertical',
        customToolbar: () => {
            return <GABButtonIcon
            tip={"Adicionar novo grupo"}
            onClick={()=>{
                closeMenu();
                setModalOpen(true);
            }}
            icon={"akar-icons:plus"}/>
        },
        customRowRender: (data: any, dataIndex: any, rowIndex: any) => {
            let styleLocal: React.CSSProperties = {};
            if (rowIndex % 2 === 0) {
                styleLocal.backgroundColor = alpha(theme.palette.primary.light, 0.20);
            }
            return (
                <TableRow style={styleLocal}>
                    <TableCell>
                        <Typography>{data[0]}</Typography>
                    </TableCell>
                    <TableCell>
                        <Typography>{data[1]}</Typography>
                    </TableCell>
                    <TableCell width={30}>
                        <IconButton onClick={
                            (event: any) => {
                                handleClick(event,dataIndex)
                            }
                        }>
                            <GABIcon
                                icon={"iconamoon:menu-kebab-vertical-bold"}
                            />
                        </IconButton>
                    </TableCell>
                </TableRow>
            )
        }
    } as any;


    const formik = useFormik({
        initialValues: new Grupo(),
        validationSchema: validationSchema,
        onSubmit: async (values) => {
            setLoading(true)
            await postAxios("parlamentar/grupo", values);
            closeMenu();
            setModalOpen(false);
            buscar();
            formik.resetForm();
            setLoading(false)
        }
    });


    return (
        <Fade in timeout={1000}>
            <div>
                <GABDialog
                    title={"Excluir?"}
                    description={`Deseja excluir o grupo: ${data[indexSelected]?.nome || ''}?`}
                    open={dialogOpen}
                    onClickLeft={() => {
                        setDialogOpen(false)
                        closeMenu();
                    }}
                    labelLeft={"Não"}
                    onClickRight={excluirGrupo}
                    labelRight={"Sim"}
                />
                <GABModal
                    title={indexSelected > -1 ? "Edtitar Grupo" : "Novo Grupo"}
                    open={modalOpen}
                    onClickLeft={() => {
                        formik.resetForm();
                        setModalOpen(false)
                        closeMenu();
                    }}
                    labelLeft={"Cancelar"}
                    onClickRight={formik.submitForm}
                    labelRight={"Salvar"}
                >
                    <form onSubmit={formik.handleSubmit} style={{marginTop: "10px"}}>
                        <Grid container spacing={3}>
                            <GabInput
                                label={"Grupo"}
                                id={"nome"}
                                size={12}
                                required={true}
                                value={formik.values.nome}
                                onChange={formik.handleChange}
                                error={formik.touched.nome && Boolean(formik.errors.nome)}
                                helperText={formik.touched.nome && formik.errors.nome}
                            />
                           
                            <GabInput
                                label={"Descrição"}
                                id={"descricao"}
                                size={12}
                                rows={4}
                                formik={formik}
                                value={formik.values.descricao}
                                onChange={formik.handleChange}
                                error={formik.touched.descricao && Boolean(formik.errors.descricao)}
                                helperText={formik.touched.descricao && formik.errors.descricao}
                            />
                        </Grid>
                    </form>
                </GABModal>
                <GABMenu id="basic-menu" anchorEl={anchorEl} handleClose={closeMenu}>
                    <GABMenuItem onClick={() => { setDialogOpen(true) }} label={"Excluir"} icon={"akar-icons:trash-can"} tooltip={"Excluir contato"} />
                    <GABMenuItem onClick={editarGrupo} label={"Editar"} icon={"akar-icons:pencil"} tooltip={"Editar contato"} />
                </GABMenu>
                
                    <GABForm
                        icon={"fluent:task-list-square-person-20-regular"}
                        style={{
                            color: "#919EAB",
                        }}
                        title={"Grupos"}
                        subtile={"lista de grupos para os contatos"}
                    >
                        <Grid item xs={12}>
                            <div style={{ height: "20px" }} />
                            <GABDataTable
                                title={"Grupos"}
                                data={data}
                                columns={columns}
                                print={true}
                                download={true}
                                options={options}
                            />
                        </Grid>
                    </GABForm>
            </div>
        </Fade>
    );
};
export default FormGroups;
