import React, {MouseEventHandler} from "react";
import {Box} from "@mui/material";
import {useTheme} from "@mui/material/styles";
import {Icon} from "@iconify/react";

interface propsCardForm {
    icon:any;
    width?:any;
    height?:any;
    color?:string;
}
const GABIcon: React.FC<propsCardForm> = (props) => {
    const theme = useTheme();
    return (
        <Box component={Icon} icon={props.icon}
             width={props.width}
             height={props.height}
             style={{
                 color: props.color || theme.palette.primary.light
             }}
        ></Box>
    );
};

export default GABIcon;
