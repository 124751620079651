import React, {useContext, useEffect, useRef, useState} from "react";
import Page from "../../../components/Page";
import {Button, ButtonGroup, Divider, Fade, Grid} from "@mui/material";
import GABForm from "../../../components/gab-form";
import {FormikProps, useFormik} from "formik";
import AppProviderContext, {propsAppProviderContext} from "../../../app-provider";
import GABDataTable from "../../../components/gab-data-table";
import GABButtonIcon from "../../../components/gab-button-icon";
import GABModal from "../../../components/gab-modal";
import GABMenu from "../../../components/gab-menu";
import GABMenuItem from "../../../components/gab-menu/item";
import GABDialog from "../../../components/gab-dialog";
import GABInput from "../../../components/gab-input";
import * as Yup from "yup";
import GABIcon from "../../../components/gab-icon";

interface propGABCrudField{
    id:string;
    size:number;
    label:string;
}


interface propsGABCrud{
    service:string;
    tip?:string;
    serviceName:string;
    pageTitle:string;
    title: string;
    subtile: string;
    icon: string;
    columns: any[];
    validationSchema?: any | (() => any);
    idField:string;
    fields?: propGABCrudField[];
    noAction?: boolean;
}
const GABCrud: React.FC<propsGABCrud> = (props) => {
    const [modalOpen, setModalOpen] = useState(false);
    const [modalOpenRemove, setModalOpenRemove] = useState(false);
    const [openMenu, setOpenMenu] = React.useState(false);
    const [indexSelected, setIndexSelected] = useState(-1);

    const [data, setData] = useState([]);
    const {
        getAxios,
        delAxios,
        setLoading,
        postAxios,
        putAxios,
    }: propsAppProviderContext = useContext(AppProviderContext);

    const formik = useFormik({
        initialValues: {} ,
        validationSchema :props.validationSchema,
        onSubmit: async (values:any) => {
            setLoading(true);
            if(values?._id && values?._id !== undefined && values?._id !== ''){
                await putAxios(props.service, values)
            }else{
                await postAxios(props.service, values)
            }
            setLoading(false);
        },
    });
    const closeMenu = () => {
        setIndexSelected(-1);
        setOpenMenu(false);
    };

    const remove = async ()=>{
        setData([]);
        setModalOpenRemove(false)
        closeMenu();
        setLoading(true)
        const item = data[indexSelected];
        try{
            await delAxios(`${props.service}/${item[props.idField]}`);
        }catch (e){
            console.error('error ', e)
        }
        await init();
    }

    const edit = async ()=>{
        closeMenu();
        if(formik){
            formik.setValues(data[indexSelected]);
            await setModalOpen(true)
            await init();
        }

    }

    const init = async ()=>{
        setLoading(true)
        const response = await getAxios(props.service)
        if(props.serviceName===''){
            setData(response.data);
        }else{
            setData(response.data[props.serviceName]);
        }

        setLoading(false)
    }
    useEffect(
        ()=>{
            init();
        },[]
    )
    const handleClick = (event: any, rowIndex: number) => {
        setOpenMenu(true);
        setIndexSelected(rowIndex);
    };
    const getFields = ()=>{
        return props.fields?.map(
            (f:propGABCrudField)=><GABInput
                id={f.id}
                formik={formik}
                size={f.size}
                label={f.label}/>
        )
    }

    return (
        <Fade in timeout={1000}>
            <div>
            <Page title={props.pageTitle}>
                <form onSubmit={formik.handleSubmit}>
                        <GABForm
                            icon={props.icon}
                            title={props.title}
                            subtile={props.subtile}
                        >
                                <GABDataTable
                                    search={true}
                                    onClickMenu={handleClick}
                                    print={true}
                                    download={true}
                                    data={data}
                                    noAction={props.noAction}
                                    buttons={
                                        !props.noAction ? <>
                                            <GABButtonIcon
                                                onClick={
                                                    () => {
                                                        setModalOpen(true)
                                                    }
                                                }
                                                tip={props?.tip}
                                                icon={"akar-icons:plus"}/>
                                        </> : null
                                    }
                                    title={props.title}
                                    columns={props.columns}/>

                        </GABForm>

                <GABModal
                    title={props.title}
                    open={modalOpen}
                    onClickLeft={() => {
                        formik?.resetForm();
                        setModalOpen(false)
                    }}
                    labelLeft={"Cancelar"}
                    onClickRight={
                        () => {
                            if (formik?.isValid) {
                                formik?.submitForm();
                                setModalOpen(false);
                                setTimeout(
                                    ()=>{
                                        init()
                                    },500
                                )
                            }
                        }

                    }
                    labelRight={"Salvar"}
                >
                    <Grid container spacing={2}>
                        {
                            getFields()

                        }
                    </Grid>



                </GABModal>
                </form>


                <GABDialog
                    title={"Excluir?"}
                    description={`Deseja excluir o registro? `}
                    open={modalOpenRemove}
                    onClickLeft={() => {
                        setModalOpenRemove(false)
                        closeMenu();
                    }}
                    labelLeft={"Não"}
                    onClickRight={remove}
                    labelRight={"Sim"}
                />

                <GABModal open={openMenu} title={'Ações'} onClose={
                    ()=>{
                        setOpenMenu(false)
                    }
                }>
                    <ButtonGroup

                        size="large"
                        disableElevation
                        fullWidth
                        orientation="vertical"
                        variant="contained"
                    >
                        <Button
                            onClick={edit}
                            variant="contained"
                                color={'secondary'}
                                startIcon={<GABIcon color={'white'}
                                                    icon={'akar-icons:pencil'} />}>
                            Editar
                        </Button>

                        <Button variant="contained"
                                onClick={()=>{setModalOpenRemove(true)}}
                                color={'error'}
                                startIcon={<GABIcon
                                    color={'white'}
                                    icon={'akar-icons:trash-can'} />}>
                            Excluir
                        </Button>
                        <Divider style={{height : '1rem'}}/>
                        <Button variant="outlined" onClick={()=>{setOpenMenu(false)}}>
                            Cancelar
                        </Button>

                    </ButtonGroup>
                </GABModal>
                </Page>
            </div>
        </Fade>
    )
}

export default GABCrud;


