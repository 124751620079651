import React, {useContext, useState} from "react";
import GABForm from "../../../../components/gab-form";
import {FormikProps, useFormik} from "formik";
import AppProviderContext, {propsAppProviderContext} from "../../../../app-provider";
import GABMenu from "../../../../components/gab-menu";
import GABMenuItem from "../../../../components/gab-menu/item";
import GABDataTable from "../../../../components/gab-data-table";
import GABButtonIcon from "../../../../components/gab-button-icon";
import {alpha, useTheme} from "@mui/material/styles";
import {Grid, IconButton, TableCell, TableRow} from "@mui/material";
import GABIcon from "../../../../components/gab-icon";
import GABModal from "../../../../components/gab-modal";
import GABInput from "../../../../components/gab-input";
import GabInput from "../../../../components/gab-input";
import {Atividade} from "../../dto/demanda";


interface propsGABFormDemandaActivity {
    formik:FormikProps<any>;
}
const GABFormDemandaActivity: React.FC<propsGABFormDemandaActivity> = (props) => {

    const [anchorAtividade, setAnchorAtividade] = React.useState(null);
    const [modalAtividadeOpen, setModalAtividadeOpen] = useState(false);
    const [atividadeSelected, setAtividadeSelected] = React.useState(-1);
    const {
        profile
    }: propsAppProviderContext = useContext(AppProviderContext);
    const removeAtividade = () => {
        const newList = props.formik.values.atividades.filter((atividade:Atividade, index:number) => index !== atividadeSelected);
        props.formik.setFieldValue('atividades', newList);
        setAtividadeSelected(-1);
        setAnchorAtividade(null)
        return newList;
    }
    const editarAtividade = () => {
        const newList = props.formik.values.atividades.filter((atividade:Atividade, index:number) => index === atividadeSelected);
        formikAtividade.setValues(newList[0]);
        setAnchorAtividade(null);
        setModalAtividadeOpen(true);
    }
    const theme = useTheme();
    const customRenderAtividades = (data: any, dataIndex: any, rowIndex: any) => {
        let styleLocal: React.CSSProperties = {};
        if (rowIndex % 2 === 0) {
            styleLocal.backgroundColor = alpha(theme.palette.primary.light, 0.20);
        }
        return (
            <TableRow style={styleLocal}>
                <TableCell align={"left"} valign={"top"}>
                    {data[0]}
                </TableCell>
                <TableCell align={"left"} valign={"top"}>
                    {data[1]}
                </TableCell>
                <TableCell align={"justify"} valign={"top"}>
                    {data[2]}
                </TableCell>
                <TableCell align={"justify"} valign={"top"}>
                    {data[1] ? data[3] : ''}
                </TableCell>
                <TableCell width={30}>
                    <IconButton onClick={
                        (event: any) => {
                            setAnchorAtividade(event.currentTarget)
                            setAtividadeSelected(dataIndex)
                        }
                    }>
                        <GABIcon
                            icon={"iconamoon:menu-kebab-vertical-bold"}
                        />
                    </IconButton>
                </TableCell>
            </TableRow>
        )
    };

    const formikAtividade = useFormik({
        initialValues: {} as Atividade,
        onSubmit: async (values) => {
            setModalAtividadeOpen(false);
            let list = props.formik.values.atividades || [];
            if (atividadeSelected > -1) {
                list = removeAtividade();
            }

            list.push({
                responsavel_nome: profile.nome,
                responsavel_email: profile.email,
                descricao: formikAtividade.values.descricao,
                data: formikAtividade.values.data,
            })
            await props.formik.setFieldValue('atividades', list);
            formikAtividade.resetForm();
        }
    });


    return (
     <>
         <GABForm
             icon={"material-symbols:task-alt"}
             title={"Atividades"}
             subtile={`Registro de atividades do usuário`}
         >

             <GABMenu id="basic-menu" anchorEl={anchorAtividade} handleClose={() => {
                 setAnchorAtividade(null);
             }}>
                 <GABMenuItem onClick={removeAtividade} label={"Excluir"} icon={"akar-icons:trash-can"}
                              tooltip={"Excluir Atividade"}/>
                 <GABMenuItem onClick={editarAtividade} label={"Editar"} icon={"akar-icons:pencil"}
                              tooltip={"Editar Atividade"}/>
             </GABMenu>


             <GABDataTable
                 title={"Atividades"}
                 customRowRender={customRenderAtividades}
                 data={props.formik.values.atividades}
                 buttons={
                     <>
                         <GABButtonIcon
                             onClick={
                                 () => {
                                     setModalAtividadeOpen(true)
                                 }
                             }
                             tip={'Adicionar Atividade'}
                             icon={"ic:round-add-task"}/>
                     </>
                 }
                 columns={[
                     {
                         name: "responsavel_nome",
                         label: "Nome",
                     },
                     {
                         name: "responsavel_email",
                         label: "Email",

                     },
                     {
                         name: "data",
                         label: "Data",
                     },
                     {
                         name: "descricao",
                         label: "Descrição",
                     },
                     {
                         name: "acao",
                         label: "Ação",
                     },
                 ]}
             />

             <GABModal
                 title={'Cadastrar Atividade'}
                 open={modalAtividadeOpen}
                 onClickLeft={() => {
                     formikAtividade.resetForm();
                     setModalAtividadeOpen(false)
                 }}
                 labelLeft={"Cancelar"}
                 onClickRight={
                     () => {
                         if (formikAtividade.isValid) {
                             formikAtividade.submitForm();
                         }
                     }

                 }
                 labelRight={"Salvar"}
             >
                 <form onSubmit={formikAtividade.handleSubmit}>
                     <Grid container spacing={3}>

                         <GABInput
                             label={"Data"}
                             id={"data"}
                             formik={formikAtividade}
                             datePick/>
                         <GabInput
                             label={"Tarefa"}
                             id={"descricao"}
                             formik={formikAtividade}
                             rows={4}
                         />


                     </Grid>
                 </form>
             </GABModal>


         </GABForm>
     </>
  );
};

export default GABFormDemandaActivity;
