import * as React from 'react';
import {Draggable} from 'react-beautiful-dnd';

import {Demanda, Responsavel} from './typings';
import {
    Avatar,
    Button,
    Card, CardActions,
    Tooltip, Typography
} from "@mui/material";

import './gab-kanban-card.css'
import "./gab-draggable-card.css"
import GABIcon from "../../../components/gab-icon";
import {useState} from "react";
import DemandaDetail from "../detail";
export type DraggableListItemProps = {
    item: Demanda;
    index: number;
};

const GABDraggableCard = ({item, index}: DraggableListItemProps) => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [demandasSelected, setDemandasSelected] = useState('')
    const [dialogDetailOpen, setDialogDetailOpen] = useState(false);

    const closeMenu = () => {
        setAnchorEl(null);
    };
    const getInitials = (nome:string) => {
        let listSplit = nome.split(" ");
        if (listSplit.length > 1) {
            return listSplit[0].substring(0, 1) + listSplit[1].substring(0, 1);
        }
        return nome.substring(0, 2);
    };

    const detail = ()=> {
        setDemandasSelected(item._id);
        setDialogDetailOpen(true)
        closeMenu();
    }
    const getAvatar = (responsaveis?:Responsavel) => {
      const name =responsaveis? responsaveis.label  :  "-";
      return <Typography variant={"subtitle2"}>{getInitials(name)}</Typography>;
    }

    const handleClick = (event: any) => {
        setAnchorEl(event.currentTarget);
    };

    const getDaysOfStringDate = (strDate:any) => {
        const today = new Date().getTime();
        const limit = new Date(strDate.substr(6, 4), parseInt(strDate.substr(3, 2)) - 1, strDate.substr(0, 2)).getTime();
        return (limit - today) / (1000 * 60 * 60 * 24);

    }
    const isLate = (item:Demanda) =>{
        const days = item.prazoData ? getDaysOfStringDate(item.prazoData) : 0;
        return  days<0;
    }
    const getDays = (item:Demanda) =>{
        const days =  item.prazoData ? getDaysOfStringDate(item.prazoData) : 0;
        return  parseInt(Math.abs(days).toFixed(0));
    }
    const getClassNamePriority = (item:Demanda)=>{
        let priority ='';
        switch (item.prioridade) {
            case "Alta":
                priority = 'alta';
                break;
            case "Baixa":
                priority = 'baixa';
                break;
            case "Média":
                priority = 'media';
                break;
        }
        return `${priority}`;
    }

    const getTime = ()=>{
        const max = 5;
        let counter = -1;
        return <div style={{
            display: "flex",
            flexDirection :'row',
            paddingTop : '0.2rem',
            justifyContent : 'end'
        }}>
            {
                item?.responsaveis?.map(
                    (responsavel)=>{
                        counter++;
                        if(counter>=max){
                            return ;
                        }
                        return  <Tooltip title={responsavel.label} placement="left-start">
                            <Avatar
                                sx={{ width: 24, height: 24 }}
                                className={`avatar`}>
                                {
                                    getAvatar(responsavel)
                                }
                            </Avatar>
                        </Tooltip>
                    }
                )
            }{
            counter>=max? <div><GABIcon icon={"akar-icons:plus"}></GABIcon></div> : <div></div>
        }
        </div>

    }

    return (
        <Draggable draggableId={item._id} index={index} key={item._id}>
            {(provided, snapshot) => (
                <Card
                    className={`itemCard ${getClassNamePriority(item)}`}
                    elevation={0} ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                >
                    <div className={`cardTitleContainer ${getClassNamePriority(item)}`}>
                        <Typography className={"titleCard"} >
                            {item.protocolo.toString().split("-")[1]}
                        </Typography>
                    </div>

                    <div className={`cardContainer`}>
                        <Typography className={"subTitleCard caixas"}>
                            Solicitante :
                        </Typography>
                        <Typography className={"subTitleCard nome"}>
                            {item.eleitor || item.titulo || ''}
                        </Typography>

                        {
                            getTime()
                        }
                        <Typography className={"subTitleCard caixas"}>
                            {item.categoria?.nome  || ''}
                        </Typography>
                        <Typography className={"subTitleCard caixas"}>
                            {item.caixa[0]?.nome  || ''}
                        </Typography>
                    </div>


                    <CardActions>
                        <Button size="small" variant={"outlined"} onClick={detail}>Detalhes</Button>
                      <div className={`actionDays ${isLate(item)? 'late': 'no-late'}`}>
                          Prazo {getDays(item)>99? "99+":getDays(item)}
                      </div>
                    </CardActions>

                    <DemandaDetail
                        idDemanda={demandasSelected}
                        isOpen={dialogDetailOpen}
                        onClose={
                            ()=>{
                                setDialogDetailOpen(false)
                            }
                        }/>
                </Card>
            )}
        </Draggable>
    );
};

export default GABDraggableCard;
