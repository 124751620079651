import React, { useContext, useEffect, useState } from "react";
import Page from "../../components/Page";
import { Button, Fade, Grid } from "@mui/material";
import GABForm from "../../components/gab-form";
import GabInput from "../../components/gab-input/index";
import AppProviderContext, {
  propsAppProviderContext
} from "../../app-provider";

const RemoverCadastro: React.FC = () => {
  const {
    setLoading,
    postAxios,
    addMessage
  }: propsAppProviderContext = useContext(AppProviderContext);

  const enviar = async () => {
    setLoading(true);
    await postAxios("auth/remover", {});
    setLoading(false);
    addMessage("Um email de confirmação foi enviado para dar cotinuidade ao processo", "success");
  };

  return (
    <Fade in timeout={1000}>
      <div>
        <Page title={`Remover cadastro`}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <GABForm
                icon={"material-symbols:dangerous-outline-rounded"}
                title={`REMOVER CADASTRO`}
                subtile={"Remover conta e excluir dados"}
              >
                <Grid item xs={12}>
                  <p>
                    Este é um processo de desvinculação completo. <br />
                    <br />
                    Ao prosseguir todo o sistema e os dados vinculados a ele serão permanentemente removidos. <br/>
                    <br />
                    <span style={{marginLeft: "20px", display: "block"}}><ul>
                      <li>Dados do gabinete / parlamentar</li>
                      <li>Dados dos usuários</li>
                      <li>Dados dos contatos</li>
                      <li>Dados das demandas</li>
                    </ul></span>
                    <br/>
                    <br/>
                    Se está ciente e deseja continuar com o processo, clique no botão abaixo e um email de segurança será enviado para que confirme o procedimento.
                    <br /><br />
                  </p>
                </Grid>
                <Grid item xs={4}></Grid>
                <Grid item xs={4}><Button
                variant="contained"
                color="error"
                type={"button"}
                onClick={enviar}
              >
                EXCLUSÃO COMPLETA
              </Button></Grid>
              </GABForm>
            </Grid>
          </Grid>
        </Page>
      </div>
    </Fade>
  );
};

export default RemoverCadastro;
