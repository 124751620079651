import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {HelmetProvider} from "react-helmet-async";
import {BrowserRouter} from "react-router-dom";
import {SnackbarProvider} from "notistack";
import "react-quill/dist/quill.snow.css";

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
        <HelmetProvider>
            <BrowserRouter>
                <SnackbarProvider maxSnack={3} autoHideDuration={1500}>
                    <App />
                </SnackbarProvider>
            </BrowserRouter>
        </HelmetProvider>
);
reportWebVitals();
