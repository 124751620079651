import React, { CSSProperties } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  DialogProps
} from "@mui/material";

interface propsCardForm {
  open: boolean;
  onClickLeft?: () => void;
  onClickRight?: () => void;
  onClose?: () => void;
  title: string;
  description: string;
  labelLeft?: string;
  labelRight?: string;
  children?: React.ReactNode;
  maxWidth?: DialogProps['maxWidth'];
  disabled?: boolean;
}

const GABDialog: React.FC<propsCardForm> = props => {
  return (
    <Dialog
      open={props.open}
      onClose={props.onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth={props?.maxWidth}
      fullWidth
    >
      <DialogTitle id="alert-dialog-title">{props.title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {props.description}
          {props.children}
        </DialogContentText>
      </DialogContent>
      {!props?.disabled ? <DialogActions>
        {props.onClickLeft ? (
          <Button
            onClick={props.onClickLeft}
            variant="contained"
            color={"secondary"}
          >
            {props.labelLeft || "Não"}
          </Button>
        ) : (
          <div />
        )}

        {props.onClickRight ? (
          <Button
            onClick={props.onClickRight}
            variant="contained"
            color={"primary"}
            autoFocus
          >
            {props.labelRight || "Sim"}{" "}
          </Button>
        ) : (
          <div />
        )}
      </DialogActions>:null}
    </Dialog>
  );
};

export default GABDialog;
