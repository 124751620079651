import {
    Button,
    Card,
    Fade,
    Grid, LinearProgress,
} from "@mui/material";
import React, {useContext, useEffect, useState} from "react";
import GABForm from "../../components/gab-form";
import AppProviderContext, {propsAppProviderContext} from "../../app-provider";
import GABLabel from "../../components/gab-label";
import {useTheme} from "@mui/material/styles";


export interface propsMessageConfig {
    client: string
}

const MessageConfig: React.FC<propsMessageConfig> = (props) => {
    const REPEAT_AFETER = 15000;
    const {getAxios, setLoading, delAxios}: propsAppProviderContext = useContext(AppProviderContext);
    const [response, setResponse] = useState({message: '', number: '', connected: false});
    const [status, setStatus] = useState(false);
    const chechStatus = async () => {
        const resp = await getAxios(`${process.env.REACT_APP_BASE_WHATSAPP}/gv/whatsapp/status/${props.client}`);
        //const resp = await getAxios(`parlamentar/whatsapp/status`);
        setStatus(resp.data);
        if (resp.data !== true) {
            await getQrCode();
        }
    }

    const deleteMe = async () => {
        try {
            setLoading(true);
            const resp = await delAxios(`${process.env.REACT_APP_BASE_WHATSAPP}/gv/whatsapp/client/${props.client}`);
            setTimeout(
                ()=>{
                    setLoading(false);
                },REPEAT_AFETER
            );
        } catch (e) {
            setLoading(false);
        }
        setTimeout(()=>{
            chechStatus();
        }, REPEAT_AFETER)
    }
    const getQrCode = async () => {
        try {
            const resp = await getAxios(`${process.env.REACT_APP_BASE_WHATSAPP}/gv/whatsapp/qrcode/${props.client}`);
            if (resp.data !== response) {
                setResponse(resp.data)
                if(resp.data.connected === true){
                    return;
                }
            }
        } catch (e) {

        }
        setTimeout(()=>{
            chechStatus();
        }, REPEAT_AFETER)
    }
    const init = async () => {
        if (props.client) {
            await chechStatus();
        }
    }

    useEffect(() => {
        init()
    }, [props.client]);

    const theme = useTheme();
    return (
        <Fade in timeout={1000}>
            <div>
                <GABForm
                    icon={"ic:baseline-qrcode"}
                    title={"Conectar"}
                    subtile={`É necessário se conectar via whatsapp web`}
                >
                    <Grid item xs={4}>
                        <Card
                            elevation={20}
                            style={{
                                backgroundColor: `${theme.palette.background.default}`,
                                padding: '1rem'
                            }}>
                            {
                                status ?
                                    <div style={{
                                        minHeight: '20rem',
                                        paddingTop: '5rem'
                                    }}>
                                        <GABLabel size={12} textAlign={'center'} titleVariant={'h6'}
                                                  title={'CONECTADO'}/>

                                        <div style={{textAlign :'center', padding :'1rem'}}>
                                            <Button variant={"contained"} onClick={deleteMe}>Desconectar</Button>
                                        </div>
                                    </div>
                                    :
                                    response?.message ? <img src={response?.message} width={'100%'}/> :
                                        <Grid container
                                              style={{
                                                  minHeight: '20rem',
                                                  paddingTop: '5rem'

                                              }}>
                                            <Grid item xs={12}>
                                                <LinearProgress/>
                                            </Grid>
                                            <GABLabel size={12} textAlign={'center'} titleVariant={'h6'}
                                                      title={'Aguarde'}/>
                                        </Grid>
                            }

                        </Card>
                    </Grid>

                    <div>
                        <GABLabel size={6} title={'Numero'} subTitle={response?.number || props.client}/>
                        <GABLabel size={6} title={'Conectado'} subTitle={status ? 'Sim' : 'Não'}/>
                    </div>
                </GABForm>

            </div>
        </Fade>
    );
};
export default MessageConfig;
