import React, { useContext, useEffect, useState } from "react";
import Page from "../../components/Page";
import {
  Button,
  ButtonGroup,
  CardActions,
  Divider,
  Fade,
  Grid,
  IconButton,
  InputAdornment,
  TableCell,
  TableRow,
  Typography,
  alpha,
  useTheme,
} from "@mui/material";
import { green } from "@mui/material/colors";
import GABForm from "../../components/gab-form";
import GabInput from "../../components/gab-input/index";
import { cpf } from "cpf-cnpj-validator";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  listaAcessos,
  listaEscolaridade,
  listaEstadoCivil,
  listaEstados,
  listaGeneros,
  listaSelecione,
  listaTratamentos,
} from "../../utils/select-utils.domain";
import AppProviderContext, {
  propsAppProviderContext,
} from "../../app-provider";
import User, { Telefone } from "./user";
import axios from "axios";
import MUIDataTable from "mui-datatables";
import { useNavigate, useParams } from "react-router-dom";
import GABTextEditor from "../../components/gab-text-editor";
import { isValidDate } from "../../utils/str-utils";
import Cargo from "../cargo/cargo";
import GABAutocomplete from "../../components/gab-autocomplete";
import GABFormButtons from "../../components/gab-form-buttons";
import GABSwitch from "../../components/gab-switch";
import GABIcon from "../../components/gab-icon";
import GABMenu from "../../components/gab-menu";
import GABMenuItem from "../../components/gab-menu/item";

interface propsNewContact {
  onSubmit?: (values: any) => void;
  initialValue: User;
  toDisabled?: string[];
  disabled?: boolean;
}

const FormUser: React.FC<propsNewContact> = (props) => {
  let { idUser } = useParams();
  const [cargos, setCargos] = useState<Cargo[]>([]);

  const validationSchema = Yup.object().shape({
    nome: Yup.string().trim().required("informe o nome"),
    email: Yup.string()
      .trim()
      .email("Email Inválido")
      .required("informe o email")
      .test(
        "checkDuplEmail",
        "Este email já está cadastrado no sistema Gabinete Virtual",
        async function (value) {
          if(isDisabled("email")){
            return true;
          }

          const e = await getAxios(`auth/existe/${value}`);

          if (e.data.existe) {
            return false;
          }

          return true;
        }
      ),
    cpfCnpj: Yup.string()
      .trim()
      .test("test-invalid-cpf", "CPF inválido", (e) => {
        let valid = !e || e === "" || cpf.isValid(e ? e : "");
        return valid;
      }),
    nascimento: Yup.string()
      .trim()
      .test("test-invalid-date", "Data inválida", (e) => {
        return !e || e === "" || isValidDate(e);
      }),
  });

  const {
    setLoading,
    isLoading,
    postAxios,
    addMessage,
    setEditContato,
    editContato,
    getAxios,
  }: propsAppProviderContext = useContext(AppProviderContext);

  const navigator = useNavigate();

  const formik = useFormik({
    initialValues: props.initialValue,
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      try {
        setLoading(true);
        let clone: any = Object.assign({}, values);
        if (clone._id === undefined || clone._id === "") {
          delete clone._id;
        }

        await postAxios("auth/usuario", clone);
        addMessage("Usuário salvo com sucesso!", "success");
        navigator("/admin/config");
      } catch (e: any) {
        addMessage("Não foi possível salvar o usuário", "error");
      }
      setLoading(false);
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    },
  });

  const formikTelefones = useFormik({
    initialValues: Telefone.EmptyPhone(),
    onSubmit: async (values) => {
      setLoading(true);
      formik.values.listaTelefone.push(values);
      formik.values.telefones.push(values.fone);
      formikTelefones.resetForm();
      setLoading(false);
    },
  });

  const isDisabled = (id: string): boolean => {
    if (editContato) {
      if (["email"].includes(id)) {
        return true;
      }
    }

    if (props?.disabled === true) {
      return true;
    }

    const tod = props.toDisabled?.filter((f) => f === id);

    if (tod && tod?.length > 0) {
      return true;
    }
    return isLoading;
  };

  const [anchorEl, setAnchorEl] = React.useState(null);

  const [indexSelected, setIndexSelected] = useState(-1);
  const handleClick = (event: any, rowIndex: number) => {
    setAnchorEl(event.currentTarget);
    setIndexSelected(rowIndex);
  };
  const closeMenu = () => {
    setIndexSelected(-1);
    setAnchorEl(null);
  };

  const removeTel = async (telIndex: any) => {
    if (telIndex !== -1) {
      let contato = formik.values;
      contato.listaTelefone.splice(telIndex, 1);
      await formik.setValues(contato);
    }
  };

  const theme = useTheme();

  const isWpp = async (telIndex: any) => {
    setLoading(true);
    if (telIndex !== -1) {
      let contato = formik.values;
      contato.listaTelefone[telIndex].isWpp =
        !contato.listaTelefone[telIndex].isWpp;
      await formik.setValues(contato);
    }
    setLoading(false);
  };

  const findCep = async (e: any) => {
    const cepStr = formik.values.endereco.cep.replace(/\D/g, "");
    if (cepStr && cepStr.length === 8) {
      setLoading(true);
      const endereco: any = await axios.get(
        `https://viacep.com.br/ws/${cepStr}/json/`
      );
      if (endereco?.data) {
        const data = endereco?.data;
        if (data) {
          formik.setFieldValue("endereco.bairro", data?.bairro || "");
          formik.setFieldValue("endereco.logradouro", data?.logradouro || "");
          formik.setFieldValue("endereco.cidade", data?.localidade || "");
          formik.setFieldValue("endereco.uf", data?.uf || "");
        }
      }
    }
    setLoading(false);
  };

  const init = async () => {
    if (idUser) {
      setLoading(true);
      setEditContato(true);
      const response = await getAxios(`auth/usuario/${idUser}`);

      if (!response.data.listaTelefone) {
        response.data.listaTelefone = response.data.telefones.map(
          (e: string) => {
            return { fone: e, nome: "", obs: "" };
          }
        );
      }

      const user: User = response.data as User;
      await formik.setValues(user);
      setLoading(false);
    } else {
      setEditContato(false);
    }
  };

  const carregaCargos = async () => {
    setLoading(true);
    const value = await getAxios(`parlamentar/cargo/`);
    setCargos([...listaSelecione, ...value.data.cargos]);
    setLoading(false);
  };

  useEffect(() => {
    init();
    carregaCargos();
  }, []);

  const columns = [
    {
      name: "isWpp",
      label: " ",
    },
    {
      name: "nome",
      label: "Nome",
    },
    {
      name: "fone",
      label: "Fone",
    },
    {
      name: "obs",
      label: "obs",
    },
    {
      name: "Ação",
      options: {
        filter: false,
        sort: false,
        empty: true,
      },
    },
  ];

  const options = {
    filter: false,
    selectableRows: "none",
    search: true,
    print: false,
    download: false,
    viewColumns: false,
    responsive: "vertical",
    customRowRender: (data: any, dataIndex: any, rowIndex: any) => {
      let styleLocal: React.CSSProperties = {};
      if (rowIndex % 2 === 0) {
        styleLocal.backgroundColor = alpha(theme.palette.primary.light, 0.2);
      }
      return (
        <TableRow style={styleLocal}>
          <TableCell width={30}>
            {data[0] ? (
              <GABIcon width={20} height={20} icon={"logos:whatsapp-icon"} />
            ) : null}
          </TableCell>
          <TableCell>
            <Typography>{data[1]}</Typography>
          </TableCell>
          <TableCell>
            <Typography>{data[2]}</Typography>
          </TableCell>
          <TableCell>
            <Typography>{data[3]}</Typography>
          </TableCell>
          <TableCell width={30}>
            <IconButton
              onClick={(event: any) => {
                handleClick(event, dataIndex);
              }}
            >
              <GABIcon icon={"iconamoon:menu-kebab-vertical-bold"} />
            </IconButton>
          </TableCell>
        </TableRow>
      );
    },
  } as any;

  return (
    <Fade in timeout={1000}>
      <div>
        <form onSubmit={formik.handleSubmit}>
          <Page title="Novo Usuário">
            <GABMenu
              id="basic-menu"
              anchorEl={anchorEl}
              handleClose={closeMenu}
            >
              <GABMenuItem
                onClick={() => {
                  removeTel(indexSelected);
                }}
                label={"Excluir"}
                icon={"akar-icons:trash-can"}
                tooltip={"Excluir telefone"}
              />
              <GABMenuItem
                onClick={() => {
                  isWpp(indexSelected);
                }}
                label={"WhatsApp"}
                icon={"ri:whatsapp-line"}
                tooltip={"Marcar/Desmarcar como WhatsApp"}
              />
            </GABMenu>
            <section id={"dados_pessoais"}>
              <GABForm
                icon={"twemoji:identification-card"}
                title={"Dados Pessoais"}
                subtile={"informe os dados pessoais"}
              >
                <GabInput
                  label={"Tratamento"}
                  id={"tratamento"}
                  size={3}
                  disabled={isDisabled("tratamento")}
                  value={formik.values.tratamento}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.tratamento &&
                    Boolean(formik.errors.tratamento)
                  }
                  helperText={
                    formik.touched.tratamento && formik.errors.tratamento
                  }
                >
                  {listaTratamentos.map((option) => (
                    <option key={option?.value} value={option?.value}>
                      {option?.label}
                    </option>
                  ))}
                </GabInput>

                <GabInput
                  label={"Nome completo"}
                  id={"nome"}
                  size={5}
                  disabled={isDisabled("nome")}
                  required={true}
                  value={formik.values.nome}
                  onChange={formik.handleChange}
                  error={formik.touched.nome && Boolean(formik.errors.nome)}
                  helperText={formik.touched.nome && formik.errors.nome}
                />

                <GabInput
                  label={"Nome Social"}
                  id={"nomeSocial"}
                  size={4}
                  disabled={isDisabled("nomeSocial")}
                  value={formik.values.nomeSocial}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.nomeSocial &&
                    Boolean(formik.errors.nomeSocial)
                  }
                  helperText={
                    formik.touched.nomeSocial && formik.errors.nomeSocial
                  }
                />

                <GabInput
                  label={"Nascimento"}
                  id={"nascimento"}
                  size={3}
                  disabled={isDisabled("nascimento")}
                  value={formik.values.nascimento}
                  mask={"date"}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.nascimento &&
                    Boolean(formik.errors.nascimento)
                  }
                  helperText={
                    formik.touched.nascimento && formik.errors.nascimento
                  }
                />
                <GabInput
                  label={"sexo"}
                  id={"sexo"}
                  size={3}
                  disabled={isDisabled("sexo")}
                  value={formik.values.sexo}
                  onChange={formik.handleChange}
                  error={formik.touched.sexo && Boolean(formik.errors.sexo)}
                  helperText={formik.touched.sexo && formik.errors.sexo}
                >
                  {listaGeneros.map((option) => (
                    <option key={option?.value} value={option?.value}>
                      {option?.label}
                    </option>
                  ))}
                </GabInput>

                <GabInput
                  label={"CPF"}
                  id={"cpfCnpj"}
                  size={3}
                  mask={"cpf"}
                  disabled={isDisabled("cpfCnpj")}
                  value={formik.values.cpfCnpj}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.cpfCnpj && Boolean(formik.errors.cpfCnpj)
                  }
                  helperText={formik.touched.cpfCnpj && formik.errors.cpfCnpj}
                />
                <GabInput
                  label={"RG"}
                  id={"rg"}
                  size={3}
                  disabled={isDisabled("rg")}
                  value={formik.values.rg}
                  onChange={formik.handleChange}
                  error={formik.touched.rg && Boolean(formik.errors.rg)}
                  helperText={formik.touched.rg && formik.errors.rg}
                />

                <GabInput
                  label={"Estado Civil"}
                  id={"estadoCivil"}
                  size={3}
                  disabled={isDisabled("estadoCivil")}
                  value={formik.values.estadoCivil}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.estadoCivil &&
                    Boolean(formik.errors.estadoCivil)
                  }
                  helperText={
                    formik.touched.estadoCivil && formik.errors.estadoCivil
                  }
                >
                  {listaEstadoCivil.map((option) => (
                    <option key={option?.value} value={option?.value}>
                      {option?.label}
                    </option>
                  ))}
                </GabInput>

                <GabInput
                  label={"Escolaridade"}
                  id={"escolaridade"}
                  size={5}
                  disabled={isDisabled("escolaridade")}
                  value={formik.values.escolaridade}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.escolaridade &&
                    Boolean(formik.errors.escolaridade)
                  }
                  helperText={
                    formik.touched.escolaridade && formik.errors.escolaridade
                  }
                >
                  {listaEscolaridade.map((option) => (
                    <option key={option?.value} value={option?.value}>
                      {option?.label}
                    </option>
                  ))}
                </GabInput>
                <GabInput
                  label={"Cargo"}
                  id={"cargo"}
                  size={4}
                  disabled={isDisabled("cargo")}
                  value={formik.values.cargo}
                  onChange={formik.handleChange}
                  error={formik.touched.cargo && Boolean(formik.errors.cargo)}
                  helperText={formik.touched.cargo && formik.errors.cargo}
                >
                  {cargos.map((option) => (
                    <option key={option?.nome} value={option?.nome}>
                      {option?.nome}
                    </option>
                  ))}
                </GabInput>
              </GABForm>
              <div style={{ height: "10px" }} />
            </section>
            <section id={"dados_user"}>
              <GABForm
                icon={"flat-color-icons:contacts"}
                title={"Contato"}
                subtile={"informe dados de user"}
              >
                <GabInput
                  label={"email"}
                  id={"email"}
                  size={12}
                  required
                  disabled={isDisabled("email")}
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.email && Boolean(formik.errors.email)}
                  helperText={formik.touched.email && formik.errors.email}
                  inputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Typography variant={"h6"}>@</Typography>
                      </InputAdornment>
                    ),
                  }}
                />
                <div
                  style={{
                    width: "100%",
                    marginTop: "1rem",
                  }}
                >
                  <Divider style={{ height: "0.5rem" }} />
                </div>

                <GabInput
                  label={"Nome"}
                  id={"nome"}
                  size={3}
                  value={formikTelefones.values.nome}
                  onChange={formikTelefones.handleChange}
                  error={
                    formikTelefones.touched.nome &&
                    Boolean(formikTelefones.errors.nome)
                  }
                  helperText={
                    formikTelefones.touched.nome && formikTelefones.errors.nome
                  }
                />
                <GabInput
                  label={"Telefone"}
                  id={"fone"}
                  size={3}
                  mask={"phone"}
                  value={formikTelefones.values.fone}
                  onChange={formikTelefones.handleChange}
                  error={
                    formikTelefones.touched.fone &&
                    Boolean(formikTelefones.errors.fone)
                  }
                  helperText={
                    formikTelefones.touched.fone && formikTelefones.errors.fone
                  }
                />
                <GabInput
                  label={"obs"}
                  id={"obs"}
                  size={3}
                  value={formikTelefones.values.obs}
                  onChange={formikTelefones.handleChange}
                  error={
                    formikTelefones.touched.obs &&
                    Boolean(formikTelefones.errors.obs)
                  }
                  helperText={
                    formikTelefones.touched.obs && formikTelefones.errors.obs
                  }
                />

                <Grid item xs={3}>
                  <Grid container spacing={0}>
                    <Grid item xs={6}>
                      <div style={{ paddingLeft: "12px" }}>
                        <GABSwitch
                          label={"WhatsApp"}
                          id="isWpp"
                          onChange={(event) => {
                            formikTelefones.values.isWpp = event.target.checked;
                          }}
                        />
                      </div>
                    </Grid>
                    <Grid item xs={6}>
                      <Button
                        id="btSave"
                        variant="contained"
                        color="primary"
                        onClick={() => {
                          formikTelefones.submitForm();
                        }}
                        style={{ width: "100%", height: "100%" }}
                      >
                        Adicionar
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={12}>
                  <MUIDataTable
                    title={"Telefones"}
                    data={formik.values.listaTelefone}
                    columns={columns}
                    options={options}
                  />
                </Grid>
              </GABForm>
              <div style={{ height: "10px" }} />
            </section>
            <section id={"dados_endereco"}>
              <GABForm
                icon={"noto:house-with-garden"}
                title={"Endereço"}
                subtile={"informe o endereço"}
              >
                <GabInput
                  label={"Cep"}
                  id={"endereco.cep"}
                  size={6}
                  mask={"cep"}
                  disabled={isDisabled("cep")}
                  value={formik.values.endereco?.cep}
                  onChange={formik.handleChange}
                  onKeyUp={(e: any) => {
                    findCep(e);
                  }}
                  error={
                    formik.touched.endereco?.cep &&
                    Boolean(formik.errors.endereco?.cep)
                  }
                  helperText={
                    formik.touched.endereco?.cep && formik.errors.endereco?.cep
                  }
                />

                <GabInput
                  label={"Logradouro"}
                  id={"endereco.logradouro"}
                  size={6}
                  disabled={isDisabled("logradouro")}
                  value={formik.values.endereco?.logradouro}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.endereco?.logradouro &&
                    Boolean(formik.errors.endereco?.logradouro)
                  }
                  helperText={
                    formik.touched.endereco?.logradouro &&
                    formik.errors.endereco?.logradouro
                  }
                />

                <GabInput
                  label={"Numero"}
                  id={"endereco.numero"}
                  disabled={isDisabled("numero")}
                  size={6}
                  value={formik.values.endereco?.numero}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.endereco?.numero &&
                    Boolean(formik.errors.endereco?.numero)
                  }
                  helperText={
                    formik.touched.endereco?.numero &&
                    formik.errors.endereco?.numero
                  }
                />

                <GabInput
                  label={"Complemento"}
                  id={"endereco.complemento"}
                  size={6}
                  disabled={isDisabled("complemento")}
                  value={formik.values.endereco?.complemento}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.endereco?.complemento &&
                    Boolean(formik.errors.endereco?.complemento)
                  }
                  helperText={
                    formik.touched.endereco?.complemento &&
                    formik.errors.endereco?.complemento
                  }
                />
                <GabInput
                  label={"Bairro"}
                  id={"endereco.bairro"}
                  size={6}
                  disabled={isDisabled("bairro")}
                  value={formik.values.endereco?.bairro}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.endereco?.bairro &&
                    Boolean(formik.errors.endereco?.bairro)
                  }
                  helperText={
                    formik.touched.endereco?.bairro &&
                    formik.errors.endereco?.bairro
                  }
                />

                <GabInput
                  label={"Cidade"}
                  id={"endereco.cidade"}
                  size={6}
                  disabled={isDisabled("cidade")}
                  value={formik.values.endereco?.cidade}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.endereco?.cidade &&
                    Boolean(formik.errors.endereco?.cidade)
                  }
                  helperText={
                    formik.touched.endereco?.cidade &&
                    formik.errors.endereco?.cidade
                  }
                />

                <GabInput
                  label={"UF"}
                  id={"endereco?.uf"}
                  size={3}
                  disabled={isDisabled("endereco?.uf")}
                  value={formik.values.endereco?.uf}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.endereco?.uf &&
                    Boolean(formik.errors.endereco?.uf)
                  }
                  helperText={
                    formik.touched.endereco?.uf && formik.errors.endereco?.uf
                  }
                >
                  {listaEstados.map((option) => (
                    <option key={option?.id} value={option?.id}>
                      {option?.estado}
                    </option>
                  ))}
                </GabInput>
              </GABForm>
              <div style={{ height: "10px" }} />
            </section>
            <section id={"dados_acesso"}>
              <GABForm
                icon={"noto:busts-in-silhouette"}
                title={"Acessos"}
                subtile={"selecione os níveis de acesso ao sistema"}
              >
                <GABAutocomplete
                  id={"acessos"}
                  size={12}
                  label={"Acessos"}
                  limitTags={-1}
                  optionLabel={"label"}
                  //value={formik.values.acessos}
                  formik={formik}
                  options={listaAcessos}
                />
              </GABForm>
              <div style={{ height: "10px" }} />
            </section>
            <section id={"observacao"}>
              <GABForm
                icon={"noto:notebook"}
                title={"Observaçoes"}
                subtile={"Observaçoes e notas sobre este user"}
              >
                <GABTextEditor
                  id={"obs"}
                  size={12}
                  label={""}
                  value={formik.values.obs}
                  onChange={(texto: string) => {
                    formik.values.obs = texto;
                  }}
                />
              </GABForm>
              <div style={{ height: "10px" }} />
            </section>

            <CardActions
              disableSpacing
              hidden={true}
              style={props?.disabled === true ? { display: "none" } : {}}
            >
              <GABFormButtons size={12} formik={formik} />
            </CardActions>
          </Page>
        </form>
      </div>
    </Fade>
  );
};

export default FormUser;
