import { Tooltip } from "@mui/material";

const customAgenda = event => {
  return (
    <div style={{ textAlign: "left", position: "relative" }}>
      <Tooltip delay={0} target={"event" + event.event.id}>
        <button
          id={"event" + event.event.id}
          style={{ position: "absolute", right: "0", top: "0" }}
          className="btn-icon btn btn-success btn-sm naoImprime"
          onClick={() => {
            this.editarevento(event.event);
          }}
        >
          <i className="fas fa-edit" />
        </button>
      </Tooltip>
      <h6
        onClick={() => {
          this.editarevento(event.event);
        }}
        style={{ cursor: "pointer" }}
      >
        {event.title}
      </h6>
      <p dangerouslySetInnerHTML={{ __html: event.event.descricao }} />
      {event.event.local && event.event.local !== "" ? (
        <div>
          <label>
            <b>Local</b>
          </label>
          <p>{event.event.local}</p>
        </div>
      ) : (
        ""
      )}
      {event.event.participantes && event.event.participantes.length > 0 ? (
        <div>
          <label>
            <b>Participantes</b>
          </label>
          <ul>
            {event.event.participantes.map((pessoa, k) => {
              return (
                <li key={k} style={{ marginLeft: "16px" }}>
                  {pessoa.displayName + " (" + pessoa.email + ")"}
                </li>
              );
            })}
          </ul>
        </div>
      ) : (
        ""
      )}

      {event.event.resource.hangoutLink &&
      event.event.resource.hangoutLink !== "" ? (
        <div>
          <label>
            <b>Link da Conferência</b>
          </label>
          <a
            style={{
              display: "block",
              wordWrap: "break-word"
            }}
            target="_BLANK"
            href={event.event.resource.hangoutLink}
            rel="noreferrer"
          >
            {event.event.resource.hangoutLink}
          </a>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default customAgenda;
