import {styled} from "@mui/material/styles";
import {
    Button,
    Card,
    CardActions,
    CardContent,
    CardHeader,
    Divider,
    Fade, Grid,
} from "@mui/material";
import Page from "../components/Page";
import React, {useContext, useEffect, useState} from "react";
import "./login.css";
import {useNavigate} from "react-router-dom";
import AppProviderContext, {propsAppProviderContext} from "../app-provider";
import Logo from "../components/Logo";
import {useFormik} from "formik";
import * as Yup from "yup";
import {APP_TOKEN, APP_USUARIO} from "../App";
import GabInput from "../components/gab-input";
import brasilia from "./images/pexels-flavia-sandriany-13618459.jpg"
import GABDataTable from "../components/gab-data-table";
import GABModal from "../components/gab-modal";

const ContentStyle = styled("div")(({theme}) => ({
    maxWidth: 480,
    margin: "auto",
    minHeight: "100vh",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    padding: theme.spacing(12, 0),
}));

export default function Login() {
    const [isDisabledForm, setIsDisabledForm] = useState(false);
    const [showModal, setShowModal] = useState(false)
    const navigate = useNavigate();
    const {
        getAxios,
        postAxios,
        addMessage,
        setLoading,
        setProfile,
        setToken
    }: propsAppProviderContext = useContext(AppProviderContext);

    const validationSchema = Yup.object().shape({
        username: Yup.string().email('Email Inválido'),
        password: Yup.string().required("informe sua senha"),
    });
    const formikRequest = useFormik({
        initialValues: {email: ""},
        onSubmit: async (values) => {
            setLoading(true);

            try {
                await getAxios(`auth/senha/${values.email}`);
                setLoading(false);
                addMessage('Verifique as orientações enviadas no seu email', "success", 4000);
            }catch (e) {
                setLoading(false);
                addMessage('Não foi possível recuperar, verifique seu email', "error", 3000);
            }
            setShowModal(false);
        },
    });

    const formik = useFormik({
        initialValues: {username: "", password: ""},
        validationSchema: validationSchema,
        onSubmit: async (values) => {
            setIsDisabledForm(true);
            setLoading(true);
            try {
                const result = await postAxios('auth/', {
                    "password": formik.values.password,
                    "email": formik.values.username,
                });
                let user = result.data;

                const sys = await getAxios(`parlamentar/status/${user.idParlamentar}`);
                let status = sys.data[0].statusSistema;

                if(status.value === 2){
                    setLoading(false);
                    addMessage('SISTEMA INATIVO', 'error', 10000);
                    setIsDisabledForm(false);
                    return;
                }else if(status.value === 4){
                    setLoading(false);
                    addMessage('PERÍODO DE TESTE EXPIRADO', 'error', 10000);
                    setIsDisabledForm(false);
                    return;
                }

                localStorage.setItem(APP_TOKEN, user.token);
                setToken(user.token);
                const profile = await getAxios(`auth/usuario/${user._id}`)
                user.imageUrl = profile.data.foto;

                localStorage.setItem(APP_USUARIO, JSON.stringify(user));
                setProfile(user);
                setLoading(false);
                addMessage(`Bem vindo, ${result.data?.nome}`, 'success', 2000);
                await setTimeout(
                    () => {
                        navigate("/")
                    }, 1500
                );

              
            } catch (e) {
                setLoading(false);
                setIsDisabledForm(false);
                addMessage('Acesso Negado', 'error');
                await setTimeout(
                    () => {
                        addMessage('ATENÇÃO, Verifique seu usuário e senha', 'warning', 10000);
                    }, 1200
                );
            }

        },
    });

    useEffect(() => {
        setLoading(false);
    }, []);

    const renderLogin = () => {
        return (
            <ContentStyle>
                <div style={{
                    display : 'flex',
                    alignItems : 'center',
                    justifyItems : 'center',
                    flexDirection : 'column'
                }}>
                    <Logo
                        fullWhite={true}
                        sx={{
                            width: "50%",
                        }}
                    />
                </div>
                <Card>
                    <CardHeader
                        title="Olá, Bem-vindo ao"
                        subheader="Entre com seu usuário e senha para acessar o sistema"
                    />
                    <CardContent>
                        <div
                            style={{
                                padding: "0.5rem",
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "start",
                                alignItems: "end",
                                width: "100%",
                                height: "9rem",
                            }}
                        >
                            <GabInput
                                label={"Email"}
                                id={"username"}
                                size={12}
                                required={true}
                                disabled={isDisabledForm}
                                value={formik.values.username}
                                onChange={formik.handleChange}
                                error={formik.touched.username && Boolean(formik.errors.username)}
                                helperText={formik.touched.username && formik.errors.username}
                            />

                            <GabInput
                                label={"Senha"}
                                id={"password"}
                                type={"password"}
                                size={12}
                                inputProps={{
                                    type: 'password'
                                }}
                                required={true}
                                disabled={isDisabledForm}
                                value={formik.values.password}
                                onChange={formik.handleChange}
                                error={formik.touched.password && Boolean(formik.errors.password)}
                                helperText={formik.touched.password && formik.errors.password}
                            />
                        </div>
                    </CardContent>
                    <Divider/>

                    <CardActions>
                        <div
                            style={{
                                width: "100%",
                                display: "flex",
                                justifyContent: "center",
                            }}
                        >
                            <Button
                                onClick={() => {
                                    setShowModal(true)
                                }}
                                variant="text"
                                type={"button"}>
                                Recuperar senha
                            </Button>
                            <div style={{minWidth: '2rem'}}></div>
                            <Button
                                disabled={isDisabledForm}
                                variant="contained" type={"submit"}>
                                Acessar
                            </Button>
                        </div>
                    </CardActions>
                </Card>
                <div
                    style={{
                        textAlign: "center",
                        padding: "1rem",
                    }}
                >

                </div>

                <div
                    style={{
                        display: "flex",
                        justifyContent: "center"
                    }}
                >


                </div>

            </ContentStyle>
        );
    };

    return (
        <Fade in timeout={1000}>
            <div>
                <Page title="Login">
                    <div
                        style={{
                            background: "linear-gradient(#DFE3E8, #637381)",
                            backgroundImage: `url(${brasilia})`,
                            backgroundSize: "cover",
                            backgroundPosition :'bottom'
                        }}
                    >
                        <div
                            title={"Flávia Sandriany"}
                            style={{
                                backgroundColor: 'rgba(0,0,0,0.6)',
                                padding: '1rem'
                            }}>
                            <form onSubmit={formik.handleSubmit}>
                                {renderLogin()}
                            </form>

                            <GABModal
                                title={'Informe seu email'}
                                open={showModal}
                                onClickLeft={
                                    async () => {
                                        setShowModal(false);
                                    }
                                }
                                labelLeft={"Sair"}

                                onClickRight={
                                    async () => {
                                        await formikRequest.submitForm();
                                    }
                                }
                                labelRight={"enviar"}

                            >
                                <form onSubmit={formikRequest.handleSubmit}>
                                    <GabInput
                                        label={"Email"}
                                        id={"email"}
                                        size={12}
                                        formik={formikRequest}
                                        required={true}
                                    />
                                </form>
                            </GABModal>
                            <div style={{
                                position: "absolute",
                                bottom: '0',
                                height : '2rem',
                                left: '5%',
                                width: '90%',
                                textAlign: 'center',
                                fontSize: '0.7rem',
                            }}>
                                <a
                                    style={{
                                        color: 'rgba(255,255,255,0.52)'
                                    }}
                                    href={'https://www.pexels.com/pt-br/foto/ponte-em-arco-ponte-arqueada-lago-lagoa-13618459'}
                                    target={'_blank'}>Foto por Fávia Sandriany</a>
                            </div>
                        </div>
                    </div>

                </Page>
            </div>
        </Fade>
    );
}
