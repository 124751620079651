import React from "react";
import { VariantType } from "notistack";

export type propsAppProviderContext = {
  addMessage: (
    message: string,
    type: VariantType,
    autoHideDuration?: number
  ) => void;
  setToken: any;
  getAxios: any;
  postAxios: (url: string, data?: any, options?: any) => any;
  getPdfAxios: (url: string, data?: any) => any;
  putAxios: (url: string, data: any) => any;
  delAxios: (url: string) => void;
  isLoading: any;
  profile: any;
  setLoading: any;
  setProfile: any;
  editContato: any;
  setEditContato: any;
  getPicture: (id: string) => any;
  canAccess: (roles:number[]) => any;
};
const AppProviderContext = React.createContext<propsAppProviderContext>({
  addMessage: () => {},
  setToken: () => {},
  getAxios: () => {},
  postAxios: (url, data) => {},
  getPdfAxios: (url, data) => {},
  putAxios: (url, data) => {},
  delAxios: () => {},
  isLoading: undefined,
  profile: undefined,
  setLoading: undefined,
  setProfile: undefined,
  editContato: undefined,
  setEditContato: undefined,
  getPicture: id => {},
  canAccess: (roles:number[])=>{},
});

export default AppProviderContext;
